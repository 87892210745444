.menu-uer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  padding: 50px 20px;

  ul li {
    padding: 10px 0px;
  }
}
