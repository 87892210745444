.mdl-tabs {
  align-items: center;
  margin-bottom: 30px;

  &.search__landing {
    .mdl-tabs__panel {
      .content {
        //padding-top: 30px;
        min-height: 50vh;
        background: #fafafa;
      }
    }
  }

  &.no-margin {
    margin-bottom: 0;
  }

  .mdl-tabs__tab-bar {
    border: none;

    &.tab__search {
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      width: 100%;
      position: relative;
      top: 0;

      @media #{$tablet-up} {
        top: 0;
      }

      @media #{$tablet-up} {
        justify-content: space-between;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
      }

      .mdl-tabs__tab {
        min-width: 160px;
        color: #fff;

        @media #{$tablet-up} {
          min-width: 80px;
        }

      }
    }

    &.event__tabs {
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      box-shadow: $shadow-3;
      //padding-left: 50px;
      //padding-left: 69px;
      height: 70px;

      @media #{$tablet-only} {
        position: relative;
        z-index: 1;
        height: 120px;
      }

      @media #{$large-up} {
        padding-left: 10px;
        height: 120px;
        position: relative;
        z-index: 1;
      }

      .mdl-tabs__tab {
        font-family: 'Roboto Condensed Regular';
        display: flex;
        justify-content: center;
        font-size: $font24;
        letter-spacing: -0.2px;
        min-width: 50px;
        padding: 0 15px;
        height: 70px;
        line-height: 70px;

        @include fontsized() {
          font-size: f(font24);
        }

        @media #{$large-up} {
          padding: 0 41px;
          min-width: 150px;
          font-size: $font60;
          height: 120px;
          line-height: 120px;

          @include fontsized() {
            font-size: f(font60);
          }
        }

        @media #{$tablet-only} {
          padding: 0;
          min-width: 140px;
          font-size: $font24;
          height: 120px;
          line-height: 120px;

          @include fontsized() {
            font-size: f(font24);
          }
        }
      }
    }
  }

  .mdl-tabs__tab {
    font-family: 'Roboto Condensed Regular';
    font-size: $font14;
    color: $text-secondary;
    //line-height: 16px;
    letter-spacing: 1.25px;

    @include fontsized() {
      font-size: f(font14);
    }

    @include themed() {
      color: t(textSecondary);
    }

    &.is-active {
      @include themed() {
        color: t(textPrimary);
      }
    }

    &.custom {
      width: 100%;
    }
  }
}
