.nav__filter {

  a {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    line-height: 0;
    text-decoration: none;
    font-family: 'Roboto-Regular';
    font-size: $font14;
    line-height: 16px;
    padding: 13px 10px;
    letter-spacing: 1.25px;
    color: $text-primary;

    @include fontsized() {
      font-size: f(font14);
    }

    @include themed() {
      font-family: 'Roboto Condensed Regular';
      color: rgba(255, 255, 255, 0.38);
    }

    span.icon {
      margin-right: 10px;
    }

    &.navArticle {
      width: auto;
    }

  }

  &.mdl-tbl {
    a {
      font-family: 'Roboto Condensed Regular';
      color: $text-primary;
      width: 100%;
      max-width: 300px;
      background: $palette-primary-50;

      @include themed() {
        font-family: 'Roboto Condensed Regular';
        color: t(textSecondary);
        background: #251e11;
      }

      span.icon {
        margin-right: 10px;

        @include themed() {
          color: $palette-primary-500;
        }
      }
    }
  }

  &.mdl-filter {
    a {
      font-family: 'Roboto Condensed Regular';
      color: $text-primary;
      width: 100%;
      max-width: 300px;
      //background: $palette-primary-50;

      @include themed() {
        font-family: 'Roboto Condensed Regular';
        color: t(textSecondary);
        //background: #251e11;
      }

      span.icon {
        margin-right: 10px;

        @include themed() {
          color: t(textSecondary);
        }
      }
    }
  }

  @media #{$tablet-down} {
    margin-bottom: 20px;
  }
}


.nav__article {
  ul {
    margin: 0;
    padding: 0;

    li {
      display: flex;

      a {
        position: relative;
        width: 100%;
        display: block;
        font-family: 'Roboto Condensed Bold';
        font-size: $font16;
        color: $text-primary;
        line-height: 24px;
        letter-spacing: 0.15px;
        padding: 13px 10px;
        border-left: 5px solid transparent;
        border-top: 1px solid transparent;
        text-decoration: none;

        @include fontsized() {
          font-size: f(font16);
        }

        @include themed() {
          color: t(textSecondary);
        }

        &:hover {
          border-top: 1px solid #d8d8d8;
          border-left: 5px solid $palette-primary-500;
          background: $palette-primary-50;

          @include themed() {
            border-top: 1px solid #d8d8d8;
            color: t(textPrimary);
            background: #251e11;
          }
        }
      }
    }
  }
}

.search__drawer {
  padding: 10px;
}
