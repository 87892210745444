.grid__panel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media #{$tablet-only} {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: flex-start;
    width: 100%;
  }

  @media #{$large-up} {
    //flex-wrap: nowrap;
    //overflow-x: auto;
    //overflow-y: hidden;
    justify-content: flex-start;
    width: 100%;
  }

  .item__panel {
    flex: 0 0 50%;
    max-width: 50%;

    @media #{$tablet-only} {
      display: flex;
      flex-basis: 100%;
      max-width: 170px;
      //max-width: 50%;
    }

    @media #{$large-up} {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }

    a.action__panel {
      display: block;
      text-transform: uppercase;
      text-align: center;
      text-decoration: none;
      box-shadow: $shadow-1;
      display: block;
      width: 100%;

      &:focus {
        box-shadow: $shadow-4;
      }

      &:hover {
        position: relative;
        z-index: 1;
        box-shadow: $shadow-4;
      }
    }

    .card__panel {
      background: $palette-primary-500;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 140px;

      .copy {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span.icon {
          color: $text-primary;
          margin-bottom: 10px;
        }

        p {
          color: $text-primary;
          font-family: "Roboto Condensed Regular";
          font-size: $font14;
          line-height: 16px;
          letter-spacing: 1.25px;
          font-weight: bold;

          @include fontsized() {
            font-size: f(font14);
          }
        }
      }
    }
  }
}

.grid__panel__vive {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 69px 60px;
  background: #9C27B0;

  @media #{$tablet-up} {
    padding: 30px;
    min-height: 360px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .item__panel {
    &.md6 {
      @media #{$tablet-up} {
        display: flex;
        flex-basis: 50%;
        max-width: 50%;
      }

      @media #{$large-up} {
        display: flex;
        flex-wrap: nowrap;
        //justify-content: space-between;
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }

  .copy__vive {
    @media #{$large-up} {
      padding-left: 10px;
      padding-right: 10px;
    }

    p {
      font-family: "OpenSans-Bold";

      &.title__num {
        font-size: $font48;
        color: $text-secondary-dark;
        line-height: 100px;

        @include fontsized() {
          font-size: f(font48);
        }

        @media #{$large-up} {
          font-size: $font91;
          line-height: 0.7;

          @include fontsized() {
            font-size: f(font91);
          }
        }
      }

      &.title__desc {
        color: $text-primary-dark;
        font-family: "Roboto Condensed Regular";
        font-size: $font36;
        letter-spacing: -0.3px;

        @include fontsized() {
          font-size: f(font36);
        }

        @media #{$large-up} {
          font-size: $font60;
          line-height: 1.1;

          @include fontsized() {
            font-size: f(font60);
          }
        }
      }

      &.title__note {
        color: $text-secondary-dark;
        font-family: "Roboto-Regular";
        font-size: $font14;
        letter-spacing: 0.44px;
        line-height: 21px;
        margin-bottom: 20px;

        @include fontsized() {
          font-size: f(font14);
        }

        @media #{$large-up} {
          font-size: $font16;

          @include fontsized() {
            font-size: f(font16);
          }
        }
      }
    }
  }
}

.grid__action__vive {
  background: #6a1b9a;
  padding: 30px;
  display: flex;
  flex-direction: column;

  @media #{$large-up} {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }

  .copy__action {
    p {
      color: $text-primary-dark;
      font-family: "Roboto-Regular";
      font-size: $font24;
      line-height: 28px;
      letter-spacing: 0.18px;
      margin-bottom: 20px;

      @include fontsized() {
        font-size: f(font24);
      }

      @media #{$large-up} {
        font-size: $font34;
        width: 70%;
        line-height: 1.2;

        @include fontsized() {
          font-size: f(font34);
        }
      }
    }
  }

  .cont__btn__vive {
    margin: 0 auto;
    text-align: center;

    @media #{$tablet-up} {
      margin: inherit;
      text-align: left;
    }


    button.mdl-button.white_custom {
      min-width: 240px;
      height: 50px;
      background: #fff;
      color: $palette-secondary-500;

      @media #{$large-up} {
        box-shadow: $shadow-3;
      }
    }
  }
}

.grid__student {
  .title__student {
    p {
      font-family: "Roboto Condensed Regular";
      font-size: $font24;
      color: $text-secondary;
      letter-spacing: -0.2px;

      @include fontsized() {
        font-size: f(font24);
      }

    }
  }

  .scrolling-student {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    .box__round-student {
      border-radius: 50%;
      height: 40px;
      width: 40px;
      display: block;
      padding-right: 10px;

      &:focus {
        box-shadow: $shadow-4;
      }

      img {
        object-fit: cover;
        border-radius: 50%;
        height: 40px;
        width: 40px;
      }
    }
  }
}

.grid__teacher {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media #{$tablet-up} {
    flex-direction: row;
  }

  .item {
    @media #{$tablet-up} {
      flex-basis: 50%;
      max-width: 50%;
    }

    .box__teacher {
      display: flex;
      flex-direction: row;
      //align-items: center;
      padding: 10px;

      .icon__box {
        span {
          @include themed() {
            color: t(textSecondary);
          }
        }
      }

      .copy {
        padding-right: 20px;

        p {
          font-family: "Roboto-Regular";
          font-size: $font12;
          line-height: 1;
          color: $text-secondary;
          letter-spacing: 0.4px;
          margin-bottom: 5px;

          @include fontsized() {
            font-size: f(font12);
          }

          @include themed() {
            color: t(textSecondary);
          }

          &.name__teacher {
            color: $text-primary;
            font-family: "Roboto Condensed Bold";
            font-size: $font24;
            letter-spacing: 0.23px;
            line-height: 24px;

            @include fontsized() {
              font-size: f(font24);
            }

            @include themed() {
              color: t(textPrimary);
            }
          }
        }
      }
    }
  }
}

.grid__panel__info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media #{$tablet-only} {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: flex-start;
    width: 100%;
  }

  @media #{$large-up} {
    justify-content: flex-start;
    padding: 43px 5px;
  }

  &.landing__item {
    @media #{$large-up} {
      flex-wrap: nowrap;
    }

    .item__panel {
      @media #{$tablet-up} {
        margin: 0 20px;

      }

      @media #{$large-up} {
        flex: 0 0 33.3333333%;
        max-width: 33.3333333%;
        margin: 0;
      }

      @media #{$extra-large-and-up} {
        margin: 0;
      }

      .card__panel {
        background: #fafafa;

        @include themed() {
          background: t(bgdash-head);
        }

        @media #{$large-up} {
          min-height: 320px;
          width: calc(100% - 68px);
          margin: 0 auto;
        }

        &:hover {
          background: #fff;
          box-shadow: $shadow-3;

          @include themed() {
            background: t(bgHover);
          }

          @media #{$large-up} {
            box-shadow: $shadow-1;
          }
        }

        .copy {
          @media #{$large-up} {
            padding: 10px;
          }

          @media #{$extra-large-and-up} {
            padding: 40px 30px;
          }

          p.sub {
            @media #{$large-up} {
              width: 100%;
            }

            @include themed() {
              color: t(textSecondary);
            }
          }

          .copy__footer {
            justify-content: flex-start;

            button.mdl-button {
              @include themed() {
                background: $palette-primary-500 !important;
              }
            }
          }
        }
      }
    }
  }

  .item__panel {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;

    @media #{$large-up} {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }

    @media #{$tablet-only} {
      display: flex;
      flex-basis: 100%;
      max-width: 350px;
      //max-width: 50%;
    }

    a.action__panel {
      display: block;
      text-decoration: none;
      box-shadow: $shadow-1;

      &:focus {
        box-shadow: $shadow-4;
      }

      &:hover {
        box-shadow: $shadow-4;
      }
    }

    .content__bglogo {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 0.05;

      &.right__bg {
        right: 0;
        left: initial;
      }

      img {
        height: 100%;
        width: 100%;
        max-width: 100%;

        @media #{$large-up} {
          height: 220px;
          width: 220px;
          object-fit: contain;
        }

        @include themed() {
          -webkit-filter: invert(100%);
          //filter: brightness(0.2) sepia(1) hue-rotate(180deg) saturate(5);
        }
      }
    }

    .card__panel {
      background: $bg-secondary;
      display: flex;
      align-items: center;
      //justify-content: center;
      min-height: 140px;
      width: 100%;

      @include themed() {
        background: t(bgdash-body);
      }

      .copy {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        padding: 40px 30px;

        span.icon {
          color: $text-primary;
          margin-bottom: 10px;
        }

        p {
          color: $palette-primary-500;
          font-family: "Roboto Condensed Regular";
          font-size: $font36;
          //line-height: 16px;
          line-height: 1.1;
          letter-spacing: -0.3px;

          @include fontsized() {
            font-size: f(font36);
          }

          &.sub {
            font-family: "Roboto-Regular";
            font-size: $font14;
            color: $text-secondary;

            @include fontsized() {
              font-size: f(font14);
            }

            @media #{$large-up} {
              width: 330px;
            }

            @include themed() {
              color: t(textPrimary);
            }
          }
        }

        .copy__footer {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;

          .item {
            flex-basis: 50%;
            max-width: 50%;

            button.mdl-button {
              margin-left: 5px;
              background: $bg-secondary;
              height: 50px;
              min-width: 140px;
              box-shadow: $shadow-3;

              @include themed() {
                background: $palette-secondary-500;
                color: t(textPrimary);
              }
            }

            &.item__right {
              margin-left: auto;
              display: flex;

              a.mdl-button {
                display: flex;
                align-items: center;
                font-family: "Roboto Condensed Regular";
                font-size: $font14;
                line-height: 16px;
                letter-spacing: 1.25px;
                margin-left: auto;
                margin-right: 5px;
                display: flex;

                @include fontsized() {
                  font-size: f(font14);
                }

                @include themed() {
                  //background: $palette-secondary-500;
                  color: t(textSecondary);
                }

                //display: block;
              }
            }
          }
        }
      }
    }
  }
}

.grid__panel__data {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media #{$large-up} {
    position: relative;
  }

  .item__panel {
    flex: 0 0 100%;
    max-width: 100%;

    @media #{$large-up} {
      position: relative;
      flex: 0 0 50%;
      max-width: 100%;
    }

    @media #{$tablet-only} {
      flex: 0 0 50%;
      max-width: 50%;
    }

    a.action__panel {
      display: block;
      text-decoration: none;
      box-shadow: $shadow-1;

      &:focus {
        box-shadow: $shadow-4;
      }

      &:hover {
        box-shadow: $shadow-4;
      }
    }

    .card__panel {
      background: $bg-secondary;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      //justify-content: center;
      min-height: 140px;
      width: 100%;

      @include themed() {
        background: t(bgdash-body);
      }

      @media #{$tablet-only} {
        height: 100%;
      }

      @media #{$large-up} {
        height: 100%;
        justify-content: center;
        //align-items: unset;
      }

      &.center__panel {
        align-items: center;

        @media #{$large-up} {
          align-items: flex-end;
          justify-content: space-between;
        }
      }

      .copy__title {
        padding-top: 50px;
        margin-bottom: 60px;

        @media #{$large-up} {
          margin-bottom: 50px;
          width: 100%;
          text-align: center;
        }

        p {
          font-family: "Roboto-Regular";
          font-size: $font24;
          color: $text-secondary;
          letter-spacing: 0.25px;

          @include fontsized() {
            font-size: f(font24);
          }

          @media #{$large-up} {
            font-size: $font34;

            @include fontsized() {
              font-size: f(font34);
            }
          }

          @include themed() {
            color: t(textPrimary);
          }
        }
      }

      .copy__grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        @media #{$large-up} {
          margin-bottom: 75px;
        }

        .item {
          flex-basis: 50%;
          max-width: 50%;

          @media #{$large-up} {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
          }

          .data__num {
            margin: 0 auto;
            text-align: center;

            p {
              &.title {
                font-family: "OpenSans-Bold";
                font-size: $font36;
                letter-spacing: -0.58px;
                color: $palette-primary-500;

                @include fontsized() {
                  font-size: f(font36);
                }

                @media #{$large-up} {
                  //margin-bottom: 10px;
                  font-size: $font72;
                  line-height: 1.2;

                  @include fontsized() {
                    font-size: f(font72);
                  }
                }
              }

              &.sub {
                font-family: "Roboto Condensed Bold";
                font-size: $font16;
                line-height: 24px;
                letter-spacing: 0.15px;

                @include fontsized() {
                  font-size: f(font16);
                }

                @include themed() {
                  color: t(textSecondary);
                }
              }
            }
          }
        }
      }

      .copy__group {
        display: flex;
        flex-direction: row;
        min-height: 160px;
        justify-content: center;
        align-items: center;
        background: #f5f5f5;
        padding: 20px;

        @media #{$tablet-only} {
          margin-top: 30px;
        }

        @media #{$large-up} {
          min-height: 220px;
          width: inherit;
          flex-direction: column;
          //min-height: initial;
          align-items: initial;
          //justify-content: flex-end;
          //width: 100%;
          //align-items: flex-end;
          margin-top: 30px;
        }

        @include themed() {
          background: t(bgCard);
        }

        .item__group {
          display: flex;
          justify-content: flex-end;
          width: 37%;
          margin-left: auto;
        }

        .ctn__btn {
          position: relative;
          top: 62px;

          button.mdl-button {
            color: $palette-primary-500;
          }
        }

        p {
          color: $text-secondary;
          font-size: $font34;
          line-height: 1.1;
          letter-spacing: 0.25px;

          @include fontsized() {
            font-size: f(font34);
          }

          @include themed() {
            color: t(textPrimary);
          }
        }
      }

      .content__img {
        display: flex;
        flex-direction: column;
        width: 100%;

        @media #{$tablet-only} {
          height: 50%;
        }

        @media #{$large-up} {
          height: 100%;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          max-width: 100%;
        }
      }

      .copy__card {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: calc(100% - 80px);
        top: -25px;
        padding: 20px;
        box-shadow: $shadow-3;
        background: $palette-primary-500;

        @media #{$large-up} {
          width: 100%;
          min-height: 220px;
          //max-width: 720px;
          position: absolute;
          left: 50%;
          bottom: 0;
          top: initial;
          //max-width: calc(100% - 709px);
          // position: absolute;
          // top: 21%;
          // left: 25%;
          z-index: 999;
        }

        @media #{$extra-large-and-up} {
          //max-width: calc(100% - 806px);
        }

        @media #{$tablet-only} {
          top: 0;
          width: auto;
          //height: 245px;
          height: 50%;
        }

        p {
          font-family: "Roboto-Regular";
          font-size: $font16;
          line-height: 28px;
          color: $text-primary;

          @include fontsized() {
            font-size: f(font16);
          }

          @media #{$large-up} {
            font-size: $font24;

            @include fontsized() {
              font-size: f(font24);
            }
          }
        }
      }

      .copy {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        //width: 100%;
        padding: 40px 30px;

        .content__logo {
          margin-bottom: 15px;

          img {
            width: 100%;
            height: 90px;
            max-width: 100%;

            @include themed() {
              //-webkit-filter: invert(100%);
            }
          }
        }

        span.icon {
          color: $text-primary;
          margin-bottom: 10px;
        }

        p {
          color: $palette-primary-500;
          font-family: "Roboto Condensed Regular";
          font-size: $font36;
          //line-height: 16px;
          letter-spacing: -0.3px;

          @include fontsized() {
            font-size: f(font36);
          }

          &.subtitle {
            font-family: "Roboto-Regular";
            font-size: $font14;
            color: $text-primary;

            @include fontsized() {
              font-size: f(font14);
            }

            @include themed() {
              color: t(textPrimary);
            }
          }

          &.subtitle__two {
            font-family: "Roboto Condensed Regular";
            font-size: $font24;
            line-height: 30px;
            letter-spacing: -0.2px;
            color: $text-primary;

            @include fontsized() {
              font-size: f(font24);
            }

            @include themed() {
              color: t(textPrimary);
            }

            @media #{$large-up} {
              text-align: left;
            }
          }

          &.sub {
            font-family: "Roboto-Regular";
            font-size: $font14;
            color: $text-secondary;

            @include fontsized() {
              font-size: f(font14);
            }
          }
        }

        .content__btn {
          padding-top: 30px;
          margin: 0 auto;
          text-align: center;

          @media #{$large-up} {
            width: 100%;
            margin: inherit;
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          .item__btn {
            @media #{$large-up} {
              flex-basis: 50%;
              max-width: 50%;
            }
          }

          button.mdl-button {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $bg-hover-dark;
            min-width: 220px;
            height: 50px;
            color: $palette-primary-500;
            border-radius: 30px;
            margin: 0 auto;

            @media #{$large-up} {
              margin-left: auto;
            }

            span.icon {
              color: $palette-primary-500;
              line-height: 0;
              margin: 0;
              margin-left: 10px;
            }
          }
        }

        .copy__footer {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;

          .item {
            flex-basis: 50%;
            max-width: 50%;

            button.mdl-button {
              margin-left: 5px;
              background: $bg-secondary;
              height: 50px;
              min-width: 140px;
              box-shadow: $shadow-3;
            }

            &.item__right {
              margin-left: auto;
              display: flex;

              a.mdl-button {
                display: flex;
                align-items: center;
                font-family: "Roboto Condensed Regular";
                font-size: $font14;
                line-height: 16px;
                letter-spacing: 1.25px;
                margin-left: auto;
                margin-right: 5px;
                display: flex;

                @include fontsized() {
                  font-size: f(font14);
                }

                //display: block;
              }
            }
          }
        }
      }
    }
  }
}

.grid__custom {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .item__custom {
    margin-left: 0px;
    margin-right: 0px;

    &.col12-sm {
      flex-basis: 100% !important;
      max-width: 100% !important;
    }



    &.col4-md {
      @media #{$tablet-up} {
        flex-basis: 33.33% !important;
        max-width: 33.33% !important;
      }
    }

    &.col6-md {
      @media #{$tablet-up} {
        flex-basis: 50% !important;
        max-width: 50% !important;
      }
    }

    &.col12-lg {
      @media #{$large-up} {
        flex-basis: 100% !important;
        max-width: 100% !important;
      }
    }

  }
}

.grid__timeline {
  padding-top: 50px;
  padding-bottom: 50px;
}

.grid__search-landing {
  //padding: 10px;
  background: rgb(73, 22, 141);
  background: linear-gradient(90deg, rgba(73, 22, 141, 1) 0%, rgba(33, 149, 242, 1) 100%);

  .input__form {
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    .mdl-textfield.custom {
      padding: 10px 30px;
      height: 60px;
      min-width: 320px;
      background: #fff;
      border-radius: 30px;
      position: relative;
      display: flex;
      align-items: center;

      @media #{$tablet-up} {
        min-width: 600px;
      }

      .mdl-textfield__icon {
        position: absolute;
        right: 19px;
        top: 13px;
      }

      .mdl-textfield__input {
        padding-right: 10px;
        border-bottom: 1px solid transparent;
      }

      .mdl-textfield__label {
        font-family: 'Roboto Condensed Bold';
        font-size: $font16;
        letter-spacing: 0.15px;
        line-height: 24px;
        top: 17px;
        left: 30px;

        @include fontsized() {
          font-size: f(font16);
        }

        @media #{$tablet-up} {
          top: 14px;
        }

        &:after {
          background-color: transparent;
        }
      }
    }
  }
}

.grid__nosearch {
  background: #fff;



  .grid__panel__info {
    &.landing__item {
      background: #fafafa;

      @include themed() {
        background: t(bgdash-head);
      }
    }
  }
}
