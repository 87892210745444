.hide-on-small-only,
.hide-on-small-and-down {
  @media #{$small-and-down} {
    display: none !important;
  }
}

.hide-on-med-and-down {
  @media #{$medium-and-down} {
    display: none !important;
  }
}

.hide-on-med-and-up {
  @media #{$medium-and-up} {
    display: none !important;
  }
}

.hide-on-med-only {
  @media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
    display: none !important;
  }
}

.hide-on-large-only {
  @media #{$large-and-up} {
    display: none !important;
  }
}

.hide-on-extra-large-only {
  @media #{$extra-large-and-up} {
    display: none !important;
  }
}

.show-on-extra-large {
  @media #{$extra-large-and-up} {
    display: block !important;
  }
}

.show-on-large {
  @media #{$large-and-up} {
    display: block !important;
  }
}

.show-on-medium {
  @media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
    display: block !important;
  }
}

.show-on-small {
  @media #{$small-and-down} {
    display: block !important;
  }
}

.show-on-medium-and-up {
  @media #{$medium-and-up} {
    display: block !important;
  }
}

.show-on-medium-and-down {
  @media #{$medium-and-down} {
    display: block !important;
  }
}

// The Material Design Lite (MDL) Breakpoints 
// .mdl-table-hide-lon {
//   @media (max-width: 839px) and (min-width: 480px) {
//     display: none;
//   }
// }

//$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
.mdl-large-hide {
  @media all and (max-width: 1024px) {
    display: none !important;
  }

}

.mdl-large-hide-up {
  @media only screen and (min-width :839px) {
    display: none !important;
  }
}

.mdl-tablet-hide {

  @media all and (max-width: 839px) {
    display: none !important;
  }
}

.mdl-phone-hide {

  @media all and (max-width: 480px) {
    display: none !important;
  }
}

.hide__phone {
  @media #{$phone-down} {
    display: none !important;
  }
}

.hide__tablet {
  @media #{$tablet-only} {
    display: none !important;
  }
}

.hide__large {
  @media #{$large-up} {
    display: none !important;
  }
}