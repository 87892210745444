.content__login {
  position: relative;
  background: #e7eff1;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include themed() {
    background: t(bgdash-body);
  }

  a.close__login {
    position: absolute;
    right: 20px;
    top: 20px;
    color: $text-secondary;
    box-shadow: none;
    background: transparent;

    .mdl-ripple {
      background: $palette-primary-50;

      // @include themed() {
      //   //opacity: 0.1;
      //   //background: $palette-primary-50;
      //   color: $palette-primary-500;
      // }

      &.is-visible {
        @include themed() {
          opacity: 0.05;
          background: $palette-primary-900;
          color: $palette-primary-500;
        }
      }
    }

    &:focus {
      color: $palette-primary-500;
      box-shadow: none;
      background: rgba(0, 0, 0, .54);

      @include themed() {
        box-shadow: none;
        //background: $palette-primary-50;
        color: $palette-primary-500;
      }
    }

    @include themed() {
      color: t(textSecondary);
    }
  }

  //align-items: center;

  .box__login {
    background: $bg-secondary;
    margin: 20px;
    margin-top: 60px;
    box-shadow: $shadow-2;

    @include themed() {
      background: t(bgDashLink);
    }

    @media #{$tablet-only} {
      width: 550px;
      margin: 60px auto 0;
      //padding: 70px 70px 20px;
    }

    @media #{$extra-large-and-up} {
      width: 1150px;
      margin: 60px auto 0;
    }

    &.box_contact {
      .cont__contact {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        background: linear-gradient(to right, rgba(255, 189, 6, 1) 0%, rgba(255, 175, 3, 1) 100%);

        span.logo {
          position: absolute;
          left: 10px;
          top: 0;
          background: url('../images/logo/Logo_Universidad-de-La-Salle_WHITE.svg') no-repeat;
          width: 120px;
          height: 45px;
          max-width: 100%;
          background-position: center;
          display: block;
          padding-bottom: 30px;

          @include themed() {
            background: t(logoWhite) no-repeat;
            background-position: center;
          }
        }

        .contact__copy {
          width: 370px;

          p.title {
            font-size: $font60;
            font-family: "OpenSans-Bold";
            color: $text-primary;
            letter-spacing: -0.97px;
            margin-bottom: 30px;

            @include fontsized() {
              font-size: f(font60);
            }
          }

          p.sub {
            font-size: $font16;
            line-height: 28px;
            letter-spacing: 0.5px;
            font-family: 'Roboto-Regular';
            color: #fff;

            @include fontsized() {
              font-size: f(font16);
            }
          }
        }
      }

      .box__button {
        button.mdl-button.form {
          background: $text-primary;
          color: $palette-primary-500;
          min-width: 240px;
        }
      }

      .login__copy {
        padding: 0;

        .header__login-mobile {
          position: relative;
          padding: 35px;
          background: linear-gradient(to right, rgba(255, 189, 6, 1) 0%, rgba(255, 175, 3, 1) 100%);

          @media #{$tablet-up} {
            padding: 35px 70px;
          }

          .cont__circle__custom {
            bottom: -35px;
            right: 20px;
          }

          span.logo {
            position: relative;
            //left: 10px;
            //top: 0;
            background: url('../images/logo/Logo_Universidad-de-La-Salle_WHITE.svg') no-repeat;
            width: 120px;
            height: 45px;
            max-width: 100%;
            background-position: center;
            display: block;
            padding-bottom: 30px;
            margin-bottom: 30px;

            @include themed() {
              background: t(logoWhite) no-repeat;
              background-position: center;
            }
          }


          .contact__copy {
            p {
              &.title {
                color: #000;
                font-family: 'OpenSans-Bold';
                font-size: $font24;
                letter-spacing: -0.39px;

                @include fontsized() {
                  font-size: f(font24);
                }
              }

              &.sub {
                color: #fff;
                font-family: 'Roboto-Regular';
                font-size: $font14;
                line-height: 21px;
                letter-spacing: 0.44px;

                @include fontsized() {
                  font-size: f(font14);
                }
              }
            }
          }
        }

        .box__title {
          padding: 50px 35px 10px;

          @media #{$tablet-up} {
            padding: 45px 70px 10px;
          }

        }

        .box__form {
          padding: 10px 35px 35px;

          @media #{$tablet-up} {
            padding: 15px 70px 10px;
          }
        }
      }
    }

    .login__copy {
      padding: 20px;

      @media #{$tablet-only} {
        padding: 70px 70px 20px;
      }

      @media #{$large-up} {
        padding: 81px 81px 20px;
      }


    }

    .grid__box {
      position: relative;

      @media #{$large-up} {
        display: flex;
        flex-direction: row;
      }

      .cont__progress {
        width: 100%;
        position: absolute;

        .mdl-progress {
          width: 100%;
        }
      }

      .item__box {
        @media #{$large-up} {
          flex-basis: 50%;
          max-width: 50%;
        }
      }
    }

    .bg_img {
      display: flex;
      height: 100%;


      img {
        object-fit: cover;
        //height: 100%;
        max-width: 100%;
      }
    }

    .box__title {
      img {
        width: 165px;
        height: 45px;
        max-width: 100%;
        padding-bottom: 20px;
      }

      .logo {
        background: url('../images/logo/Logo_Universidad-de-La-Salle.svg') no-repeat;
        width: 165px;
        height: 45px;
        max-width: 100%;
        background-position: center;
        display: block;
        padding-bottom: 30px;

        @include themed() {
          background: t(logoWhite) no-repeat;
          background-position: center;
        }
      }

      p.title {
        font-family: 'Roboto Condensed Bold';
        font-size: $font24;
        color: $text-secondary;
        letter-spacing: -0.2px;

        @include fontsized() {
          font-size: f(font24);
        }

        @include themed() {
          color: t(textPrimary);
        }

        span {
          font-weight: bold;
        }
      }
    }

    .box__form {
      @media #{$tablet-only} {
        padding: 30px 45px 10px;
      }

      @media #{$large-up} {
        padding: 30px 47px 20px;
      }

      .mdl-textfield {
        position: relative;
        width: 100%;

        &.mdl-textfield--floating-label {
          &.is-invalid {
            .mdl-icon {
              color: #d50000;
            }
          }

          &.is-focused {
            .mdl-textfield__label {
              color: $text-primary;

              @include themed() {
                color: t(textPrimary);
              }

            }

          }

          .mdl-textfield__input {
            color: $palette-primary-500;

            @include themed() {
              color: t(textPrimary);
              border-bottom: 1px solid t(textSecondary);
            }

            &:-webkit-autofill {
              @include themed() {
                // -webkit-box-shadow: 0 0 0 50px white inset !important;
                // background-color: t(bgCard) !important;
              }
            }

            // &:-webkit-autofill:focus {
            //   @include themed() {
            //     -webkit-box-shadow:0 0 0 50px white inset;
            //     background-color: t(bgCard);
            //   }
            // }
          }

          .mdl-textfield__label {
            color: $palette-primary-500;

            @include themed() {
              color: $palette-primary-500;
            }
          }

        }

        .mdl-icon {
          position: absolute;
          right: 0;
          top: 15px;
          color: $text-secondary;

          @include themed() {
            color: t(textSecondary);
          }
        }
      }

      .remenber {
        padding: 10px 0;
        display: flex;
        justify-content: flex-end;

        //margin-left: auto;
        a.mdl-button {
          font-size: $font12;
          //line-height: 16px;
          letter-spacing: 0.4px;
          color: $text-secondary;

          @include fontsized() {
            font-size: f(font12);
          }

          //margin-left: auto;
          @include themed() {
            color: t(textSecondary);
          }
        }
      }


    }

    .box__button {
      padding-top: 20px;
      margin: 0 auto;
      text-align: center;

      @media #{$large-up} {
        padding-bottom: 135px;
      }

      button.mdl-button.mdl-button--raised {
        color: $text-primary;
        box-shadow: none;

        @include themed() {
          color: t(textSecondary);
          background: rgba(0, 0, 0, 0.3);
        }

        @media #{$tablet-only} {
          width: 180px !important;
        }

        //background: transparent;


        .mdl-ripple {
          background: $palette-secondary-50;

          &.is-visible {
            opacity: 0.3;
          }
        }


        //background: $text-secondary;
        &:active {
          color: $text-primary-dark !important;
          background: $palette-secondary-500 !important;
        }

        &:focus:not(:active) {
          color: $text-primary-dark !important;
          background: $palette-secondary-500 !important;
        }
      }
    }
  }

  .support__grid {
    margin: 20px;
    display: flex;
    flex-direction: row;

    //justify-content: space-between;
    @media #{$tablet-only} {
      padding-top: 30px;
      width: 550px;
      margin: 0 auto;
      //margin: 0 auto;
      //padding: 70px 70px 20px;
    }

    @media #{$extra-large-and-up} {
      padding-top: 30px;
      width: 1150px;
      margin: 0 auto;
    }


    .item__support {
      flex-basis: 50%;
      max-width: 50%;

      a.mdl-button.ico__data {
        padding-left: 0;
        padding-right: 0;
        display: flex;
        width: 120px;
        color: $text-secondary;
        //justify-content: center;
        align-items: center;
        flex-direction: row;

        @include themed() {
          color: t(textSecondary);
        }

        &:focus,
        :active {
          color: $palette-primary-500;
        }

        span {
          line-height: 0;

          i {
            padding-right: 5px;
          }
        }

        &.left__align {
          margin-left: auto;
        }
      }
    }
  }
}