.content-carousel {
  padding: 20px 10px;
  background: $palette-primary-500;

  .title-carousel {
    padding: 30px 0;

    p {
      &.title {
        font-family: 'Roboto Condensed Regular';
        font-size: $font24;
        color: $text-primary;
        line-height: 35px;
        letter-spacing: -0.2px;

        @include fontsized() {
          font-size: f(font24);
        }
      }
    }

    .filter-btn {
      button {
        margin-right: 10px;

        &:hover {
          box-shadow: $shadow-3;
          background: $bg-dashboard;
        }
      }
    }
  }

  .cards-carousel {
    ul {
      padding: 0;
      margin: 0;

      li {
        width: 100%;
      }
    }

    .card_custom {
      @media #{$large-and-up} {
        width: 100%;
        margin: 0 auto;
      }

      .footer-card {
        p {
          margin: 0;
          padding: 6px 20px;

          &.tag {
            border-radius: 20px;
            border: 1px solid $divider-color;
            font-family: 'Roboto-Regular';
            font-size: $font14;
            line-height: 20px;
            letter-spacing: 0.25px;

            @include fontsized() {
              font-size: f(font14);
            }
          }
        }

        .info-time {
          margin-left: auto;
          display: flex;
          align-items: center;
          flex-direction: row;

          span {
            padding: 0;
            line-height: 0;
            color: $text-primary;

            @include themed() {
              color: t(textPrimary);
            }
          }

          p {
            margin: 0;
            padding-left: 5px;
            font-family: 'Roboto-Regular';
            font-size: $font12;
            line-height: 16px;
            letter-spacing: 2px;
            margin: 0;

            @include fontsized() {
              font-size: f(font12);
            }

            &.tag {
              border-radius: 20px;
              border: 1px solid $divider-color;
              font-family: 'Roboto-Regular';
              font-size: $font14;
              line-height: 20px;
              letter-spacing: 0.25px;

              @include fontsized() {
                font-size: f(font14);
              }
            }
          }

          //width: 100%;
        }
      }
    }

    .footer-carousel {
      padding: 20px 5px 10px 5px;
      display: flex;
      align-items: center;

      .content-link_notice {
        margin-left: auto;

        a {
          text-decoration: none;
          display: flex;
          align-items: center;

          span {
            &.text {
              color: $text-primary;
              font-family: 'Roboto Condensed Regular';
              font-size: $font14;
              line-height: 16px;
              letter-spacing: 1.25px;

              @include fontsized() {
                font-size: f(font14);
              }
            }

            &.icon {
              line-height: 0;
              padding-left: 10px;
              color: $text-primary;
              opacity: 0.9;
            }
          }
        }
      }
    }

    .custom-number {
      //padding-top: 40px;

      a {
        font-family: 'Roboto Condensed Regular';
        font-size: $font24;
        letter-spacing: -0.2px;
        color: $text-secondary-white;
        padding: 0 10px;
        text-decoration: none;

        @include fontsized() {
          font-size: f(font24);
        }

        &.cycle-pager-active {
          color: $text-primary;
        }
      }
    }
  }
}
