.content__title__primary {
  padding: 8px;
  padding-top: 20px;

  &.ofert__title {
    h1 {
      @media #{$tablet-up} {
        text-align: center;
        font-size: $font48;

        @include fontsized() {
          font-size: f(font48);
        }
      }

      @media #{$large-up} {
        font-size: $font72;

        @include fontsized() {
          font-size: f(font72);
        }
      }

    }

    p.sub__title {
      text-align: center;

      @media #{$large-up} {
        font-size: $font24;

        @include fontsized() {
          font-size: f(font24);
        }
      }
    }
  }

  h1 {
    padding: 0;
    margin: 0;
    font-family: 'OpenSans-Bold';
    color: $palette-primary-500;
    font-size: $font92;
    letter-spacing: -1.5px;

    @include fontsized() {
      font-size: f(font92);
    }


    @media #{$tablet-down} {
      font-size: $font36;
      line-height: 42px;
      letter-spacing: -0.58px;
      margin-bottom: 15px;

      @include fontsized() {
        font-size: f(font36);
      }
    }
  }

  p.sub__title {
    font-family: 'Roboto-Regular';
    font-size: $font14;
    color: $text-secondary;
    line-height: 21px;
    letter-spacing: 0.1px;

    @include fontsized() {
      font-size: f(font14);
    }

    @include themed() {
      color: t(textSecondary);
    }
  }

  h2 {
    font-family: 'Roboto Condensed Bold';
    font-size: $font36;
    color: $text-secondary;
    line-height: 42px;
    letter-spacing: -0.3px;

    @include fontsized() {
      font-size: f(font36);
    }

    @media #{$tablet-down} {
      font-size: $font24;
      line-height: 28px;

      @include fontsized() {
        font-size: f(font24);
      }
    }

    @include themed() {
      color: t(textPrimary);
    }
  }
}

.content__title__secondary {
  margin-bottom: 20px;

  h2 {
    font-size: $font36;
    line-height: 42px;
    letter-spacing: -0.3px;
    color: $palette-primary-500;
    margin: 0;

    @include fontsized() {
      font-size: f(font36);
    }

    @media #{$tablet-down} {
      padding-bottom: 20px;
    }
  }
}


// componente article
.article__view {
  &.top_center {
    padding-top: 50px;
  }

  .article__title {
    margin-bottom: 14px;

    h1 {
      font-family: "OpenSans-Bold";
      font-size: $font36;
      line-height: 42px;
      letter-spacing: -0.3px;
      color: $palette-primary-500;
      margin: 0;

      @include fontsized() {
        font-size: f(font36);
      }

      @media #{$tablet-down} {
        padding-bottom: 15px;
      }

      &.primary {
        @media #{$tablet-up} {
          font-size: 80px;
          line-height: 1;
          margin-bottom: 30px;
        }
      }
    }

    h2 {
      font-size: $font36;
      line-height: 42px;
      letter-spacing: -0.3px;
      color: $palette-primary-500;
      margin: 0;

      @include fontsized() {
        font-size: f(font36);
      }

      @media #{$tablet-down} {
        padding-bottom: 15px;
      }
    }
  }

  .article__subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .cont__img {
      padding-right: 15px;

      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
    }

    h2 {
      font-family: "Roboto-Regular";
      font-size: $font24;
      line-height: 28px;
      //letter-spacing: -0.3px;
      color: $text-primary;
      margin: 0;

      @include fontsized() {
        font-size: f(font24);
      }

      @include themed() {
        color: t(textPrimary);
      }

      @media #{$tablet-down} {
        padding-bottom: 0;
      }
    }
  }

  .article__options {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    margin-bottom: 29px;

    @media #{$tablet-down} {
      flex-direction: column;
    }

    .article__tags {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      //width: 80%;
      //max-width: 600px;

      @media #{$tablet-down} {
        width: 100%;
        //max-width: 430px;
        margin-bottom: 15px;
      }

      @media #{$large-up} {
        width: 100%;
        max-width: 80%;
      }

      a,
      button {
        font-family: 'Roboto-Regular';
        border-radius: 30px;
        border: 1px solid $divider-btn;
        font-size: $font14;
        color: $text-primary;
        padding: 5px 10px;
        margin-right: 15px;
        //min-width: 300px;
        height: 32px;
        text-decoration: none;
        margin-bottom: 10px;

        @include fontsized() {
          font-size: f(font14);
        }

        @include themed() {
          color: t(textPrimary);
          border: 1px solid t(textPrimary);
        }
      }

    }

    .article__date {
      display: flex;
      align-items: center;
      margin-left: auto;
      width: 220px;
      justify-content: flex-end;

      @media #{$tablet-down} {
        margin-left: inherit;
        justify-content: flex-start;
        width: 100%;
      }

      span {
        display: block;
        line-height: 0;
        margin: 0;
        color: $text-link-color;

        @include themed() {
          color: t(textPrimary);
        }

        &.date-num {
          font-family: 'Roboto-Regular';
          color: $text-primary;
          line-height: 16px;
          letter-spacing: 2px;
          font-size: $font12;
          margin-left: 10px;

          @include fontsized() {
            font-size: f(font12);
          }

          @include themed() {
            color: t(textSecondary);
          }
        }
      }
    }
  }

  .article__body {
    font-size: $font14;
    font-family: 'Roboto-Regular';
    line-height: 21px;
    letter-spacing: 0.25px;
    margin-bottom: 30px;

    @include fontsized() {
      font-size: f(font14);
    }

    @include themed() {
      color: t(textPrimary);
    }

    img {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }
}

.article__options {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  margin-bottom: 29px;

  &.left__option {
    justify-content: flex-start;
  }

  @media #{$tablet-down} {
    flex-direction: column;
  }

  .article__tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    //width: 80%;
    //max-width: 600px;

    @media #{$tablet-down} {
      width: 100%;
      //max-width: 430px;
      margin-bottom: 15px;
    }

    @media #{$large-up} {
      width: 100%;
      max-width: 80%;
    }

    a,
    button {
      font-family: 'Roboto-Regular';
      border-radius: 30px;
      border: 1px solid $divider-btn;
      font-size: $font14;
      color: $text-primary;
      padding: 5px 10px;
      margin-right: 15px;
      //min-width: 300px;
      height: 32px;
      text-decoration: none;
      margin-bottom: 10px;

      @include fontsized() {
        font-size: f(font14);
      }

      @include themed() {
        color: t(textPrimary);
        border: 1px solid t(textPrimary);
      }
    }

  }

  .article__date {
    display: flex;
    align-items: center;
    margin-left: auto;
    width: 220px;
    justify-content: flex-end;

    @media #{$tablet-down} {
      margin-left: inherit;
      justify-content: flex-start;
      width: 100%;
    }

    span {
      display: block;
      line-height: 0;
      margin: 0;
      color: $text-link-color;

      @include themed() {
        color: t(textPrimary);
      }

      &.date-num {
        font-family: 'Roboto-Regular';
        color: $text-primary;
        line-height: 16px;
        letter-spacing: 2px;
        font-size: $font12;
        margin-left: 10px;

        @include fontsized() {
          font-size: f(font12);
        }

        @include themed() {
          color: t(textSecondary);
        }
      }
    }
  }
}
