header {
  &.header {
    min-height: 90px;
    background: $bg-secondary;
    box-shadow: $shadow-3;
    align-items: center;
    justify-content: center;

    @include themed() {
      background: t(yellowPrimary500);
    }

    .mdl-layout__drawer-button {
      top: 18px;
    }

    .mdl-layout__header-row {
      &.custom {
        padding: 0 16px 0 16px;
      }
    }

    .mdl-navigation {
      &.custom {
        &__left {
          flex: auto;
          justify-content: space-between;
          //flex-shrink: initial;
          position: relative;
          //margin-left: auto;
        }
      }
    }

    .logo {
      background: url("../images/logo/Logo_Universidad-de-La-Salle.svg") no-repeat;
      width: 100px;
      height: 100%;
      background-position: center;

      @include themed() {
        background: t(logoWhite) no-repeat;
        background-position: center;
      }
    }

    .mdl-navigation {
      a.mdl-navigation__link {
        color: $text-link-color;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;

        span {
          display: none;
          font-family: "Roboto Condensed Regular";
          color: $text-secondary;
          line-height: 16px;
          letter-spacing: 1.25px;
          line-height: 16px;

          @media #{$large-and-up} {
            padding-top: 5px;
            display: inline-block;
          }
        }

        &.custom {
          color: $text-link-color;
          padding: 0 13px;

          @media #{$large-and-up} {
            color: $text-primary;
          }

          &__active {
            position: relative;
            color: $text-primary;

            @media #{$large-and-up} {
              color: $palette-primary-500;

              @include themed() {
                color: t(textBlack);
              }
            }

            @media #{$large-and-up} {
              &:before {
                content: "";
                position: absolute;
                bottom: -22px;
                left: 0;
                width: 100%;
                height: 2px;
                border-bottom: 3px solid $palette-primary-500;

                @include themed() {
                  border-bottom: 3px solid t(textBlack);
                }
              }
            }
          }

          &:hover {
            color: $text-primary;

            @media #{$large-and-up} {
              color: $palette-primary-500;

              @include themed() {
                color: t(textBlack);
              }
            }
          }
        }
      }
    }
  }

  &.header__public {
    min-height: 56px;
    background: $bg-secondary;
    box-shadow: $shadow-3;
    flex-shrink: 0;

    // flex-direction: row;
    // align-items: center;
    // justify-content: center;
    &.center__grid {
      @media #{$large-up} {
        //margin: 0 !important;
        width: calc(100% - 310px) !important;
      }
    }

    &.bg__transparent {
      background: transparent;
    }

    &.no__shadow {
      box-shadow: none;
    }

    &.inside__bg {
      position: absolute;
    }

    @include themed() {
      background: t(yellowPrimary500);
    }

    .content__bread {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .grid__header {
      display: flex;
      margin-right: auto;
      margin-left: auto;
      flex-flow: row nowrap;
      //padding-top: 10px;
      width: 100%;
      // flex-direction: row;
      align-items: center;

      //justify-content: center;
      //flex-wrap: nowrap;
      .breadcrumb {
        a {
          text-decoration: none;
          color: $text-white;

          @include themed() {
            color: t(textPrimary);
          }
        }
      }

      .item__header {
        flex: 1 1 0px;
        min-width: 0;
        padding-right: 0.9375rem;
        padding-left: 0.9375rem;
        // flex: 0 0 50%;
        // max-width: 50%;

        &.mdl8 {
          flex: 0 0 66.666667%;
          max-width: 66.666667%;
        }

        &.mdl4 {
          flex: 0 0 33.333333%;
          max-width: 33.333333%;
        }

        &.align__right {
          display: flex;
          justify-content: flex-end;
        }

        //max-width: 50%;
      }
    }

    .breadcrumb {
      a {
        text-decoration: none;
        color: $text-secondary;

        @include themed() {
          color: t(textSecondary);
        }
      }
    }

    .top__header {
      height: 50px;
      display: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      background: $palette-primary-500;

      @media #{$large-up} {
        display: flex;
      }

      @media #{$deadpx-only} {
        display: none !important;
      }

      .menu__action {
        display: flex;
        //justify-content: center;
        align-items: center;

        a.mdl-button {
          font-size: $font14;
          color: #fff;
          letter-spacing: 0.2px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;

          @include fontsized() {
            font-size: f(font14);
          }

          &:hover {
            text-decoration: none;
          }

          &.black__100 {
            font-family: "Roboto Condensed Bold";
            color: $text-primary;
          }

          &.icon__box {
            width: 24px;
            min-width: 24px;

            &.search__icon {
              margin-left: 50px;
            }
          }

          span.fast_name {
            padding-right: 10px;
          }
        }

        a.mdl__icon {
          color: $text-primary;
        }
      }
    }

    .mdl-layout__drawer-button {
      //top: 18px;
    }

    .mdl-layout__header-row {
      &.custom {
        display: flex;
        // margin-right: -0.9375rem;
        // margin-left: -0.9375rem;
        flex-flow: row nowrap;
        min-height: 64px;
        //width: 100%;
        padding: 0 20px 0 20px;

        @media #{$large-up} {
          margin: 0 !important;
          justify-content: space-between;
          //width: calc(100% - 305px) !important;
        }

        @include themed() {
          background: $palette-primary-500;
        }

        .item__row {
          flex: 1 1 0px;
          padding-right: 0.9375rem;
          padding-left: 0.9375rem;
          min-width: 0;

          &.mdl83 {
            flex-basis: 80% !important;
          }

          &.mdl2 {
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
          }

          &.mdl8 {
            flex: 0 0 66.666667%;
            max-width: 66.666667%;
          }

          &.mdl4 {
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
          }

          &.mdl10 {
            flex: 0 0 83.33333%;
            max-width: 83.33333%;
          }
        }
      }
    }

    .mdl-navigation {
      @media #{$deadpx-only} {
        display: none !important;
      }

      &.custom {
        align-items: inherit;

        &.align__left {
          margin-right: 30px;
          display: flex;
          justify-content: flex-end;

          @media #{$large-up} {
            margin-right: 0;
          }
        }

        &__left {
          position: relative;
          justify-content: flex-end;
          margin-left: auto;
          align-items: inherit;

          @media #{$tablet-up} {
            word-break: break-all;
          }

          @media #{$large-up} {
            word-wrap: none;
          }
        }
      }
    }

    .content__logo {
      //width: 100%;
      height: 90px;

      .logo {
        background: url("../images/logo/Logo_Universidad-de-La-Salle.svg") no-repeat;
        width: 100px;
        height: 90px;
        display: flex;
        background-position: center;

        @include themed() {
          background: t(logoWhite) no-repeat;
          background-position: center;
        }
      }
    }

    .mdl-navigation {
      a.mdl-navigation__link {
        color: $text-link-color;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;

        span {
          display: none;
          font-family: "Roboto Condensed Regular";
          color: $text-secondary;
          line-height: 16px;
          letter-spacing: 1.25px;
          line-height: 16px;

          @include themed() {
            color: t(textPrimary);
          }

          @media #{$large-and-up} {
            padding-top: 5px;
            display: inline-block;
          }

          @media (-webkit-min-device-pixel-ratio: 1),
          (min-resolution: 160dpi) {
            font-size: 12px;
            // MDPI CSS HERE
          }

          @media (-webkit-min-device-pixel-ratio: 1.5),
          (min-resolution: 240dpi) {
            // HDPI CSS HERE
            font-size: 14px;
          }
        }

        &.custom {
          color: $text-link-color;

          @media #{$large-and-up} {
            color: $text-primary;
          }

          &__active {
            position: relative;
            color: $text-primary;
            text-decoration: none;

            @media #{$large-and-up} {
              color: $palette-primary-500;

              @include themed() {
                color: $palette-primary-500;
              }
            }

            @media #{$large-and-up} {
              &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                border-bottom: 3px solid $palette-primary-500;

                @include themed() {
                  border-bottom: 3px solid $palette-primary-500;
                }
              }
            }
          }

          &:hover {
            color: $text-primary;
            text-decoration: none;

            @media #{$large-and-up} {
              color: $palette-primary-500;

              @include themed() {
                color: t(textBlack);
              }
            }
          }
        }
      }
    }

    .btn__session {
      display: flex;
      align-items: center;
      justify-content: center;

      &.white__color {
        .active-user {
          .item-name p.name {

            color: #fff;

            @include themed() {
              color: t(textPrimary);
            }
          }
        }

        button.mdl-button.user__active {
          color: #fff;

          @include themed() {
            color: t(textPrimary);
          }
        }

      }

      &.left__btn {
        margin-left: auto;
      }

      button.mdl-button {
        height: 50px;
        min-width: 160px;

        &.user__active {
          height: 30px;
          min-width: 30px;
        }

        &.blue__btn {
          color: #fff;
          background: $palette-secondary-500;
        }
      }
    }
  }
}

.active-user {
  padding-left: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .item-name {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 10px;

    p {
      margin: 0;

      &.name {
        color: $text-primary;
        font-family: "Roboto Condensed Bold";
        font-size: $font16;
        line-height: 24px;
        letter-spacing: 0.15px;

        @include fontsized() {
          font-size: f(font16);
        }
      }

      &.role {
        color: $palette-secondary-500;
        font-family: "Roboto Condensed Regular";
        font-size: $font14;
        line-height: 16px;
        letter-spacing: 1.25px;

        @include fontsized() {
          font-size: f(font14);
        }

        @include themed() {
          color: $text-primary;
        }
      }
    }
  }
}

.mdl-layout {
  &.custom {
    .mdl-layout__drawer-button {
      left: auto;
      right: 0;
      background: transparent;
    }
  }
}