.title__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.title__info {
    @media #{$tablet-up} {
      padding-top: 20px;
    }

    p.title {
      font-family: 'Roboto Condensed Regular';
      font-size: $font24;
      color: $text-secondary;
      letter-spacing: -0.2px;
      margin-bottom: 0;

      @include fontsized() {
        font-size: f(font24);
      }

      @media #{$large-up} {
        font-size: $font34;

        @include fontsized() {
          font-size: f(font34);
        }
      }

      @media #{$tablet-up} {
        text-align: center;
        display: block;
        width: 100%;
        font-size: $font36;
        margin-bottom: 20px;

        @include fontsized() {
          font-size: f(font36);
        }
      }

      @include themed() {
        color: t(textPrimary);
      }
    }
  }

  &.number__list {
    margin: 20px 0px;
  }

  &.section__warp {
    p {
      @include themed() {
        color: $text-primary;
      }
    }

    p.number {
      color: $palette-primary-600;

      @include themed() {
        color: $palette-primary-600;
      }

      @media #{$tablet-down} {
        font-size: $font60;

        @include fontsized() {
          font-size: f(font60);
        }
      }

      @media #{$tablet-only} {
        font-size: $font60;

        @include fontsized() {
          font-size: f(font60);
        }
      }
    }
  }

  p {
    position: relative;
    z-index: 1;
    font-family: 'Roboto-Regular';
    font-size: $font16;
    color: $text-primary;
    margin: 0;
    padding-right: 40px;

    @include fontsized() {
      font-size: f(font16);
    }

    @include themed() {
      color: t(textPrimary);
    }

    &.number {
      font-size: $font120;
      color: #eeeeee;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;

      @include fontsized() {
        font-size: f(font120);
      }

      @media #{$tablet-down} {
        font-size: $font60;

        @include fontsized() {
          font-size: f(font60);
        }
      }

      @media #{$tablet-only} {
        font-size: $font60;

        @include fontsized() {
          font-size: f(font60);
        }
      }

      @include themed() {
        color: t(textNumber);
      }

    }
  }

  .active__num {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;

  }

  .filter-btn {
    button.mdl-button {
      @include themed() {
        border: 1px solid $palette-primary-500;
        color: t(textPrimary);

        &:hover {
          background: transparent;
        }

        .mdl-ripple {
          background: $palette-primary-500;
        }
      }
    }
  }
}

.scrolling-wrapper {
  // display: flex;
  // flex-direction: row;
  // flex-wrap: nowrap;
  // overflow-x: auto;
  // width: 100%;
  // margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  -webkit-overflow-scrolling: touch;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  padding: 20px 30px 20px 20px;
  margin-bottom: 50px;

  &.student {
    justify-content: flex-start;
    padding: 20px 0px 20px;

    @media #{$tablet-up} {
      padding: 20px 0;
    }

    @media #{$large-up} {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

  &.custom {
    justify-content: flex-start;
    padding: 20px 0px 20px;

    @media #{$large-up} {
      justify-content: center;
    }

    @media #{$tablet-up} {
      padding: 20px 30px 20px 20px;
      margin-bottom: 10px;
    }
  }


  &::-webkit-scrollbar {
    display: none;
  }

  .card__number {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    // min-width: 150px;
    // max-width: 180px;
    // min-height: 140px;
    padding: 35px 10px 10px 10px;
    margin-right: 30px;
    box-shadow: $shadow-1;
    background: $bg-secondary;

    @include themed() {
      background: t(bgCard);
    }

    .copy__number {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      min-width: 100px;
    }

    p {
      display: block;

      &.title {
        font-family: 'Roboto-Regular';
        font-size: $font34;
        color: $text-secondary;
        letter-spacing: 0.25px;
        width: 100%;
        text-align: center;
        white-space: nowrap;

        @include fontsized() {
          font-size: f(font34);
        }

        @media #{$tablet-up} {
          font-size: $font48;

          @include fontsized() {
            font-size: f(font48);
          }
        }

        @include themed() {
          //border: 1px solid #fff;
          color: t(textSecondary);
        }
      }

      &.sub {
        font-family: 'Roboto Condensed Bold';
        font-size: $font16;
        line-height: 24px;
        color: $text-secondary;
        letter-spacing: 0.15px;
        width: 100%;
        text-align: center;
        white-space: nowrap;

        @include fontsized() {
          font-size: f(font16);
        }

        @media #{$tablet-up} {
          font-size: $font20;

          @include fontsized() {
            font-size: f(font20);
          }
        }

        @include themed() {
          //border: 1px solid #fff;
          color: t(textSecondary);
        }
      }
    }
  }

  .item__card {
    position: relative;
    width: 100%;
    box-shadow: $shadow-1;
    max-width: 240px;
    min-height: 260px;
    flex: 0 0 auto;
    justify-content: center;
    padding: 35px 10px 10px 10px;
    margin-right: 30px;
    background: $bg-secondary;
    margin-bottom: 20px;

    @media #{$large-up} {
      max-width: 280px;
      margin-right: 25px;
    }

    @include themed() {
      background: t(bgCard);
    }

    &.blank {
      @media #{$tablet-down} {
        max-width: 30px;
        background: none;
        box-shadow: none;
      }

      @media #{$large-up} {

        display: none;
      }
    }

    &:hover {
      box-shadow: $shadow-3;
    }

    &.student {
      padding: 0;
      margin: 0;
      margin-right: 40px;
      box-shadow: none;

      @include themed() {
        background: transparent;
      }

      @media #{$tablet-up} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        //max-width: 25%;
        margin-right: 40px;
        //margin-bottom: 30px;
      }

      .box__student__card {
        position: relative;
        padding: 35px 10px 10px 10px;
        margin-right: 20px;
        margin-left: 10px;
        box-shadow: $shadow-1;
        max-width: 240px;
        width: 100%;
        min-height: 260px;

        @include themed() {
          background: t(bgCard);
        }

        &:hover {
          box-shadow: $shadow-3;
        }

        .item__head {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          padding: 0 20px;

          img {
            max-width: 100%;
            width: 70px;
            height: 70px;
            object-fit: cover;
            margin-bottom: 10px;

            &.circle {
              border-radius: 50%;
            }
          }

          p {
            margin: 0;
            text-align: center;

            &.title {
              font-family: 'Roboto Condensed Bold';
              font-size: $font16;
              color: $palette-primary-500;
              margin-bottom: 5px;

              @include fontsized() {
                font-size: f(font16);
              }

              &.dark {
                color: $text-primary;

                @include themed() {
                  color: t(textPrimary);
                }

              }
            }

            &.subtitle {
              font-family: 'Roboto Condensed Bold';
              font-size: $font14;
              line-height: 16px;
              letter-spacing: 1.25px;
              color: $text-secondary;
              margin-bottom: 10px;

              @include fontsized() {
                font-size: f(font14);
              }

              @include themed() {
                color: t(textSecondary);
              }
            }
          }
        }

        .item__footer {
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 20px 0;
          display: flex;
          //justify-content: space-between;
          align-items: center;
          width: 100%;

          .item__info {
            padding-left: 20px;
            width: 100%;
            max-width: 170px;

            p {
              font-family: 'Roboto-Regular';
              font-size: $font14;
              color: rgba(0, 0, 0, 0.38);
              margin: 0;

              @include fontsized() {
                font-size: f(font14);
              }

              @include themed() {
                color: t(textSecondary);
              }

              &.number {
                font-size: $font12;
                letter-spacing: 0.4px;
                color: $text-secondary;

                @include fontsized() {
                  font-size: f(font12);
                }

                @include themed() {
                  color: t(textPrimary);
                }
              }
            }
          }

          .item__icon {
            padding-right: 20px;
            width: 100%;
            max-width: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            span {
              color: $text-secondary;

              @include themed() {
                color: t(textSecondary);
              }
            }

          }

        }
      }

      &:hover {
        .item__head p.title {
          color: $palette-primary-500;
        }
      }

      .item__head {
        img {
          margin-bottom: 10px;
        }

        p {
          &.title {
            font-family: "Roboto Condensed Bold";
            font-size: $font20;
            color: $text-primary;
            letter-spacing: 0.25px;
            margin-bottom: 10px;

            @include fontsized() {
              font-size: f(font20);
            }
          }

          &.subtitle {
            font-family: "Roboto Condensed Regular";
            font-size: $font14;
            line-height: 1.6;
            text-transform: uppercase;
            color: $text-secondary;
            letter-spacing: 1.25px;

            @include fontsized() {
              font-size: f(font14);
            }
          }
        }
      }

      .item__footer {
        display: flex;
        justify-content: center;

        p {
          &.address {
            display: flex;
            align-items: center;
            text-align: center;

            span {
              line-height: 0;

              &.sede {
                padding-left: 10px;
                font-family: "Roboto Condensed Bold";
                color: $text-secondary;
                font-size: $font16;
                line-height: 24px;
                letter-spacing: 0.15px;

                @include fontsized() {
                  font-size: f(font16);
                }

                @include themed() {
                  color: t(textSecondary);
                }
              }
            }

          }
        }
      }
    }

    .item__head {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      padding: 0 20px;

      img {
        max-width: 100%;
        width: 70px;
        height: 70px;
        object-fit: cover;
        margin-bottom: 10px;

        &.circle {
          border-radius: 50%;
        }
      }

      p {
        margin: 0;
        text-align: center;

        &.title {
          font-family: 'Roboto Condensed Bold';
          font-size: $font16;
          color: $palette-primary-500;
          margin-bottom: 5px;

          @include fontsized() {
            font-size: f(font16);
          }

          &.dark {
            color: $text-primary;

            @include themed() {
              color: t(textPrimary);
            }

          }
        }

        &.subtitle {
          font-family: 'Roboto Condensed Bold';
          font-size: $font14;
          line-height: 16px;
          letter-spacing: 1.25px;
          color: $text-secondary;
          margin-bottom: 10px;

          @include fontsized() {
            font-size: f(font14);
          }

          @include themed() {
            color: t(textSecondary);
          }
        }
      }
    }

    .item__footer {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 20px 0;
      display: flex;
      //justify-content: space-between;
      align-items: center;
      width: 100%;

      .item__info {
        padding-left: 20px;
        width: 100%;
        max-width: 170px;

        p {
          font-family: 'Roboto-Regular';
          font-size: $font14;
          color: rgba(0, 0, 0, 0.38);
          margin: 0;

          @include fontsized() {
            font-size: f(font14);
          }

          @include themed() {
            color: t(textSecondary);
          }

          &.number {
            font-size: $font12;
            letter-spacing: 0.4px;
            color: $text-secondary;

            @include fontsized() {
              font-size: f(font12);
            }

            @include themed() {
              color: t(textPrimary);
            }
          }
        }
      }

      .item__icon {
        padding-right: 20px;
        width: 100%;
        max-width: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        span {
          color: $text-secondary;

          @include themed() {
            color: t(textSecondary);
          }
        }

      }

    }
  }
}

.item__card-only {
  position: relative;
  width: 100%;
  box-shadow: $shadow-1;
  max-width: 320px;
  margin: 0 auto;
  min-height: 260px;
  flex: 0 0 auto;
  justify-content: center;
  padding: 35px 10px 10px 10px;
  margin-right: 30px;
  background: $bg-secondary;
  margin-bottom: 20px;

  @media #{$tablet-up} {
    max-width: 240px;
  }

  @media #{$large-up} {
    width: auto;
    max-width: 100%;
    margin-right: 25px;
  }

  @include themed() {
    background: t(bgCard);
  }

  &.blank {
    @media #{$tablet-down} {
      max-width: 30px;
      background: none;
      box-shadow: none;
    }

    @media #{$large-up} {

      display: none;
    }
  }

  &:hover {
    box-shadow: $shadow-3;
  }

  &.student {
    padding: 0;
    margin: 0;
    margin-right: 40px;
    box-shadow: none;

    @include themed() {
      background: transparent;
    }

    @media #{$tablet-up} {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      //max-width: 25%;
      margin-right: 40px;
      //margin-bottom: 30px;
    }

    .box__student__card {
      position: relative;
      padding: 35px 10px 10px 10px;
      margin-right: 20px;
      margin-left: 10px;
      box-shadow: $shadow-1;
      max-width: 240px;
      width: 100%;
      min-height: 260px;

      @include themed() {
        background: t(bgCard);
      }

      &:hover {
        box-shadow: $shadow-3;
      }

      .item__head {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        padding: 0 20px;

        img {
          max-width: 100%;
          width: 70px;
          height: 70px;
          object-fit: cover;
          margin-bottom: 10px;

          &.circle {
            border-radius: 50%;
          }
        }

        p {
          margin: 0;
          text-align: center;

          &.title {
            font-family: 'Roboto Condensed Bold';
            font-size: $font16;
            color: $palette-primary-500;
            margin-bottom: 5px;

            @include fontsized() {
              font-size: f(font16);
            }

            &.dark {
              color: $text-primary;

              @include themed() {
                color: t(textPrimary);
              }

            }
          }

          &.subtitle {
            font-family: 'Roboto Condensed Bold';
            font-size: $font14;
            line-height: 16px;
            letter-spacing: 1.25px;
            color: $text-secondary;
            margin-bottom: 10px;

            @include fontsized() {
              font-size: f(font14);
            }

            @include themed() {
              color: t(textSecondary);
            }
          }
        }
      }

      .item__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 20px 0;
        display: flex;
        //justify-content: space-between;
        align-items: center;
        width: 100%;

        .item__info {
          padding-left: 20px;
          width: 100%;
          max-width: 170px;

          p {
            font-family: 'Roboto-Regular';
            font-size: $font14;
            color: rgba(0, 0, 0, 0.38);
            margin: 0;

            @include fontsized() {
              font-size: f(font14);
            }

            @include themed() {
              color: t(textSecondary);
            }

            &.number {
              font-size: $font12;
              letter-spacing: 0.4px;
              color: $text-secondary;

              @include fontsized() {
                font-size: f(font12);
              }

              @include themed() {
                color: t(textPrimary);
              }
            }
          }
        }

        .item__icon {
          padding-right: 20px;
          width: 100%;
          max-width: 50px;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          span {
            color: $text-secondary;

            @include themed() {
              color: t(textSecondary);
            }
          }

        }

      }
    }

    &:hover {
      .item__head p.title {
        color: $palette-primary-500;
      }
    }

    .item__head {
      img {
        margin-bottom: 10px;
      }

      p {
        &.title {
          font-family: "Roboto Condensed Bold";
          font-size: $font20;
          color: $text-primary;
          letter-spacing: 0.25px;
          margin-bottom: 10px;

          @include fontsized() {
            font-size: f(font20);
          }
        }

        &.subtitle {
          font-family: "Roboto Condensed Regular";
          font-size: $font14;
          line-height: 1.6;
          text-transform: uppercase;
          color: $text-secondary;
          letter-spacing: 1.25px;

          @include fontsized() {
            font-size: f(font14);
          }
        }
      }
    }

    .item__footer {
      display: flex;
      justify-content: center;

      p {
        &.address {
          display: flex;
          align-items: center;
          text-align: center;

          span {
            line-height: 0;

            &.sede {
              padding-left: 10px;
              font-family: "Roboto Condensed Bold";
              color: $text-secondary;
              font-size: $font16;
              line-height: 24px;
              letter-spacing: 0.15px;

              @include fontsized() {
                font-size: f(font16);
              }

              @include themed() {
                color: t(textSecondary);
              }
            }
          }

        }
      }
    }
  }

  .item__head {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    padding: 0 20px;

    img {
      max-width: 100%;
      width: 70px;
      height: 70px;
      object-fit: cover;
      margin-bottom: 10px;

      &.circle {
        border-radius: 50%;
      }
    }

    p {
      margin: 0;
      text-align: center;

      &.title {
        font-family: 'Roboto Condensed Bold';
        font-size: $font16;
        color: $palette-primary-500;
        margin-bottom: 5px;

        @include fontsized() {
          font-size: f(font16);
        }

        &.dark {
          color: $text-primary;

          @include themed() {
            color: t(textPrimary);
          }

        }
      }

      &.subtitle {
        font-family: 'Roboto Condensed Bold';
        font-size: $font14;
        line-height: 16px;
        letter-spacing: 1.25px;
        color: $text-secondary;
        margin-bottom: 10px;

        @include fontsized() {
          font-size: f(font14);
        }

        @include themed() {
          color: t(textSecondary);
        }
      }
    }
  }

  .item__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px 0;
    display: flex;
    //justify-content: space-between;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .item__info {
      padding-left: 20px;
      width: 100%;
      max-width: 170px;

      p {
        font-family: 'Roboto-Regular';
        font-size: $font14;
        color: rgba(0, 0, 0, 0.38);
        margin: 0;

        @include fontsized() {
          font-size: f(font14);
        }

        @include themed() {
          color: t(textSecondary);
        }

        &.number {
          font-size: $font12;
          letter-spacing: 0.4px;
          color: $text-secondary;

          @include fontsized() {
            font-size: f(font12);
          }

          @include themed() {
            color: t(textPrimary);
          }
        }
      }
    }

    .item__icon {
      padding-right: 20px;
      width: 100%;
      max-width: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      span {
        color: $text-secondary;

        @include themed() {
          color: t(textSecondary);
        }
      }

    }

  }
}

.card__landing {
  display: flex;
  flex-direction: column;
  box-shadow: $shadow-1;
  background: #fff;
  margin-bottom: 20px;

  @include themed() {
    background: t(bgCard);
  }

  @media #{$large-up} {
    min-height: 400px;
    flex-direction: row;
  }

  .iteml {
    flex-basis: 0;
    flex-grow: 1;
    flex: 0 0 100%;
    max-width: 100%;

    &.phone__mdl12 {
      flex: 0 0 100%;
      max-width: 100%;

    }

    &.mdl8 {
      @media #{$large-up} {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;

      }
    }

    &.mdl4 {
      @media #{$large-up} {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;

      }
    }

    .card__copy {
      padding: 20px;
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: space-between;

      @media #{$large-up} {
        padding: 60px;
      }

      .cont__circle__custom {
        position: absolute;
        right: -27px;
        top: 50%;
        z-index: 1;

        &.left__align {
          right: inherit;
          left: -27px;
        }

        span.cirle__white {
          width: 56px;
          height: 56px;
          box-shadow: $shadow-1;

          @include themed() {
            background: $palette-secondary-500;
          }

          i {
            color: $palette-secondary-500;

            @include themed() {
              color: #fff;
            }
          }
        }
      }

      p {
        &.title {
          font-family: "Roboto Condensed Regular";
          color: $palette-secondary-500;
          font-size: $font36;
          letter-spacing: -0.3px;

          @include fontsized() {
            font-size: f(font36);
          }

          @media #{$tablet-up} {
            font-size: $font60;
            line-height: 1.1;

            @include fontsized() {
              font-size: f(font60);
            }
          }

          &.violet {
            color: #a225a3;
          }
        }

        &.sub {
          color: $text-primary;
          font-family: "Roboto-Regular";
          font-size: $font16;
          line-height: 21px;
          letter-spacing: 0.5px;
          margin-bottom: 19px;

          @include fontsized() {
            font-size: f(font16);
          }

          @include themed() {
            color: t(textPrimary);
          }
        }

        &.desc {
          color: $text-primary;
          font-family: "Roboto Condensed Bold";
          font-size: $font16;
          line-height: 24px;
          letter-spacing: 0.15px;
          margin-bottom: 20px;

          @include fontsized() {
            font-size: f(font16);
          }

          @include themed() {
            color: t(textPrimary);
          }
        }
      }

      .action__btn {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media #{$large-up} {
          flex-direction: row-reverse;
        }

        &.oneaction {
          flex-direction: row-reverse;

          .item.btn__left {
            justify-content: flex-end;
          }
        }

        .item {
          flex-basis: 0;
          flex-grow: 1;
          flex: 0 0 50%;
          max-width: 50%;

          &.btn__right {
            display: flex;
            justify-content: flex-end;
          }

          &.btn__left {
            display: flex;
            justify-content: flex-start;

            @media #{$large-up} {
              justify-content: flex-end;
            }
          }
        }

        button.mdl-button {
          color: $text-secondary;
          min-width: 58px;

          @include themed() {
            color: $palette-primary-500;
          }
        }

        .cont__circle__custom {
          position: relative;
          right: 0;

          span.cirle__white {
            width: 56px;
            height: 56px;
            box-shadow: $shadow-1;

            i {
              color: $palette-secondary-500;
            }
          }
        }
      }
    }

    .card__img {
      display: flex;
      display: flex;
      flex-basis: 100%;
      height: 100%;
      position: relative;

      .cont__circle__custom {
        position: absolute;
        right: 5px;
        bottom: 5px;

        span.cirle__white {
          width: 56px;
          height: 56px;
          box-shadow: $shadow-1;

          i {
            color: $palette-secondary-500;
          }
        }
      }

      .cont__iconcard {
        position: absolute;
        top: inherit;
        left: 5px;
        bottom: 5px;

        @media #{$large-up} {
          top: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        span.icon__white {
          background: #fff;
          width: 56px;
          height: 56px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          @include themed() {
            background: t(bgdash-body);
          }

          @media #{$large-up} {
            width: 160px;
            height: 160px;
          }

          span.icon__data {

            // svg ajuste
            svg {
              width: 37px;
              height: 37px;

              @media #{$large-up} {
                width: 70px;
                height: 70px;
              }
            }

            .imgdata {
              path {
                fill: #000;

                @include themed() {
                  fill: $palette-secondary-500;
                }
              }
            }

            img {
              width: 37px;
              height: 37px;
              object-fit: contain;
              max-width: 100%;



              @media #{$large-up} {
                width: 70px;
                height: 70px;
              }
            }
          }
        }
      }

      img {
        //width: 100%;
        object-fit: cover;
        max-width: 100%;
        //height: 401px;
      }
    }
  }
}

.comp__shared {
  display: flex;
}
