.grid__title {
  padding: 20px 10px 5px;

  h1 {
    font-family: "Roboto-Regular";
    font-size: $font24;
    color: $text-primary;

    @media #{$large-up} {
      font-size: $font48;

      @include fontsized() {
        font-size: f(font48);
      }
    }

    @include themed() {
      color: t(textPrimary);
    }
  }

  p {
    font-family: "Roboto Condensed Regular";
    font-size: $font24;
    line-height: 1;
    color: $text-secondary;
    margin-bottom: 10px;

    @include fontsized() {
      font-size: f(font24);
    }

    @include themed() {
      color: t(textSecondary);
    }
  }
}

.cont__comment {
  display: flex;
  width: 100%;
  padding: 20px 10px 20px;

  .box__comment {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    margin-bottom: 10px;

    .item__comment {


      .copy__comment {
        flex-basis: 100%;
        max-width: 100%;

        &.txt-comille {
          padding-right: 10px;
        }

        p {
          font-family: "Roboto-Regular";
          font-size: $font14;
          color: $text-secondary;
          line-height: 21px;
          letter-spacing: 0.25px;

          @include fontsized() {
            font-size: f(font14);
          }

          @include themed() {
            color: t(textSecondary);
          }

          &.quotes {
            position: relative;
            top: 30px;
            color: $palette-primary-500;
            font-family: 'OpenSans-Bold';
            font-size: $font92;
            padding-right: 20px;
            letter-spacing: -1.5px;

            @include fontsized() {
              font-size: f(font92);
            }

            @include themed() {
              color: $palette-primary-500;

            }
          }
        }
      }

      .user__comment {
        display: flex;
        flex-direction: row;

        .cont__user {
          button.mdl-button {
            width: 40px;
            min-width: 40px;
            height: 40px;
            color: $palette-secondary-500;

            i {
              position: relative;
              left: -8px;
            }
          }
        }

        .copy__user {
          padding-left: 10px;

          p {
            color: $text-primary;
            font-family: "Roboto-Regular";
            font-size: $font14;
            line-height: 24px;
            letter-spacing: 0.1px;
            margin-bottom: 0;

            @include fontsized() {
              font-size: f(font14);
            }

            @include themed() {
              color: t(textPrimary);
            }

            &.subtitle {
              font-size: $font12;
              color: $text-secondary;
              line-height: 16px;
              letter-spacing: 0.4px;

              @include fontsized() {
                font-size: f(font12);
              }

              @include themed() {
                color: t(textSecondary);
              }
            }
          }
        }
      }
    }
  }
}

.cont__help-client {
  box-shadow: $shadow-2;
  background: #fafafa;
  margin-left: -41px;
  width: 100vw;
  margin-bottom: 20px;

  @include themed() {
    background: t(bgCard);
  }

  @media #{$tablet-up} {
    margin-left: 0;
    max-width: 340px;
    width: 100%;
  }

  @media #{$large-up} {
    max-width: 100%;
  }

  .head__help {
    padding: 0px 20px;
    border-bottom: 1px solid #d8d8d8;

    @include themed() {
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.14);
    }

    .mdl-textfield {
      width: 100%;

      i.mdl-icon-toggle__label {
        color: $text-primary;

        @include themed() {
          color: t(textSecondary);
        }
      }

      .mdl-textfield__input {
        border-bottom: 1px solid transparent;
      }

      .mdl-textfield__label {
        color: $text-primary;
        font-family: "Roboto Condensed Bold";
        font-size: $font16;

        @include fontsized() {
          font-size: f(font16);
        }

        @include themed() {
          color: t(textPrimary);
        }

        &:after {
          background-color: transparent;
        }
      }
    }
  }

  .title__hour {
    padding: 20px 20px 10px;

    p {
      text-transform: uppercase;
      font-family: "Roboto-Regular";
      font-size: $font12;
      color: $text-secondary;
      letter-spacing: 2px;
      line-height: 16px;
      margin-bottom: 5px;

      @include fontsized() {
        font-size: f(font12);
      }

      @include themed() {
        color: t(textSecondary);
      }
    }
  }

  .body__help {
    height: 350px;
    overflow-y: scroll;



    .box__help-in {
      background: #fff;
      margin: 10px 20px;
      padding: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      box-shadow: $shadow-1;

      @include themed() {
        background: t(bgCard);
      }

      &:hover {
        box-shadow: $shadow-3;

        .copy__help p.title {
          color: $palette-primary-500;

          @include themed() {
            color: $palette-primary-500;
          }
        }
      }

      .copy__help {
        display: flex;
        flex-direction: column;
        flex-basis: 80%;

        p {
          margin-bottom: 5px;

          &.title {
            font-family: "Roboto Condensed Bold";
            font-size: $font16;
            color: $text-primary;

            @include fontsized() {
              font-size: f(font16);
            }

            @include themed() {
              color: t(textPrimary);
            }
          }

          &.location {
            font-family: "Roboto-Regular";

            @include themed() {
              color: t(textSecondary);
            }
          }

          &.hour {
            font-family: "Roboto-Regular";

            @include themed() {
              color: t(textPrimary);
            }
          }

          span.ico {
            position: relative;
            top: 6px;
            line-height: 0;
          }

        }

      }

      .btn__help {
        display: flex;
        flex-direction: column;
        flex-basis: 20%;

        button.mdl-button {
          min-width: 50px;
          color: $text-secondary;

          @include themed() {
            color: t(textSecondary);
          }
        }
      }
    }
  }
}

.cont__help-btn {
  margin-bottom: 50px;

  button.mdl-button {
    box-shadow: $shadow-3;
    color: #fff;

  }
}

.card__ofert {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  box-shadow: $shadow-1;
  background: $bg-head;
  transition: box-shadow .2s cubic-bezier(.4, 0, 1, 1);

  @include themed() {
    //border: 1px solid #fff;
    background: t(bgCard);
  }

  &:hover {
    box-shadow: $shadow-3;
  }

  .card__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 12px 15px;

    .item__title {
      p {
        font-family: 'Roboto Condensed Regular';
        font-size: $font24;
        color: $text-primary;
        margin-bottom: 0;

        @include fontsized() {
          font-size: f(font24);
        }

        @include themed() {
          color: $palette-primary-500 !important;
        }


        &.mdl__blue {
          color: $palette-secondary-500;
        }

        &.mdl__red {
          color: #b71c1c;
        }

        &.mdl__yellow {
          color: $palette-primary-500;
        }

        &.mdl__green__black {
          color: #004c3f;
        }

        &.mdl__green {
          color: #8bc34a;
        }
      }
    }

    .item__left {
      margin-left: auto;

      .mdl-menu__outline,
      .mdl-menu__outline {
        @include themed() {
          background: t(bgdash-head);
        }
      }

      button.mdl-button {
        @include themed() {
          color: t(textSecondary);
        }
      }
    }
  }

  .card__body {
    .grid__colum {
      display: flex;
      //flex-direction: row;
      flex-flow: row wrap;

      .colmdl__custom {
        flex: 1 1 0px;
        padding-right: 0.9375rem;
        padding-left: 0.9375rem;
        min-width: 0;

        &.line__row {
          position: relative;
          flex-basis: 20%;


          &::after {
            position: absolute;
            right: 0;
            content: "";
            height: 20px;
            top: 30%;
            border-left: 1px solid $divider-color;
          }
        }
      }

      .copy {
        p {
          margin-bottom: 5px;

          &.title {
            font-family: 'Roboto-Regular';
            font-size: $font12;
            line-height: 16px;
            letter-spacing: 0.4px;

            @include fontsized() {
              font-size: f(font12);
            }

            @include themed() {
              //border: 1px solid #fff;
              color: t(textPrimary);
            }
          }

          &.sub {
            font-family: 'Roboto Condensed Bold';
            font-size: $font16;
            color: $text-secondary;
            line-height: 24px;
            letter-spacing: 0.15px;

            @include fontsized() {
              font-size: f(font16);
            }

            @include themed() {
              //border: 1px solid #fff;
              color: t(textSecondary);
            }
          }
        }
      }
    }

    .tag {
      padding: 10px .9375rem;

      .label__tag {
        padding: 5px;
        border-radius: 40px;
        border: 1px solid $divider-color;

        span {
          font-family: 'Roboto-Regular';
          color: $text-secondary;
          line-height: 20px;
          letter-spacing: 0.25px;

          @include themed() {
            //border: 1px solid #fff;
            color: t(textPrimary);
          }
        }
      }
    }

  }

  .card__footer {
    padding-bottom: 10px;

    .grid__colum {
      display: flex;
      //flex-direction: row;
      flex-flow: row wrap;

      .colmdl__custom {
        flex: 1 1 0px;
        padding-right: 0.9375rem;
        padding-left: 0.9375rem;
        min-width: 0;

        // justify-content: center;
        // align-items: center;
        &.col20porcent {
          flex-basis: 20%;
        }

        &.line__row {
          position: relative;
          flex-basis: 20%;


          &::after {
            position: absolute;
            right: 0;
            content: "";
            height: 20px;
            top: 30%;
            border-left: 1px solid $divider-color;
          }
        }
      }

      .cont__btn {
        margin: 0 auto;
        display: flex;
        text-align: right;
        align-items: center;
        justify-content: center;
        height: 100%;

        button.mdl-button {
          //margin-left: auto;
          color: $palette-secondary-500;
          min-width: 120px;

          .mdl-ripple {
            color: $text-white;
            background: $palette-secondary-500;
          }

          &:active {
            color: $text-white;
            background: $palette-secondary-500;
          }

          &:focus {
            color: $text-white;
            background: $palette-secondary-500;
          }
        }
      }

      .copy {
        &.location {
          display: flex;
          flex-direction: row;
          //justify-content: center;
          align-items: center;
          flex-grow: 1;
          flex-shrink: 1;

          .icon_location {
            padding-right: 10px;

            i {
              position: relative;
              top: 2px;
              color: $text-secondary;

              @include themed() {
                color: t(textSecondary);
              }
            }
          }

          p {
            &.title {
              font-family: 'Roboto Condensed Bold';
              font-size: $font16;
              color: $text-secondary;
              line-height: 24px;
              letter-spacing: 0.15px;
              margin-bottom: 0;

              @include fontsized() {
                font-size: f(font16);
              }

              @include themed() {
                color: t(textSecondary);
              }
            }
          }
        }

        p {
          margin-bottom: 5px;

          &.title {
            font-family: 'Roboto-Regular';
            font-size: $font12;
            line-height: 16px;
            letter-spacing: 0.4px;

            @include fontsized() {
              font-size: f(font12);
            }
          }

          &.sub {
            font-family: 'Roboto Condensed Bold';
            font-size: $font16;
            color: $text-secondary;
            line-height: 24px;
            letter-spacing: 0.15px;

            @include fontsized() {
              font-size: f(font16);
            }
          }
        }
      }
    }
  }

}

.card__group {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  box-shadow: $shadow-1;
  background: $bg-head;
  transition: box-shadow .2s cubic-bezier(.4, 0, 1, 1);

  @include themed() {
    //border: 1px solid #fff;
    background: t(bgCard);
  }

  &:hover {
    box-shadow: $shadow-3;
  }

  .card__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 12px 15px;

    .item__title {
      p {
        font-family: 'Roboto Condensed Regular';
        font-size: $font24;
        color: $text-primary;
        margin-bottom: 0;

        @include fontsized() {
          font-size: f(font24);
        }

        @include themed() {
          color: $palette-primary-500 !important;
        }

        &.title__group {
          font-family: "Roboto-Regular";
          font-size: $font12;
          line-height: 1.2;
          letter-spacing: 2px;
          margin-bottom: 5px;
          color: $text-secondary;

          @include fontsized() {
            font-size: f(font12);
          }

          @include themed() {
            color: t(textSecondary);
          }
        }

        &.title__normal {
          font-size: $font24;
          font-family: "Roboto-Regular";
          line-height: 1;

          @include fontsized() {
            font-size: f(font24);
          }

          @include themed() {
            color: t(textPrimary);
          }
        }


        &.mdl__blue {
          color: $palette-secondary-500;
        }

        &.mdl__red {
          color: #b71c1c;
        }

        &.mdl__yellow {
          color: $palette-primary-500;
        }

        &.mdl__green__black {
          color: #004c3f;
        }

        &.mdl__green {
          color: #8bc34a;
        }
      }
    }

    .item__left {
      margin-left: auto;

      .mdl-menu__outline,
      .mdl-menu__outline {
        @include themed() {
          background: t(bgdash-head);
        }
      }

      button.mdl-button {
        @include themed() {
          color: t(textSecondary);
        }
      }
    }
  }

  .card__body {
    .grid__colum {
      display: flex;
      //flex-direction: row;
      flex-flow: row wrap;

      .colmdl__custom {
        flex: 1 1 0px;
        padding-right: 0.9375rem;
        padding-left: 0.9375rem;
        min-width: 0;

        &.line__row {
          position: relative;
          flex-basis: 20%;


          &::after {
            position: absolute;
            right: 0;
            content: "";
            height: 20px;
            top: 30%;
            border-left: 1px solid $divider-color;
          }
        }
      }

      .copy {
        p {
          margin-bottom: 5px;

          &.title {
            font-family: 'Roboto-Regular';
            font-size: $font12;
            line-height: 16px;
            letter-spacing: 0.4px;

            @include fontsized() {
              font-size: f(font12);
            }

            @include themed() {
              //border: 1px solid #fff;
              color: t(textPrimary);
            }
          }

          &.sub {
            font-family: 'Roboto Condensed Bold';
            font-size: $font16;
            color: $text-secondary;
            line-height: 24px;
            letter-spacing: 0.15px;

            @include fontsized() {
              font-size: f(font16);
            }

            @include themed() {
              //border: 1px solid #fff;
              color: t(textSecondary);
            }
          }
        }
      }
    }

    .tag {
      padding: 10px .9375rem;

      .label__tag {
        padding: 5px;
        border-radius: 40px;
        border: 1px solid $divider-color;

        span {
          font-family: 'Roboto-Regular';
          color: $text-secondary;
          line-height: 20px;
          letter-spacing: 0.25px;

          @include themed() {
            //border: 1px solid #fff;
            color: t(textPrimary);
          }
        }
      }
    }

  }

  .card__footer {
    padding-bottom: 10px;

    .grid__colum {
      display: flex;
      //flex-direction: row;
      flex-flow: row wrap;

      .colmdl__custom {
        flex: 1 1 0px;
        padding-right: 0.9375rem;
        padding-left: 0.9375rem;
        min-width: 0;

        // justify-content: center;
        // align-items: center;
        &.col20porcent {
          flex-basis: 20%;
        }

        &.line__row {
          position: relative;
          flex-basis: 20%;


          &::after {
            position: absolute;
            right: 0;
            content: "";
            height: 20px;
            top: 30%;
            border-left: 1px solid $divider-color;
          }
        }
      }

      .cont__btn {
        margin: 0 auto;
        display: flex;
        text-align: right;
        align-items: center;
        justify-content: center;
        height: 100%;

        button.mdl-button {
          //margin-left: auto;
          color: $text-primary;
          min-width: 120px;

          @include themed() {
            //border: 1px solid #fff;
            color: t(textPrimary);
          }

          .mdl-ripple {
            color: $text-white;
            background: $palette-secondary-500;
          }

          &:active {
            color: $text-white;
            background: $palette-secondary-500;
          }

          &:focus {
            color: $text-white;
            background: $palette-secondary-500;
          }
        }
      }

      .copy {
        &.location {
          display: flex;
          flex-direction: row;
          //justify-content: center;
          align-items: center;
          flex-grow: 1;
          flex-shrink: 1;

          .icon_location {
            padding-right: 10px;

            i {
              position: relative;
              top: 2px;
              color: $text-secondary;

              @include themed() {
                color: t(textSecondary);
              }
            }
          }

          p {
            &.title {
              font-family: 'Roboto Condensed Bold';
              font-size: $font16;
              color: $text-secondary;
              line-height: 24px;
              letter-spacing: 0.15px;
              margin-bottom: 0;

              @include fontsized() {
                font-size: f(font16);
              }

              @include themed() {
                color: t(textSecondary);
              }
            }
          }
        }

        p {
          margin-bottom: 5px;

          &.title {
            font-family: 'Roboto-Regular';
            font-size: $font12;
            line-height: 16px;
            letter-spacing: 0.4px;

            @include fontsized() {
              font-size: f(font12);
            }
          }

          &.sub {
            font-family: 'Roboto Condensed Bold';
            font-size: $font16;
            color: $text-secondary;
            line-height: 24px;
            letter-spacing: 0.15px;

            @include fontsized() {
              font-size: f(font16);
            }
          }
        }
      }
    }
  }

}

.card__news {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  @media #{$tablet-only} {
    flex-direction: row;
  }

  @media #{$phone-down} {
    margin-bottom: 15px;
  }

  a.card__action {
    text-decoration: none;
    display: flex;
    flex-direction: column;

    @media #{$tablet-only} {
      flex-direction: row;
    }

    &:hover {
      p.title__card {
        color: $palette-primary-500;
      }
    }
  }

  .card__head {
    position: relative;
    padding-bottom: 15px;

    img {
      width: 100%;
      //max-width: 360px;
      max-width: 100%;
      object-fit: cover;
      box-shadow: $shadow-1;

      @media #{$phone-down} {
        box-shadow: $shadow-3;
      }
    }

    span.icon {
      position: absolute;
      bottom: 30px;
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      color: $palette-primary-500;
      justify-content: center;
      border: 1px solid $palette-primary-500;
      left: 15px;
    }

  }

  .card__body {
    @media #{$tablet-only} {
      padding-left: 20px;
    }

    p.title__card {
      font-family: 'Roboto-Regular';
      font-size: $font24;
      color: $text-secondary;
      line-height: 28px;

      @include fontsized() {
        font-size: f(font24);
      }

      @include themed() {
        //border: 1px solid #fff;
        color: t(textPrimary);
      }

      @media #{$phone-down} {
        font-size: $font16;
        color: $text-primary;

        @include fontsized() {
          font-size: f(font16);
        }
      }

      @media #{$tablet-only} {
        font-size: $font16;

        @include fontsized() {
          font-size: f(font16);
        }
      }
    }
  }

  .card__footer {
    display: flex;
    align-items: center;
    flex-direction: row;

    @media #{$tablet-only} {
      flex-direction: column;
      justify-content: flex-start;
    }

    .cont_tag {
      @media #{$tablet-only} {
        width: 100%;
        margin-bottom: 20px;
      }

      span {
        &.item__tag {
          font-size: $font14;
          line-height: 20px;
          letter-spacing: 0.25px;
          font-family: 'Roboto-Regular';
          color: $text-primary;
          border: 1px solid $divider-btn;
          border-radius: 30px;
          padding: 5px 10px;

          @include fontsized() {
            font-size: f(font14);
          }


          @include themed() {
            border: 1px solid #fff;
            color: t(textPrimary);
          }

        }
      }
    }

    .cont_date {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media #{$phone-down} {
        margin-left: inherit;
        //justify-content: flex-start;
        //width: 100%;
      }

      @media #{$tablet-only} {
        justify-content: flex-start;
      }

      span {
        display: block;
        line-height: 0;
        margin: 0;
        color: $text-link-color;

        @include themed() {
          color: t(textPrimary);
        }

        &.date-num {
          font-family: 'Roboto-Regular';
          color: $text-primary;
          line-height: 16px;
          letter-spacing: 2px;
          font-size: $font12;
          margin-left: 10px;

          @include fontsized() {
            font-size: f(font12);
          }

          @include themed() {
            color: t(textSecondary);
          }
        }
      }
    }
  }
}
