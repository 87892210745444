//@import '../../../node_modules/motion-ui/'

.comp-img {

  &.circle {
    display: flex;
    border-radius: 100%;
    object-fit: contain;

    img {
      box-shadow: $shadow-3;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      object-fit: cover;
    }
  }
}

.cont-circle-ico {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  box-shadow: $shadow-2;
}

.circle_note {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: $bg-secondary;
  border: 1px solid $palette-primary-500;
  height: 50px;
  width: 50px;

  @include themed() {
    background: t(bgdash-body);
  }
}

p.line {
  border-bottom: 1px solid $divider-color;
}

.no-padding {
  padding: 0;
}

.mdl-layout__content {

  @include themed() {
    background: t(bgdash-body);
  }
}

// .mdl-layout__drawer.is-visible
.mdl-layout__drawer {
  @include themed() {
    background: t(bgdash-body);
  }

  &.mdl__right {
    left: inherit;
    right: -360px;
    transform: translateX(100%);

    @media #{$tablet-up} {
      width: 360px;
      transform: translateX(360px);
    }

    &.is-visible {
      transform: translateX(-360px);
    }
  }

  &.custom {
    width: 100%;
    position: fixed;
    transform: translateX(-100%);

    @media #{$tablet-up} {
      width: 360px;
      transform: translateX(-360px);
    }

    &.is-visible {
      transform: translateX(0);
    }

  }

  .box__user {
    background: $bg-secondary;

    @include themed() {
      background: t(bgdash-body);
    }

    .panel__yellow {
      background: $palette-primary-500;
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 48px;
      box-shadow: $shadow-1;
      padding: 0 15px;

      span {
        font-family: "Roboto Condensed Bold";
        font-size: $font16;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: $text-primary;

        @include fontsized() {
          font-size: f(font16);
        }

        &.icon {

          line-height: 0;
          margin-left: auto;
        }
      }

    }

    .head__user {
      padding: 20px 20px 52px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      //margin-bottom: 52px;

      @include themed() {
        background: t(bgCard);
      }


      a.mdl-button.custom {
        margin-left: auto;
        width: 23px;
        min-width: 23px;
        height: 41px;
        color: $text-link-color;

        @include themed() {
          color: t(textPrimary);
        }
      }

      .logo {
        background: url('../images/logo/Logo_Universidad-de-La-Salle.svg') no-repeat;
        width: 100px;
        height: 30px;
        background-position: center;

        @include themed() {
          background: t(logoWhite) no-repeat;
          background-position: center;
        }
      }
    }

    .data__user {
      min-height: 305px;




      .mdl-tabs__tab-bar {
        position: relative;
        top: -0.6px;
        box-shadow: $shadow-2;

        @include themed() {
          background: t(bgCard);
        }

        .mdl-tabs__tab {
          width: 100%;

          &.is-active {
            color: $palette-primary-500;
          }
        }
      }

      nav {
        &.not__top {
          top: 0px;
        }

        &.link__fast {
          position: relative;
          border-top: 1px solid #ddd;

          @include themed() {
            border-top: 1px solid rgba(221, 221, 221, 0.13);
          }

          &.no_border {
            border-top: none;

            @include themed() {
              border-top: none;
            }
          }

          p.activity {
            padding-left: 20px;
            padding-top: 20px;
            font-family: "Roboto-Regular";
            font-size: $font12;
            color: $text-secondary;
            line-height: 16px;
            letter-spacing: 0.4px;

            @include fontsized() {
              font-size: f(font12);
            }

            @include themed() {
              color: t(textSecondary);
            }
          }

          ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
              a.general_link {
                font-family: 'Roboto Condensed Bold';
                display: flex;
                flex-direction: row;
                align-items: center;
                text-decoration: none;
                padding: 12px 16px;
                font-size: $font16;
                line-height: 24px;
                letter-spacing: 0.15px;
                color: $text-primary;

                @include fontsized() {
                  font-size: f(font16);
                }

                @include themed() {
                  color: t(textPrimary);
                }

                i {
                  color: $palette-primary-500;
                }

                &.search__result {
                  padding-left: 42px;
                  font-size: $font16;
                  line-height: 28px;
                  letter-spacing: 0.5px;
                  font-family: "Roboto-Regular";
                  color: $text-secondary;

                  @include fontsized() {
                    font-size: f(font16);
                  }

                  @include themed() {
                    color: t(textSecondary);
                  }
                }

                &.grey__color {
                  font-family: 'Roboto-Regular';
                  color: $text-secondary;

                  @include themed() {
                    color: t(textSecondary);
                  }

                  i {
                    color: $text-secondary;

                    @include themed() {
                      color: t(textSecondary);
                    }
                  }
                }

                &.black__color {
                  font-family: 'Roboto Condensed Bold';
                  color: $text-primary;

                  @include themed() {
                    color: t(textPrimary);
                  }

                  i {
                    color: $text-primary;

                    @include themed() {
                      color: t(textPrimary);
                    }
                  }
                }


                span {
                  //line-height: 0;
                  padding-left: 8px;
                }

                &.active {
                  color: $palette-primary-500 !important;

                  i {
                    color: $palette-primary-500 !important;
                  }
                }

                &.inactive {
                  color: $text-secondary !important;

                  i {
                    color: $text-secondary !important;
                  }
                }

                &:hover {
                  color: $palette-primary-500;
                  background: #fff8e1;

                  @include themed() {
                    background: t(bgCard);
                  }
                }
              }
            }
          }
        }

        &.sub__general {
          position: relative;
          top: -15px;
          border-top: 1px solid #ddd;

          @include themed() {
            //border: 1px solid #fff;
            border-top: 1px solid rgba(221, 221, 221, 0.13);
          }

          ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
              a.general_link {
                font-family: 'Roboto Condensed Bold';
                display: block;
                text-decoration: none;
                padding: 12px 16px;
                font-size: $font16;
                line-height: 24px;
                letter-spacing: 0.15px;
                color: $text-secondary;

                @include fontsized() {
                  font-size: f(font16);
                }

                @include themed() {
                  //border: 1px solid #fff;
                  color: t(textSecondary);
                }

                &.active {
                  color: $palette-primary-500;
                }

                &:hover {
                  color: $palette-primary-500;
                  background: #fff8e1;
                }
              }
            }
          }
        }

        &.general {
          position: relative;
          top: -15px;

          ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
              a.general_link {
                font-family: 'Roboto Condensed Bold';
                display: block;
                text-decoration: none;
                padding: 12px 16px;
                font-size: $font16;
                line-height: 24px;
                letter-spacing: 0.15px;
                color: $text-primary;

                @include fontsized() {
                  font-size: f(font16);
                }

                @include themed() {
                  //border: 1px solid #fff;
                  color: t(textPrimary);
                }

                &:hover {
                  color: $palette-primary-500;
                  background: #fff8e1;

                  @include themed() {
                    background: t(bgCard);
                  }
                }
              }
            }
          }
        }
      }

      .user__session {
        position: relative;
        background: $bg-secondary;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;

        @include themed() {
          background: t(bgCard);
        }

        img.circle {
          width: 50px;
          height: 50px;
          max-width: 50px;
          border-radius: 50%;
          object-fit: cover;
          box-shadow: $shadow-2;
          margin-bottom: 20px;
        }

        a.mdl-button.custom,
        button.mdl-button.custom {
          color: $palette-secondary-500;
        }

        p.name__user {
          font-family: 'Roboto Condensed Regular';
          font-size: $font20;
          color: #757575;
          letter-spacing: -0.17px;
          margin-bottom: 0;

          @include fontsized() {
            font-size: f(font20);
          }

          @include themed() {
            color: t(textPrimary);
          }
        }
      }

      .content__search {
        .mdl-textfield {
          overflow-x: hidden;
          position: relative;
          width: 100%;

          a.mdl-button {
            min-width: 20px;
            position: absolute;
            right: 10px;
            top: 13px;

            @include themed() {
              color: t(textPrimary);
            }
          }

          .icon__search {
            color: $text-secondary;
            position: absolute;
            top: 20px;
            left: 10px;
            line-height: 0;

            @include themed() {
              color: t(textPrimary);
            }
          }

          .mdl-textfield__label.custom {
            padding-left: 42px;

            @include themed() {
              color: t(textPrimary);
            }
          }

          .mdl-textfield__input {
            padding: 4px 0 15px;
            padding-left: 40px;
            width: calc(100% - 40px);

            @include themed() {
              color: t(textPrimary);
            }

            @media #{$tablet-up} {
              width: 356px;
            }
          }
        }
      }
    }
  }
}

.grid-center {
  justify-content: center;
}

.indicator__result {
  padding: 15px 0 10px 0;

  p {
    color: $text-secondary;
    font-family: 'Roboto-Regular';
    font-size: $font14;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin: 0;

    @include fontsized() {
      font-size: f(font14);
    }

    @include themed() {
      //border: 1px solid #fff;
      color: t(textPrimary);
    }

    span {
      font-family: 'Roboto-Bold';
      font-weight: bold;
    }

  }
}

.info__location {
  padding: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;

  &.col_row {
    @media #{$tablet-up} {
      flex-direction: row;
    }
  }

  @media #{$large-up} {
    padding: 0 0 20px;
    flex-direction: row;
  }

  .location__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    &.col6-md {
      @media #{$tablet-up} {
        flex-basis: 50%;
        max-width: 50%;

      }
    }

    @media #{$large-up} {
      padding: 0 15px;
    }

    .copy__location {
      p {
        margin: 0;

        &.title {
          font-family: 'Roboto Condensed Bold';
          font-size: $font16;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: $text-secondary;

          @include fontsized() {
            font-size: f(font16);
          }

          @include themed() {
            color: t(textPrimary);
          }
        }

        &.sub {
          font-family: 'Roboto-Regular';
          font-size: $font12;
          line-height: 16px;
          letter-spacing: 2px;
          color: $text-secondary;

          @include fontsized() {
            font-size: f(font12);
          }

          @include themed() {
            color: t(textSecondary);
          }
        }
      }
    }

    .icon_local {
      padding-right: 10px;

      &.blank {
        padding-right: 30px;
      }

      i {
        color: $text-secondary;

        @include themed() {
          color: t(textSecondary);
        }
      }
    }
  }
}

.info__teacher {
  @media #{$large-up} {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;

  }
}

.cont__teacher {
  padding: 20px;
  padding-top: 20px;



  p.title__teacher {
    font-family: 'Roboto Condensed Bold';
    font-size: $font16;
    color: $text-secondary;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 10px;

    @include fontsized() {
      font-size: f(font16);
    }

    @include themed() {
      color: t(textSecondary);
    }
  }

  .row__teacher {
    display: inline-block;
    width: 100%;



    .item__teacher {
      padding-right: 5px;
      padding-left: 5px;
      min-width: 0;
      display: inline-block;

      button.mdl-button {
        color: $text-secondary;
        min-width: 54px;
        padding: 10px;
        height: 54px;
        border-radius: 50%;

        .mdl-ripple {
          background: $palette-primary-100;
        }

        &:active {
          background: $palette-primary-100;
          color: $palette-primary-500;
        }

        &:focus {
          background: $palette-primary-100;
          color: $palette-primary-500;

          // .mdl-ripple {
          //   background: $palette-primary-50;
          // }
        }
      }

      .img__teacher {
        img {
          border-radius: 50%;
          width: 40px;
          height: 40px;
          object-fit: cover;
          max-width: 100%;
        }
      }
    }
  }
}

.cont__form {
  .box__video {


    .cont__video {

      @media #{$tablet-up} {
        box-shadow: $shadow-3;
      }

      img {
        width: 100%;
        object-fit: cover;
        max-width: 100%;
      }
    }
  }
}

.form__box {
  background: $bg-head;
  box-shadow: $shadow-3;
  margin-bottom: 20px;

  @include themed() {
    background: t(bgDashLink);
  }

  @media #{$tablet-up} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  }

  .copy__form {
    padding: 20px;
    margin: 0 auto;
    text-align: center;

    p {
      margin: 0;

      &.number__form {
        font-family: 'OpenSans-Bold';
        font-size: 80px;
        line-height: 1.2;
        color: $text-secondary;

        @include themed() {
          color: t(textPrimary);
        }
      }

      &.text__form {
        font-family: 'Roboto-Regular';
        font-size: $font12;
        letter-spacing: 0.4px;

        @include fontsized() {
          font-size: f(font12);
        }

        @include themed() {
          color: t(textSecondary);
        }
      }

      &.progress__title {
        font-family: 'Roboto-Regular';
        font-size: $font14;
        text-transform: uppercase;
        color: $palette-secondary-500;
        line-height: 16px;
        letter-spacing: 1.25px;

        @include fontsized() {
          font-size: f(font14);
        }

      }
    }
  }

  .bar__form {
    //position: relative;
    //margin: 0 auto;
    //width: 100%;

    @media #{$tablet-up} {
      padding: 0 20px;
    }

    span {
      position: absolute;
      min-width: 10px;

      &.value_range {
        color: $text-primary;
      }
    }
  }

  .form__money {
    display: flex;
    flex-direction: row;
    padding: 20px;

    .icon__money {
      padding-right: 10px;

      img {
        width: 50px;
        height: 50px;
      }
    }

    .copy__money {
      p {
        margin: 0;

        &.title {
          font-family: 'Roboto Condensed Bold';
          font-size: $font16;
          color: $text-secondary;
          line-height: 24px;
          letter-spacing: 0.15px;

          @include fontsized() {
            font-size: f(font16);
          }

          @include themed() {
            color: t(textPrimary);
          }
        }

        &.sub {
          font-family: 'Roboto-Regular';
          font-size: $font14;
          color: $text-secondary;
          line-height: 20px;
          letter-spacing: 0.25px;

          @include fontsized() {
            font-size: f(font14);
          }

          @include themed() {
            color: t(textSecondary);
          }
        }
      }
    }
  }

  .info__money_data {
    padding: 30px 20px 10px;

    p {
      margin: 0;

      &.title {
        font-family: 'Roboto-Regular';
        font-size: $font12;
        color: $text-secondary;
        line-height: 16px;
        letter-spacing: 0.4px;

        @include fontsized() {
          font-size: f(font12);
        }

        @include themed() {
          color: t(textSecondary);
        }
      }

      &.sub {
        font-family: 'Roboto-Regular';
        font-size: $font12;
        color: $text-primary;
        line-height: 16px;
        letter-spacing: 2px;

        @include fontsized() {
          font-size: f(font12);
        }

        @include themed() {
          color: t(textPrimary);
        }
      }
    }
  }

  .btn__money {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 20px 40px;

    button.mdl-button.custom {
      @include themed() {
        color: t(textSecondary);
      }
    }

    button.mdl-button.custom.border__btn {
      color: $palette-secondary-500;
    }
  }
}

.progress__mdl {
  display: flex;
  position: relative;
  background: #bfdff0;
  height: 1rem;
  border-radius: .25rem;

  .progress__meter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    transition: width .6s ease;

    &.bg_info {
      background-color: $palette-secondary-500;
    }
  }
}

.select__money {
  padding: 10px 20px;
  border-bottom: 1px solid $divider-color;
  border-top: 1px solid $divider-color;

  .mdl-textfield {
    width: 100%;
  }

  .mdl-icon-toggle__label {
    @include themed() {
      color: t(textSecondary);
    }
  }

  .getmdl-select .mdl-textfield__input {
    color: $text-primary;
    font-family: 'Roboto Condensed Bold';
    font-size: $font16;
    line-height: 24px;
    letter-spacing: 0.15px;
    border-bottom: 1px solid transparent;

    @include fontsized() {
      font-size: f(font16);
    }

    @include themed() {
      color: t(textPrimary);
    }
  }
}

.mdl-layout__content {
  .grid__layout {
    @media #{$large-up} {
      display: flex;
      overflow: hidden;

    }
  }
}

.global__left {
  @include themed() {
    background: t(bgdash-body);
  }

  @media #{$large-up} {
    margin-right: 305px;
    //height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    display: block;
    height: 100%;
    overflow: auto;
  }
}

.global__right {
  display: none;

  @include themed() {
    background: $bg-dash-link-dark;
  }

  @media #{$large-up} {
    background: #fff;
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 322px;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 2;

    .global-data__right {
      width: 100%;
    }
  }
}

.top__global {
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  background: $bg-dashboard-dark;

  a.back__arrow {
    margin-right: 10px;
    color: rgba(254, 254, 254, 0.54);
  }

  p.title {
    color: $palette-primary-500;
    margin: 0;
    font-family: 'Roboto Condensed Bold';
    line-height: 24px;
    letter-spacing: 0.15px;
  }
}

.cont-global__form {
  padding: 20px;

  .input__form {
    .mdl-textfield {
      position: relative;
      width: 100%;

      .mdl-textfield__input {
        @include themed() {
          border-bottom: 1px solid t(textSecondary);
        }
      }

      .mdl-textfield__label {
        color: $text-primary;
        font-family: 'Roboto Condensed Bold';
        font-size: $font16;
        line-height: 24px;
        letter-spacing: 0.15px;

        @include fontsized() {
          font-size: f(font16);
        }

        @include themed() {
          color: t(textPrimary);
        }
      }

      .mdl__icon {
        position: absolute;
        top: 17px;
        right: 0;

        i {
          color: $text-secondary;

          @include themed() {
            color: t(textSecondary);
          }
        }
      }
    }
  }

  .term_condition {
    margin-bottom: 10px;
    min-height: 45px;

    .mdl-checkbox__label {
      font-size: $font12;
      color: $text-secondary;
      font-family: 'Roboto-Regular';
      line-height: 16px;
      letter-spacing: 0.4px;

      @include fontsized() {
        font-size: f(font12);
      }

      @include themed() {
        color: t(textSecondary);
      }
    }
  }

  .cont_btn_form {
    margin: 0 auto;
    text-align: center;

    a.next_process {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: $font12;
      color: $text-secondary;
      font-family: 'Roboto Condensed Regular';
      line-height: 16px;
      letter-spacing: 1.25px;

      @include fontsized() {
        font-size: f(font12);
      }

      @include themed() {
        color: $palette-primary-500;
      }
    }

    button.mdl-button.custom {
      width: 100%;
      background: #e4e4e4;
      color: $text-secondary;
      font-family: 'Roboto Condensed Regular';
      font-size: $font14;
      line-height: 16px;
      letter-spacing: 1.25px;
      margin-bottom: 20px;

      @include fontsized() {
        font-size: f(font14);
      }

      @include themed() {
        background: t(bgdash-body);
        color: rgba(255, 255, 255, 0.38);
      }
    }
  }

}

.cont__suscription {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  //padding: 5px;
  width: 100%;
  height: 70px;
  bottom: 0;

  @media #{$tablet-up} {
    display: none;
  }

  &.md-active {
    @media #{$tablet-up} {
      display: flex !important;
    }

    @media #{$large-up} {
      display: none !important;
    }
  }

  &.primary {
    background: $palette-primary-500;
  }

  &.secondary {
    background: $palette-secondary-500;
  }

  button.mdl-button {
    font-family: 'Roboto Condensed Regular';
    font-size: $font14;
    color: $text-primary;
    width: 100%;
    letter-spacing: 1.25px;
    height: 70px;

    @include fontsized() {
      font-size: f(font14);
    }

    &.primary {
      background: $palette-primary-500;
    }

    &.secondary {
      background: $palette-secondary-500;
    }
  }
}

// .mdl-layout__container{
//   he
// }

.pdg_top {
  padding-top: 70px;
}

.cont__timeline {
  margin-bottom: 40px;

  .cont__title {
    @media #{$tablet-up} {
      margin: 0 auto;
      text-align: center;
      width: 100%;
      max-width: 600px;
    }

    p.title__timeline {
      font-family: "Roboto-Regular";
      font-size: $font24;
      letter-spacing: 0.18px;
      line-height: 1.1;
      margin-bottom: 10px;
      position: relative;
      top: -20px;

      @include fontsized() {
        font-size: f(font24);
      }

      @include themed() {
        color: t(textPrimary)
      }

      @media #{$tablet-up} {
        text-align: left;
        font-size: $font34;

        @include fontsized() {
          font-size: f(font34);
        }
      }

    }
  }


  .cont__acordeon {
    position: relative;

    &::after {
      position: absolute;
      top: -20px;
      bottom: 15px;
      left: 28px;
      right: 0;
      z-index: -1;
      content: "";
      border-left: 1px solid $palette-primary-500;
    }

    @media #{$tablet-up} {
      margin: 0 auto;
      text-align: center;
      width: 100%;
      max-width: 300px;
    }

    // .mdl-accordion__content-wrapper {
    //   overflow-x: visible !important;
    //   overflow-y: hidden !important;
    // }
  }

  .inter__mdl {
    padding-top: 20px;
    padding-left: 15px;

    .toogle__mdl {
      position: relative;

      .snaklabel {
        @media #{$tablet-up} {
          position: absolute;
          left: -230px;
        }

        @media #{$large-up} {
          max-width: 340px;
          left: -350px;
        }
      }
    }

    .mdl-navigation__link.other {
      margin-bottom: 15px;

      span.name {
        padding-left: 25px;
      }

      &:hover {
        color: $palette-secondary-500;

        @include themed() {
          color: $palette-secondary-500;
        }

        span.name {
          @include themed() {
            color: $palette-secondary-500;
          }
        }

        .circle__20x {
          border: 3px solid $palette-secondary-500;

          @include themed() {
            border: 3px solid $palette-secondary-500;
          }
        }
      }
    }
  }

  .circle__20x {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border: 3px solid $palette-primary-500;
    border-radius: 50%;
  }

  .circle__open {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 8px solid $palette-primary-500;
    border-radius: 50%;

    .circle__in {
      display: flex;
      width: 20px;
      background: $palette-primary-500;
      height: 20px;
      border: 3px solid $palette-primary-500;
      border-radius: 50%;
    }
  }

  .mdl-accordion.mdl-accordion--opened {
    border: none;

    .mdl-accordion__content-wrapper {
      overflow: visible !important;
    }

    .circle__open {
      box-shadow: $shadow-4;

      .circle__in {
        background: #000;
        border: 3px solid #000;

        @include themed() {
          background: #fff;
          border: 3px solid #fff;
        }
      }
    }
  }

  .mdl-navigation__link {
    display: flex;
    flex-direction: row;
    align-items: center;

    span.name {
      font-family: "Roboto Condensed Bold";
      padding-left: 10px;
      font-size: $font20;
      letter-spacing: 0.25px;
      color: $text-secondary;

      @include fontsized() {
        font-size: f(font20);
      }

      @include themed() {
        color: t(textSecondary)
      }
    }
  }
}

.snaklabel {
  display: none;
  position: relative;
  margin-bottom: 10px;
  //z-index: 1;
  padding: 10px;
  background: $palette-secondary-500;
  box-shadow: $shadow-2;
  width: 100%;
  max-width: 320px;
  transition: all 2s linear;

  @media #{$tablet-up} {
    max-width: 180px;
    top: -10px;
    left: -230px;
  }

  &.active {
    opacity: 1;
    display: block;
  }

  &.inactive {
    display: none;
    opacity: 0;
  }

  p {
    color: #fff;
  }
}

.overdata__active {
  @media #{$tablet-up} {
    overflow: visible !important;
  }
}

.overdata__inactive {
  @media #{$tablet-up} {
    overflow: hidden !important;
  }
}

.cont__circle__custom {
  position: absolute;
  right: -34px;

  span.circle__arrow {
    box-shadow: $shadow-3;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: linear-gradient(to right, rgba(255, 189, 6, 1) 0%, rgba(255, 175, 3, 1) 100%);
  }

  span.cirle__white {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #fff;

    i {
      color: $text-secondary;
    }
  }
}

.reveal-overlay.fade-in {
  transition-duration: 10ms;
}

// .fade-in {
//   // A long, long fade
//   @include mui-fade(in, $duration: 10s);
// }
.reveal {
  &.custom {
    padding: 0;
    border: none;
    background: transparent;
    display: flex !important;
    justify-content: center;
    align-items: center;

    &.fade-in {
      transition-duration: 150ms;
    }

    //animation-duration: 0.1s;
    @media #{$large-up} {
      display: inherit;
      width: 100%;
      max-width: 320px;
    }

    .cont__close {
      position: absolute;
      top: 10px;
      right: 10px;

      @media #{$tablet-up} {
        position: fixed;
        top: 10px;
        right: 10px;
      }

      button.close-button {
        background: transparent;
        border-style: hidden;

        @include themed() {
          color: t(textPrimary);
        }

      }
    }

    .box__dialog {
      margin: 0 auto;
      width: 100%;
      max-width: 320px;
      box-shadow: $shadow-3;
      background: #fff;

      @include themed() {
        background: t(bgCard);
      }


      .body__dialog {
        padding: 10px;

        p {
          color: $text-secondary;

          @include themed() {
            color: t(textSecondary);
          }
        }
      }

      .footer__dialog {
        padding: 5px;
        margin: 0 auto;
        text-align: right;

        button.mdl-button.cancel {
          color: $text-secondary;

          @include themed() {
            color: t(textSecondary);
          }
        }

        button.mdl-button.accept {
          color: $palette-secondary-500;
        }
      }

      .head__dialog {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $divider-color;
        padding: 10px 5px;

        @include themed() {
          border-bottom: 1px solid rgba(255, 255, 255, 0.27);
        }

        p {
          font-family: "Roboto Condensed Bold";
          font-size: $font16;
          line-height: 24px;
          letter-spacing: 0.15px;
          margin: 0;
          color: $text-primary;

          @include fontsized() {
            font-size: f(font16);
          }

          @include themed() {
            color: t(textPrimary);
          }

          span.icon {
            top: 5px;
            position: relative;
            line-height: 0;
            padding-right: 10px;

            &.info {
              color: $palette-secondary-500;
            }

            &.warning {
              color: $palette-primary-500;
            }

            &.error {
              color: #cc0000;
            }
          }
        }
      }

    }


    .fade-in {
      // A long, long fade
      //@include mui-fade(in, $duration: 0.5s);
    }
  }
}

// .imgdata {
//   path {
//     fill: red;
//   }
// }

.prueba__font {
  font-size: $font16;

  @include fontsized() {
    font-size: f(font16);
  }

}


.slider__progress {
  position: absolute;
  width: 100%;
  height: 5px;
  overflow-x: hidden;

  .line {
    position: absolute;
    opacity: 0.4;
    background: $palette-primary-100;
    width: 150%;
    height: 5px;

    &.blue {
      background: $palette-secondary-100;
    }
  }

  .subline {
    position: absolute;
    background: $palette-primary-500;
    height: 5px;

    &.blue {
      background: $palette-secondary-500;
    }

    &.inc {
      animation: increase 2s infinite;
    }

    &.dec {
      animation: decrease 2s 0.5s infinite;
    }
  }
}



@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }

  to {
    left: 130%;
    width: 100%;
  }
}

@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }

  to {
    left: 110%;
    width: 10%;
  }
}

.mdl-menu {
  &.custom {
    min-width: 300px;

    li.mdl-menu__item {

      &.title {
        display: flex;
        align-items: center;
        cursor: default;
        border-bottom: 1px solid $divider-color;

        @include themed() {
          border-bottom: 1px solid rgba(189, 189, 189, 0.2);
        }

        &:hover {
          background: none;
        }
      }

      a.link_item {
        text-decoration: none;
        color: $text-secondary;
        display: flex;
        align-items: center;

        @include themed() {
          color: t(textSecondary);
        }

        &.active {
          color: $palette-primary-500;

          span {
            //margin-left: 0;
          }

          i {
            //margin-left: auto;
          }
        }

        &:hover {
          color: $palette-primary-500;
        }

        i {
          margin-right: 10px;
        }

      }


    }

    p {
      margin: 0;

      &.mdl-menu__title {
        display: flex;
        align-items: center;

        span {
          font-family: "Roboto Condensed Bold";
          font-size: 16px;
          color: $text-primary;
          letter-spacing: 0.15px;
          margin-left: 10px;

          @include themed() {
            color: t(textPrimary);
          }
        }

        i {
          @include themed() {
            color: t(textPrimary);
          }
        }
      }
    }
  }
}