section.hero__info {

  background: #e7eff1;

  @include themed() {
    background: t(bgdash-body);
  }

  .box__black {
    position: relative;
    top: -25px;
    padding: 20px;
    margin-left: 20px;
    min-height: 400px;
    box-shadow: $shadow-3;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background: $bg-dashboard-dark;
    display: flex;
    //align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;

    @include themed() {
      background: t(bgDashLink);
    }

    @media #{$tablet-up} {
      margin-left: 106px;
      align-items: center;
      min-height: 160px;
    }

    @media #{$large-up} {
      margin-left: 60px;
    }

    .grid__info {
      @media #{$tablet-up} {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .item__info {
        @media #{$tablet-up} {
          display: flex;
          flex-direction: row;

          &:first-child {
            border-bottom: 1px solid $divider-color;
            margin-bottom: 10px;
          }
        }
      }
    }

    .box__info {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $divider-color;
      padding: 10px;
      color: $text-white;
      margin-right: 20px;

      @media #{$tablet-up} {
        margin-right: 0px;
      }
    }

    .copy__info {
      @media #{$tablet-up} {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem;
      }

      @media #{$large-up} {
        padding-right: 40px;
        padding-left: 40px;
      }

      p {
        &.title {
          font-family: 'Roboto-Regular';
          font-size: $font12;
          color: $text-white;
          line-height: 16px;
          letter-spacing: 0.4px;
          margin-bottom: 5px;

          @include fontsized() {
            font-size: f(font12);
          }
        }

        &.sub {
          font-family: 'Roboto Condensed Bold';
          font-size: $font20;
          color: $palette-primary-500;
          line-height: 24px;
          letter-spacing: 0.19px;

          @include fontsized() {
            font-size: f(font20);
          }
        }
      }
    }
  }
}

section.program__body {
  background: #e7eff1;

  @include themed() {
    background: t(bgdash-body);
  }

  .cont__info__val {
    flex-direction: row;
    margin-bottom: 20px;
    width: 100%;
    justify-content: space-between;
    display: none;

    @media #{$tablet-up} {
      display: flex;
    }

    .item__val {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;

      .box__val {
        background: $bg-head;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        padding: 10px;
        margin-right: 10px;
        box-shadow: $shadow-1;

        @include themed() {
          background: t(bgDashLink);
          color: t(textSecondary);
        }

        a.mdl-button {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 20px;
          width: 20px;

          @include themed() {
            background: t(bgDashLink);
            color: t(textSecondary);
          }
        }
      }

      .copy {
        p {
          color: $text-secondary;
          margin: 0;

          &.title {
            font-family: 'Roboto-Regular';
            font-size: $font12;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: $text-secondary;

            @include fontsized() {
              font-size: f(font12);
            }

            @include themed() {
              color: t(textSecondary);
            }
          }

          &.sub {
            font-family: 'Roboto Condensed Bold';
            font-size: $font20;
            line-height: 24px;
            letter-spacing: 0.19px;
            color: $text-primary;

            @include fontsized() {
              font-size: f(font20);
            }

            @include themed() {
              color: t(textPrimary);
            }
          }
        }
      }
    }
  }

  //padding: 20px;
  .cont__form {
    @media #{$tablet-up} {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 10px 15px 100px;
      //margin-right: -15px;
      //margin-left: -15px;
    }

    .item__form {
      @media #{$tablet-up} {
        position: relative;
        display: flex;
        flex-grow: 1;
        flex-basis: 50%;
        max-width: 50%;
      }

      @media only screen and (min-width : 481px) and (max-width : 674px) {
        display: flex;
        flex-grow: 1;
        flex-basis: 100%;
        max-width: 100%;
      }



      .item__col {
        padding: 0 15px;

        @media #{$tablet-up} {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .card_info {
      margin-bottom: 45px;
    }

    .box__video {
      .copy_comment {
        padding: 40px 20px 20px;
        display: flex;
        flex-direction: row;

        p.comille {
          font-family: 'OpenSans-Bold';
          font-size: $font93;
          color: $palette-primary-500;
          letter-spacing: -1.5px;

          @include fontsized() {
            font-size: f(font93);
          }
        }

        p.sub {
          padding-left: 10px;
          font-family: 'Roboto-Regular';
          font-size: $font14;
          color: $text-secondary;

          @include fontsized() {
            font-size: f(font14);
          }

          @include themed() {
            color: t(textSecondary);
          }
        }

        .user__comment {
          display: flex;
          flex-direction: row;

          .icon_comment {
            padding-right: 10px;

            i {
              color: $palette-secondary-500;
            }
          }

          p {
            margin-bottom: 5px;

            &.sub__comment {
              font-family: 'Roboto-Regular';
              font-size: $font12;
              line-height: 16px;
              letter-spacing: 0.4px;
              color: rgba(0, 0, 0, 0.38);

              @include fontsized() {
                font-size: f(font12);
              }

              @include themed() {
                color: t(textSecondary);
              }
            }

            &.title__comment {
              color: $text-primary;
              font-family: 'Roboto-Regular';
              font-size: $font14;
              line-height: 24px;
              letter-spacing: 0.1px;

              @include fontsized() {
                font-size: f(font14);
              }

              @include themed() {
                color: t(textPrimary);
              }
            }
          }
        }
      }
    }
  }

  .info__tab {
    .copy__tab {
      padding: 20px;
      padding-top: 30px;

      p {
        font-family: 'Roboto-Regular';
        font-size: $font16;
        line-height: 21px;
        letter-spacing: 0.5px;
        color: $text-secondary;

        @include fontsized() {
          font-size: f(font16);
        }

        @include themed() {
          color: t(textPrimary);
        }

        &.title {
          font-family: 'Roboto Condensed Bold';
          font-size: $font16;
          color: $text-primary;
          line-height: 24px;
          letter-spacing: 0.15px;

          @include fontsized() {
            font-size: f(font16);
          }

          @include themed() {
            color: t(textPrimary);
          }
        }

        &.sub__title {
          font-family: 'Roboto Condensed Regular';
          font-size: $font36;
          color: $palette-primary-500;
          //line-height: 24px;
          letter-spacing: -0.3px;
          margin-bottom: 25px;

          @include fontsized() {
            font-size: f(font36);
          }
        }
      }
    }

    .tag__info {
      padding: 20px;

      @media #{$tablet-up} {
        display: flex;
        flex-direction: row;
      }

      .tag__item {
        margin-bottom: 15px;

        @media #{$tablet-up} {
          padding: 0 15px;
        }

        button.mdl-button {
          text-transform: none;

          @include themed() {
            border: 1px solid t(textPrimary);
            color: t(textPrimary);
          }
        }

        p {
          font-family: 'Roboto-Regular';
          font-size: $font12;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: $text-secondary;
          margin-bottom: 5px;

          @include fontsized() {
            font-size: f(font12);
          }

          @include themed() {
            color: t(textSecondary);
          }
        }
      }
    }

  }

  .mdl-tabs {
    padding-top: 20px;
    margin-bottom: 0;

    .mdl-tabs__tab {
      min-width: 140px;

      @media #{$tablet-up} {
        width: 100%;
        min-width: 80px;
      }

      &.is-active {
        color: $palette-primary-500;
      }
    }
  }

  .mdl-tabs__tab-bar {
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: flex-start;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);

    @include themed() {
      border-bottom: 1px solid t(textSecondary);
    }

    @media #{$tablet-up} {
      justify-content: space-between;
    }
  }
}

section.hero__static {
  display: flex;
  //align-items: center;
  width: 100%;
  min-height: calc(100vh - 190px);
  background: url('../images/pics/college_students.jpg') no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  padding-top: 64px;

  .black__hero {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $text-primary;
  }

  //top: -64px;
  .copy {
    position: relative;
    padding: 90px 20px 20px;

    p {
      &.title {
        font-family: 'Roboto-Regular';
        font-size: $font16;
        color: $text-white;
        line-height: 24px;
        letter-spacing: 0.11px;

        @include fontsized() {
          font-size: f(font16);
        }

        @media #{$tablet-up} {
          font-size: $font24;
          line-height: 24px;
          letter-spacing: 0.17px;

          @include fontsized() {
            font-size: f(font24);
          }
        }
      }

      &.sub {
        font-family: 'OpenSans-Bold';
        font-size: $font36;
        line-height: 42px;
        color: $palette-primary-500;
        letter-spacing: -0.58px;

        @include fontsized() {
          font-size: f(font36);
        }

        @media #{$tablet-up} {
          font-size: $font60;
          padding-right: 50px;
          line-height: 70px;
          letter-spacing: -0.97px;

          @include fontsized() {
            font-size: f(font60);
          }
        }
      }
    }

    .copy__btn {
      padding-top: 20px;
      //padding: 20px;
      display: flex;
      flex-direction: column;

      @media #{$tablet-up} {
        flex-direction: row;
      }

      .grid__btn {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media #{$tablet-up} {
          padding-left: 20px;
        }
      }

      a.white__mdl {
        width: 50px;
        min-width: 50px;
        color: $text-white;
      }

      button {
        margin-bottom: 20px;

        .icon {
          padding-right: 5px;
        }
      }
    }
  }

}

section.hero__event {
  display: flex;
  flex-direction: column;
  //flex-grow: 1;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px;
  //min-height: 300px;
  background-color: $bg-dashboard-dark;
  height: 100%;
  max-height: 520px;
  position: relative;
  //margin: 0 auto;
  //flex-wrap: nowrap;

  @media #{$phone-down} {}

  .copy {
    h1 {
      padding: 0;
      margin: 0;
      color: $palette-primary-500;
      font-family: 'OpenSans-Bold';
      font-size: $font36;
      line-height: 42px;
      letter-spacing: 0.58px;
      margin-bottom: 20px;

      @include fontsized() {
        font-size: f(font36);
      }

      @media #{$tablet-only} {
        font-size: $font48;
        line-height: 62px;
        letter-spacing: -0.77px;

        @include fontsized() {
          font-size: f(font48);
        }
      }
    }

    .cont__date {
      display: flex;
      flex-direction: column;

      @media #{$tablet-only} {
        flex-direction: row;
      }

      .item__date {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 10px;

        @media #{$tablet-only} {
          justify-content: center;
        }

        @media #{$phone-down} {
          margin-left: inherit;
          justify-content: flex-start;
          width: 100%;
        }

        span {
          display: block;
          line-height: 0;
          margin: 0;
          color: $text-secondary-dark;

          @include themed() {
            color: t(textPrimary);
          }

          &.date-num {
            font-family: 'Roboto-Regular';
            color: $text-secondary-dark;
            line-height: 16px;
            letter-spacing: 2px;
            font-size: $font12;
            margin-left: 10px;

            @include fontsized() {
              font-size: f(font12);
            }

            @include themed() {
              color: t(textSecondary);
            }
          }
        }
      }
    }
  }

  .copy__footer {

    padding: 20px;
    //margin: 0 20px;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
    width: 100%;

    .box__date {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 60px;
      border: 1px solid $palette-primary-500;

      span {
        font-family: 'OpenSans-Bold';
        font-size: $font36;
        letter-spacing: -0.58px;
        color: $palette-primary-500;

        @include fontsized() {
          font-size: f(font36);
        }
      }
    }

    .cont__left {
      margin-left: 20px;
    }

    .cont__right {
      margin-right: 20px;

      a.mdl-button {
        color: $palette-primary-500;
      }

    }
  }
}

section.write {
  position: relative;
  background: $palette-primary-500;
  min-height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  .bg_write {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .title__wrapper {
    position: absolute;
    left: 20px;
    top: 50px;

    @media #{$tablet-only} {
      left: 60px;
      top: 60px;
    }

    @media #{$large-up} {
      left: 80px;
      top: 80px;
    }

  }

  .copy {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;

    @media #{$large-up} {
      width: 70%;
    }

    @media #{$tablet-only} {
      width: 60%;
    }

    h1 {
      font-family: 'OpenSans-Bold';
      font-size: $font48;
      color: $text-primary;
      letter-spacing: -0.77px;

      @include fontsized() {
        font-size: f(font48);
      }

      @media #{$large-up} {
        font-size: $font92;

        @include fontsized() {
          font-size: f(font92);
        }
      }
    }

    p {
      font-family: 'Roboto-Regular';
      color: $text-primary;
      text-align: center;
      font-size: $font16;
      line-height: 28px;
      letter-spacing: 0.5px;

      @include fontsized() {
        font-size: f(font16);
      }
    }
  }

  button.section__btn {
    background: $text-primary;
    color: $palette-primary-500;
  }

}

section.search {
  position: relative;
  background: #fafafa;
  min-height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;

  .copy {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;

    @media #{$large-up} {
      width: 70%;
    }

    @media #{$tablet-only} {
      width: 60%;
    }

    .cont__icon {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        width: 96px;
        height: 96px;
        margin-right: 10px;
      }
    }

    h1 {
      font-family: 'OpenSans-Bold';
      font-size: $font48;
      color: $palette-primary-500;
      letter-spacing: -0.77px;

      @include fontsized() {
        font-size: f(font48);
      }

      @media #{$large-up} {
        font-size: $font92;

        @include fontsized() {
          font-size: f(font92);
        }
      }
    }

    p {
      font-family: 'Roboto-Regular';
      color: $text-primary;
      text-align: center;
      font-size: $font16;
      line-height: 28px;
      letter-spacing: 0.5px;

      @include fontsized() {
        font-size: f(font16);
      }
    }
  }

  button.section__btn {
    color: $text-primary;
    background: $palette-primary-500;
  }
}

section.accreditation {
  .box__quality {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    .bg__black {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba(18, 18, 18, 0.8);
    }

    .grid__item {
      display: flex;
      flex-direction: column;

      @media #{$large-up} {
        flex-direction: row;
        min-height: 320px;
      }

      .item {
        flex-basis: 100%;
        max-width: 100%;

        @media #{$large-up} {
          flex-basis: 50%;
          max-width: 50%;
        }

        .copy {
          display: flex;
          flex-direction: column;
          //align-items: center;
          justify-content: center;
          padding: 30px 20px;
          height: 100%;

          p {
            font-family: 'Roboto-Regular';
            font-size: $font14;
            line-height: 21px;
            color: $text-primary;
            letter-spacing: 0.25px;

            @include fontsized() {
              font-size: f(font14);
            }

            &.subtop {
              font-family: 'Roboto Condensed Bold';
              font-size: $font16;
              line-height: 24px;
              letter-spacing: 0.15px;
              color: $text-secondary;
              text-transform: uppercase;

              @include fontsized() {
                font-size: f(font16);
              }

              @include themed() {
                color: t(textSecondary);
              }
            }

            &.title {
              font-family: 'Roboto Condensed Regular';
              font-size: $font36;
              letter-spacing: -0.3px;
              color: $palette-primary-500;

              @include fontsized() {
                font-size: f(font36);
              }

              @include themed() {
                color: $palette-primary-500;

              }
            }

            &.sub1 {
              font-family: 'Roboto Condensed Bold';
              font-size: $font16;
              line-height: 24px;
              letter-spacing: 0.15px;
              color: $text-primary;

              @include fontsized() {
                font-size: f(font16);
              }

              @include themed() {
                color: t(textPrimary);
              }
            }

            &.sub2 {
              font-family: 'Roboto-Regular';
              font-size: $font14;
              line-height: 21px;
              letter-spacing: 0.25px;
              color: $text-secondary;

              @include fontsized() {
                font-size: f(font14);
              }

              @include themed() {
                color: t(textSecondary);
              }
            }
          }
        }
      }
    }

    .wrapper__logo {
      -webkit-overflow-scrolling: touch;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      overflow-x: auto;
      width: 100%;
      height: 100%;
      margin-bottom: 40px;

      @media #{$large-up} {
        //flex-wrap: wrap;
      }

      .item__wrap {
        display: flex;
        flex-basis: 100%;
        max-width: 350px;

        .content-img {
          padding: 10px;
          width: 130px;

          img {
            //width: 200px;
            max-width: 100%;
          }
        }

        //padding: 10px 20px;

      }
    }
  }
}
