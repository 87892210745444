.tags__label {
  padding: 20px 0 0px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media #{$phone-down} {
    padding-top: 5px;
  }

  @media #{$tablet-up} {
    padding-top: 0px;
  }

  button.mdl-button {
    width: auto;
    margin-right: 15px;
    margin-bottom: 10px;
    position: relative;
    display: flex;
    align-items: center;

    @media #{$phone-down} {
      margin-bottom: 10px;
    }

    @include themed() {
      border: 1px solid t(textSecondary);
      color: t(textSecondary);
    }

    span.icon {
      margin-left: 15px;
      color: $text-link-color;
      line-height: 0;

      @include themed() {
        //border: 1px solid t(textSecondary);        
        color: t(textSecondary);
      }

      i {
        font-size: 18px;
      }

    }

    &:hover {
      span.icon {
        color: $palette-primary-500;
      }
    }

    &.custom-round {
      &:focus {
        box-shadow: $shadow-3;

        @include themed() {
          //border: 1px solid t(textSecondary);
          background: t(bgDashLink);
          color: $palette-primary-500;
        }
      }
    }
  }
}

.filter__btn {
  padding: 20px 0 0px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  button.mdl-button {
    width: auto;
    margin-right: 15px;
    margin-bottom: 10px;
    position: relative;
    display: flex;
    align-items: center;
    background: $bg-secondary;

    @include themed() {
      //border: 1px solid t(textSecondary);
      background: t(bgDashLink);
      color: t(textSecondary);
    }

    &:hover {
      box-shadow: $shadow-3;
      color: $palette-primary-500;

      span.icon {
        color: $palette-primary-500;
      }
    }

    &.round {
      border-radius: 30px;
    }

    &.custom-round {
      &:focus {
        box-shadow: $shadow-3;

        @include themed() {
          //border: 1px solid t(textSecondary);
          background: t(bgDashLink);
          color: $palette-primary-500;
        }
      }
    }

    span.icon {
      margin-right: 15px;
      color: $text-link-color;
      line-height: 0;

      @include themed() {
        color: t(textSecondary);
      }

      i {
        font-size: 18px;
      }

    }

  }
}
