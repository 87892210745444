.dashboard {
  .dashboard-head {
    padding: 29px 0 0 0;
    background: $bg-head;

    @include themed() {
      background: t(bgdash-head);
    }

    .user-data {
      display: flex;

      .name-view {
        padding-left: 10px;

        p {
          color: $text-primary;
          font-family: 'Roboto-Regular';
          //@include font-size(12px);
          font-size: $font12;
          letter-spacing: 0.4px;
          line-height: 16px;
          margin-bottom: 0;

          @include fontsized() {
            font-size: f(font12);
          }

          @include themed() {
            color: t(textPrimary);
          }

        }

        h1 {
          font-family: 'OpenSans-Bold';
          font-size: $font36;
          //@include font-size(36px);
          line-height: initial;
          letter-spacing: -0.58px;
          color: $palette-primary-500;
          margin: 0;

          @include fontsized() {
            font-size: f(font36);
          }
        }
      }
    }

    .calendar {
      padding: 0;

      @media #{$medium-and-up} {
        display: flex;
        flex-direction: row;
      }

      .cal-date {
        display: flex;

        .copy {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          i {
            color: $text-link-color;

            @include themed() {
              color: t(textPrimary);
            }
          }

          p {
            font-family: 'Roboto-Regular';
            color: $text-primary;
            line-height: 24px;
            letter-spacing: 0.1px;
            font-size: $font14;
            //@include font-size(14px);
            padding-left: 10px;
            margin: 0;

            @include fontsized() {
              font-size: f(font14);
            }

            //margin-bottom: 10px;
            @include themed() {
              color: t(textSecondary);
            }

            span {
              color: $text-secondary;

              @include themed() {
                color: t(textSecondary);
              }
            }
          }
        }

        p.date-hd {
          font-family: 'Roboto Condensed Bold';
          color: $text-primary;
          line-height: 24px;
          letter-spacing: -0.4px;
          font-size: $font48;
          //@include font-size(14px);
          padding-right: 10px;
          padding-top: 10px;
          margin: 0;
          margin-bottom: 10px;

          @include fontsized() {
            font-size: f(font48);
          }

          @include themed() {
            color: t(textSecondary);
          }

          @media #{$medium-and-up} {
            display: flex;
            //align-items: center;
          }

          @media #{$large-and-up} {
            display: flex;
            //align-items: center;
            font-size: $font60;
            letter-spacing: -0.5px;

            @include fontsized() {
              font-size: f(font60);
            }
          }

          span {
            color: $text-secondary;

            @include themed() {
              color: t(textPrimary);
            }
          }
        }
      }

      .cal-info {
        //padding-right: 20%;

        h2 {
          color: $text-primary;
          font-family: 'Roboto Condensed Regular';
          font-size: $font24;
          line-height: 21px;
          letter-spacing: -0.2px;
          margin: 0;
          margin-bottom: 10px;

          @include fontsized() {
            font-size: f(font24);
          }

          @include themed() {
            color: t(textPrimary);
          }
        }

        p.sub {
          font-family: 'Roboto Condensed Regular';
          color: $text-secondary;

          @include themed() {
            color: t(textSecondary);
          }
        }
      }
    }
  }

  .dashboard-body {
    padding-top: 50px;
    background: $bg-dashboard;

    @include themed() {
      background: t(bgdash-body);
    }

    .info-program {


      .title {
        padding-left: 10px;
        display: flex;
        align-items: center;

        i {
          color: $text-primary;

          &.outline {
            font-family: 'Material Icons Outlined';
          }

          @include themed() {
            color: t(textPrimary);
          }
        }

        h3 {
          padding: 0;
          margin: 0;
          font-family: 'Roboto-Regular';
          font-size: $font24;
          color: $text-primary;
          padding-left: 10px;

          @include fontsized() {
            font-size: f(font24);
          }

          @include themed() {
            color: t(textPrimary);
          }
        }
      }

      // .content-btn {}
    }

    .content-hour {
      padding-top: 25px;

      .content-title {
        p.title {
          font-family: 'Roboto-Regular';
          font-size: $font12;
          color: $text-secondary;
          line-height: 16px;
          letter-spacing: 2px;
          margin-bottom: 5px;

          @include fontsized() {
            font-size: f(font12);
          }

          @include themed() {
            color: t(textSecondary);
          }
        }
      }

      .card_custom_calendar {
        box-shadow: $shadow-3;
        background: $bg-head;

        @include themed() {
          background: t(bgdash-head);
        }

        .mdl-tabs__tab {
          line-height: 25px;
          height: 50px;
          padding: 0 4px;

          &.is-active {
            span.month {
              color: $palette-primary-500;
            }
          }

          .indicator-date {
            display: flex;
            flex-direction: column;

            span.blank {
              display: block;
              height: 25px;
              line-height: 25px;
            }
          }

        }

        .list-content {
          height: 615px;
          overflow-y: auto;
          padding-top: 15px;

          ul {
            margin: 0;
            list-style: none;
            padding: 0;
          }

          .box-class {
            border: 1px solid $divider-color;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px;
            margin: 5px 5px 10px 5px;

            @include themed() {
              border: none;
              box-shadow: $shadow-1;
            }

            //margin-bottom: 10px;
            .box_right {
              width: 100%;
            }

            .box_left {
              margin-left: auto;
            }

            a.icon-down {
              display: flex;
              padding: 10px;
              align-items: center;
              justify-content: center;
              text-decoration: none;
              color: $text-link-color;

              @include themed() {
                color: t(textSecondary);
              }

              &:hover {
                background: $bg-btn-secondary;
                color: $palette-primary-500;

                @include themed() {
                  background: none;
                  color: t(yellowPrimary500);
                }
              }
            }

            .box_head {
              p {
                margin: 0;

                &.title {
                  font-family: 'Roboto Condensed Bold';
                  font-size: $font16;
                  line-height: 24px;
                  letter-spacing: 0.15px;
                  color: $text-primary;

                  @include fontsized() {
                    font-size: f(font16);
                  }

                  @include themed() {
                    color: t(textPrimary);
                  }
                }

                &.sub {
                  font-family: 'Roboto-Regular';
                  font-size: $font12;
                  line-height: 24px;
                  letter-spacing: 0.15px;
                  color: $text-secondary;

                  @include fontsized() {
                    font-size: f(font12);
                  }

                  @include themed() {
                    color: t(textSecondary);
                  }
                }
              }
            }

            .box_body {
              display: flex;
              flex-direction: row;

              .item {
                //width: 50%;

                &.border {
                  padding-right: 10px;
                  border-right: 1px solid $divider-color;
                }

                p {
                  color: $text-link-color;
                  font-family: 'Roboto-Regular';
                  font-size: $font12;
                  line-height: 16px;
                  letter-spacing: 0.4px;
                  padding-right: 5px;
                  margin: 0;

                  @include fontsized() {
                    font-size: f(font12);
                  }

                  @include themed() {
                    color: t(textSecondary);
                  }

                  &.aula {
                    padding-left: 10px;
                  }

                  span.hour {
                    color: $text-primary;

                    @include themed() {
                      color: t(textPrimary);
                    }
                  }

                }
              }

            }

            .box_footer {
              padding-top: 5px;

              .item {
                display: flex;
                flex-direction: row;
                align-items: center;

                span {
                  line-height: 0;
                  color: $text-link-color;
                  padding-right: 5px;

                  @include themed() {
                    color: t(textPrimary);
                  }
                }

                p {
                  color: $text-link-color;
                  font-family: 'Roboto-Regular';
                  font-size: $font14;
                  line-height: 20px;
                  letter-spacing: 0.25px;
                  padding-right: 5px;
                  margin: 0;

                  @include fontsized() {
                    font-size: f(font14);
                  }

                  @include themed() {
                    color: t(textSecondary);
                  }

                  span.hour {
                    color: $text-primary;
                  }

                }
              }
            }
          }

        }
      }
    }

    .plan-student {
      display: flex;
      flex-direction: column;

      .title-content {
        display: flex;
        align-items: center;

        span {
          color: $text-secondary;
          padding-right: 5px;

          @include themed() {
            color: t(textPrimary);
          }
        }

        h3 {
          font-family: 'Roboto-Regular';
          color: $text-secondary;
          font-size: $font24;
          margin: 0;

          @include fontsized() {
            font-size: f(font24);
          }

          @include themed() {
            color: t(textPrimary);
          }
        }
      }

      .evaluation-teacher {
        .item-teacher {
          padding-top: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid #d8d8d8;

          .date-teacher {
            display: flex;
            flex-direction: row;
            align-items: center;

            .ico-date {
              span {
                position: relative;
                top: 3px;
                line-height: 0;
                color: $palette-primary-500;

                &.normal {
                  color: $text-primary;

                  @include themed() {
                    color: t(textPrimary);
                  }
                }

              }
            }

            p {
              margin: 0;
              color: $palette-primary-500;

              &.date {
                padding: 0 5px;

                &.normal {
                  color: $text-primary;

                  @include themed() {
                    color: t(textPrimary);
                  }
                }
              }

              &.limite {
                padding: 2px 10px;
                border-radius: 4px;
                color: $palette-primary-500;
                background: $bg-card-dark;
              }
            }
          }

          .title-teacher {
            padding: 20px 0;

            p {
              margin: 0;

              &.title {
                font-family: 'Roboto-Regular';
                font-size: $font34;
                color: $text-primary;
                letter-spacing: 0.25px;
                margin-bottom: 10px;

                @include fontsized() {
                  font-size: f(font34);
                }

                @include themed() {
                  color: t(textPrimary);
                }
              }

              &.sub {
                font-family: 'Roboto-Regular';
                font-size: $font14;
                color: $text-secondary;
                letter-spacing: 0.25px;

                @include fontsized() {
                  font-size: f(font14);
                }

                @include themed() {
                  color: t(textSecondary);
                }
              }
            }
          }

          .body-teacher {
            display: flex;
            flex-direction: row;

            .ico-box {
              border: 1px solid #979797;
              height: 50px;
              width: 50px;
              display: flex;
              align-items: center;
              justify-content: center;

              i {
                @include themed() {
                  color: t(textPrimary);
                }
              }
            }

            .copy {
              display: flex;
              justify-content: center;
              flex-direction: column;
              padding-left: 10px;

              p {
                margin: 0;
                color: $text-secondary;

                &.title {
                  font-family: 'Roboto-Regular';
                  font-size: $font12;
                  line-height: 16px;
                  color: $text-secondary;
                  letter-spacing: 2px;

                  @include fontsized() {
                    font-size: f(font12);
                  }

                  @include themed() {
                    color: t(textSecondary);
                  }
                }

                &.sub {
                  font-family: 'Roboto-Regular';
                  font-size: $font14;
                  color: $text-secondary;
                  //line-height: 16px;
                  letter-spacing: 0.25px;

                  @include fontsized() {
                    font-size: f(font14);
                  }

                  @include themed() {
                    color: t(textSecondary);
                  }
                }
              }
            }
          }
        }
      }

      .card_note {
        width: 100%;
        background: transparent;
        box-shadow: $shadow-1;
        padding-bottom: 50px;

        @include themed() {
          background: t(bgdash-head);
        }

        @media #{$large-and-up} {
          box-shadow: none;
          display: flex;
          flex-direction: row;
          padding-bottom: 0;
          margin-bottom: 10px;
        }

        .note-info {
          display: flex;
          flex-direction: row;

          .center-note {
            width: 100%;

            .note-indicator {
              padding-top: 30px;
              display: flex;
              flex-direction: column;
              width: 100%;
              align-items: center;

              p.title {
                font-family: 'Roboto-Regular';
                font-size: $font12;
                color: $text-secondary;
                line-height: 16px;
                letter-spacing: 0.4px;
                margin: 0;
                margin-bottom: 10px;

                @include fontsized() {
                  font-size: f(font12);
                }

                @include themed() {
                  color: t(textPrimary);
                }
              }

              span.number {
                font-family: 'Roboto Condensed Bold';
                line-height: 24px;
                letter-spacing: 0.15px;

                @include themed() {
                  color: t(yellowPrimary500);
                }
              }

            }
          }
        }

        .media-note {
          width: auto;
          //width: 238px;
          position: relative;
          margin: 0 auto;
          padding: 30px 27px 10px 27px;
          border: 1px solid $palette-primary-100;

          @media #{$large-and-up} {
            width: 238px;
          }



          .forma {
            background: url('../../../images/pics/cuadros.svg') no-repeat;
            //background-position: center;
            width: 100%;
            height: 100px;
            position: absolute;
            left: -1px;
            top: 41px;
          }

          .copy {
            position: relative;
            z-index: 1;

            p {
              text-align: center;
              margin: 0;
              margin-bottom: 5px;

              &.sub {
                font-family: 'Roboto-Regular';
                font-size: $font12;
                line-height: 16px;
                letter-spacing: 2px;

                @include fontsized() {
                  font-size: f(font12);
                }

                @include themed() {
                  color: t(textSecondary);
                }
              }

              &.title {
                font-family: 'OpenSans-Bold';
                font-size: $font93;
                line-height: 1;
                color: $text-primary;
                letter-spacing: -1.5px;

                @include fontsized() {
                  font-size: f(font93);
                }

                @include themed() {
                  color: t(textPrimary);
                }

              }
            }
          }


        }
      }

      .info_system {
        padding-top: 20px;
        padding-bottom: 30px;

        .info_title {
          display: flex;
          align-items: center;
          flex-grow: 1;

          .copy {
            padding-left: 10px;

            p {
              font-size: $font24;
              font-family: 'Roboto Condensed Regular';
              color: $text-primary;
              letter-spacing: -0.2px;
              margin: 0;

              @include fontsized() {
                font-size: f(font24);
              }

              @include themed() {
                color: t(textPrimary);
              }
            }
          }

          .indicator {
            .cont-circle-ico {
              width: 56px;
              height: 56px;
              background: $palette-primary-500;

              span {
                color: $text-primary;
                line-height: 0;
              }
            }
          }
        }

        .list-info {
          margin-top: 20px;

          @media #{$large-and-up} {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          }

          a {
            text-decoration: none;

            @media #{$large-and-up} {
              width: 50%;
              //margin: 0 1px;
            }

            &.full {
              @media #{$large-and-up} {
                width: 100%;
                //margin: 0 1px;
              }
            }
          }

          .box-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            border: 1px solid $divider-color;
            padding: 10px 20px;
            min-height: 70px;
            flex-grow: 1;
            position: relative;
            margin-bottom: 20px;



            .tag-img {
              width: 80px;
              height: 60px;
              //background: t(svgBook) no-repeat;
              background: url('../images/svgs/book.svg') no-repeat;
              background-position: center;
              background-size: contain;
              position: absolute;
              right: 0px;
              opacity: 0.3;

              @include themed() {
                background: t(svgBook) no-repeat;
              }

              &.virtual {
                width: 80px;
                height: 60px;
                background: url('../images/svgs/training.svg') no-repeat;
                background-size: contain;

                @include themed() {
                  background: t(svgVirtual) no-repeat;
                }
              }
            }

            .ico-data {
              padding-right: 20px;

              span {
                color: $text-link-color;

                @include themed() {
                  color: t(textSecondary);
                }

                &.active {
                  color: $palette-primary-500;
                }
              }

              //padding: 50px;
            }

            .tag {
              display: flex;
              align-items: center;
              margin-left: auto;
              padding-right: 20px;

              span {
                color: $text-link-color;
                padding-right: 10px;

                @include themed() {
                  color: t(textSecondary);
                }

              }

              p {
                color: $text-link-color;
                margin: 0;

                &.sub {
                  font-family: 'Roboto-Regular';
                  font-size: $font12;
                  line-height: 16px;
                  letter-spacing: 0.4px;

                  @include fontsized() {
                    font-size: f(font12);
                  }
                }

                &.title {
                  font-family: 'Roboto Condensed Bold';
                  font-size: $font24;
                  line-height: 24px;
                  letter-spacing: 0.23px;

                  @include fontsized() {
                    font-size: f(font24);
                  }

                  &.active {
                    color: $palette-primary-500;
                  }

                  &.number {
                    font-size: $font16;
                    letter-spacing: 0.15px;

                    @include fontsized() {
                      font-size: f(font16);
                    }

                    @include themed() {
                      color: t(textSecondary);
                    }
                  }
                }
              }

            }

            .box-copy {
              width: 100%;

              @media #{$large-and-up} {
                width: auto;
              }

              p {
                color: $text-link-color;
                margin: 0;

                &.sub {
                  font-family: 'Roboto-Regular';
                  font-size: $font12;
                  line-height: 16px;
                  letter-spacing: 0.4px;

                  @include fontsized() {
                    font-size: f(font12);
                  }

                  @include themed() {
                    color: t(textSecondary);
                  }
                }

                &.title {
                  font-family: 'Roboto Condensed Bold';
                  font-size: $font24;
                  line-height: 24px;
                  letter-spacing: 0.23px;

                  @include fontsized() {
                    font-size: f(font24);
                  }

                  @include themed() {
                    color: t(textPrimary);
                  }

                  &.active {
                    color: $palette-primary-500;
                  }

                  &.number {
                    font-size: $font16;
                    letter-spacing: 0.15px;

                    @include fontsized() {
                      font-size: f(font16);
                    }
                  }
                }
              }
            }

          }
        }
      }
    }

    .content-cards {
      margin-bottom: 30px;

      .title-content {
        display: flex;

        h3 {
          font-family: 'Roboto-Regular';
          color: $text-secondary;
          font-size: $font24;
          margin: 0;

          @include fontsized() {
            font-size: f(font24);
          }

          @include themed() {
            color: t(textPrimary);
          }
        }

        a.next-event {
          font-family: 'Roboto Condensed Regular';
          color: $palette-secondary-500;
          display: flex;
          line-height: 16px;
          font-size: $font14;
          letter-spacing: 1.25px;
          align-items: center;
          text-decoration: none;
          margin-left: auto;

          @include fontsized() {
            font-size: f(font14);
          }

          span.ico-arrow {
            padding-left: 10px;
          }
        }
      }

      .card_custom {
        @include themed() {
          background: t(bgdash-head);
        }

        // .info-card {}
      }
    }

    .content-files {
      margin-bottom: 30px;

      .list-files {
        padding: 16px;
        border: 1px solid $divider-color;

        @include themed() {
          background: t(bgdash-head);
          border: none;
        }

        .list-title {
          display: flex;
          align-items: center;

          span {
            color: $text-primary;
            margin-left: auto;

            @include themed() {
              color: t(textPrimary);
            }
          }

          p {
            margin: 0;

            &.sub {
              font-family: 'Roboto-Regular';
              font-size: $font14;
              line-height: 20px;
              letter-spacing: 0.25px;
              margin-left: auto;

              @include fontsized() {
                font-size: f(font14);
              }

              @include themed() {
                color: t(textSecondary);
              }
            }

            &.title {
              font-family: 'Roboto Condensed Bold';
              font-size: $font16;
              color: $text-primary;
              line-height: 24px;
              letter-spacing: 0.15px;

              @include fontsized() {
                font-size: f(font16);
              }

              @include themed() {
                color: t(textPrimary);
              }
            }
          }
        }
      }
    }

    .content-links {
      display: flex;
      flex-direction: column;

      .box-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        //width: 100%;
        border-radius: 10px;
        padding: 16px;
        background: $bg-secondary;
        box-shadow: $shadow-3;
        border: 1px solid $palette-secondary-500;
        margin-bottom: 15px;

        @include themed() {
          background: t(bgDashLink);
          border: 1px solid t(borderLink);
        }

        .cont-circle-ico {
          i {
            color: $palette-secondary-500;
          }
        }

        .ico-view {
          margin-left: auto;

          a {
            color: $text-link-color;

            @include themed() {
              color: t(textSecondary);
            }

            &:hover {
              color: $palette-secondary-500;
            }
          }

        }

        .box-copy {
          padding-left: 15px;

          p {
            margin: 0;
            margin-bottom: 5px;

            &.sub {
              font-family: 'Roboto-Regular';
              font-size: $font12;
              color: $text-primary;
              line-height: 16px;
              letter-spacing: 0.4px;
              margin-bottom: 10px;

              @include fontsized() {
                font-size: f(font12);
              }

              @include themed() {
                color: t(textPrimary);
              }
            }

            &.title {
              font-family: 'Roboto Condensed Bold';
              font-size: $font36;
              color: $text-primary;
              letter-spacing: -0.3px;

              @include fontsized() {
                font-size: f(font36);
              }

              @include themed() {
                color: t(textPrimary);
              }
            }
          }
        }

      }
    }

    .content-carousel-staff {
      ul.staff-slideshow {
        li {
          width: 100%;
        }
      }

      .footer-carousel {
        p.copy-foot {
          font-family: 'Roboto-Regular';
          font-size: $font16;
          color: $text-secondary;
          line-height: 28px;
          letter-spacing: 0.5px;

          @include fontsized() {
            font-size: f(font16);
          }

          @include themed() {
            color: t(textSecondary);
          }
        }
      }
    }
  }


  .content-carousel {
    padding: 20px 10px;
    background: $palette-primary-500;

    .title-carousel {
      padding: 30px 0;

      p {
        &.title {
          font-family: 'Roboto Condensed Regular';
          font-size: $font24;
          color: $text-primary;
          line-height: 35px;
          letter-spacing: -0.2px;

          @include fontsized() {
            font-size: f(font24);
          }
        }
      }

      .filter-btn {
        button {
          margin-right: 10px;

          &:hover {
            box-shadow: $shadow-3;
            background: $bg-dashboard;
          }
        }
      }
    }

    .cards-carousel {
      ul {
        padding: 0;
        margin: 0;

        li {
          width: 100%;
        }
      }

      .card_custom {
        @media #{$large-and-up} {
          width: 100%;
          margin: 0 auto;
        }

        .footer-card {
          p {
            margin: 0;
            padding: 6px 20px;

            &.tag {
              border-radius: 20px;
              border: 1px solid $divider-color;
              font-family: 'Roboto-Regular';
              font-size: $font14;
              line-height: 20px;
              letter-spacing: 0.25px;

              @include fontsized() {
                font-size: f(font14);
              }
            }
          }

          .info-time {
            margin-left: auto;
            display: flex;
            align-items: center;
            flex-direction: row;

            span {
              padding: 0;
              line-height: 0;
              color: $text-primary;

              @include themed() {
                color: t(textPrimary);
              }
            }

            p {
              margin: 0;
              padding-left: 5px;
              font-family: 'Roboto-Regular';
              font-size: $font12;
              line-height: 16px;
              letter-spacing: 2px;
              margin: 0;

              @include fontsized() {
                font-size: f(font12);
              }

              &.tag {
                border-radius: 20px;
                border: 1px solid $divider-color;
                font-family: 'Roboto-Regular';
                font-size: $font14;
                line-height: 20px;
                letter-spacing: 0.25px;

                @include fontsized() {
                  font-size: f(font14);
                }
              }
            }

            //width: 100%;
          }
        }
      }

      .footer-carousel {
        padding: 20px 5px 10px 5px;
        display: flex;
        align-items: center;

        .content-link_notice {
          margin-left: auto;

          a {
            text-decoration: none;
            display: flex;
            align-items: center;

            span {
              &.text {
                color: $text-primary;
                font-family: 'Roboto Condensed Regular';
                font-size: $font14;
                line-height: 16px;
                letter-spacing: 1.25px;

                @include fontsized() {
                  font-size: f(font14);
                }
              }

              &.icon {
                line-height: 0;
                padding-left: 10px;
                color: $text-primary;
                opacity: 0.9;
              }
            }
          }
        }
      }

      .custom-number {
        //padding-top: 40px;

        a {
          font-family: 'Roboto Condensed Regular';
          font-size: $font24;
          letter-spacing: -0.2px;
          color: $text-secondary-white;
          padding: 0 10px;
          text-decoration: none;

          @include fontsized() {
            font-size: f(font24);
          }

          &.cycle-pager-active {
            color: $text-primary;
          }
        }
      }
    }
  }
}
