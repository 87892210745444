.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  margin-bottom: 10px;
  list-style: none;
  background-color: transparent;

  &.white {
    //background-color: chocolate;

    .breadcrumb-item {
      &::before {
        position: relative;
        top: 2px;
        font-family: 'Material Icons';
        display: inline-block; // Suppress underlining of the separator in modern browsers
        padding-right: 5px;
        color: #fff;
        content: '\e315';

        @include themed() {
          color: #fff;
        }
      }

      &.active {
        color: #fff;
        font-weight: bold;

        @include themed() {
          color: #fff;
        }
      }
    }
  }
}

.breadcrumb-item {
  &::before {
    position: relative;
    top: 2px;
    font-family: 'Material Icons';
    display: inline-block; // Suppress underlining of the separator in modern browsers
    padding-right: 5px;
    color: $text-secondary;
    content: '\e315';

    @include themed() {
      color: t(textSecondary);
    }
  }

  // The separator between breadcrumbs (by default, a forward-slash: "/")
  +.breadcrumb-item {
    padding-left: 5px;

    &::before {
      font-family: 'Material Icons';
      display: inline-block; // Suppress underlining of the separator in modern browsers
      padding-right: 5px;
      color: $text-secondary;
      content: '\e315';

      @include themed() {
        color: t(textSecondary);
      }
    }


  }

  // IE9-11 hack to properly handle hyperlink underlines for breadcrumbs built
  // without `<ul>`s. The `::before` pseudo-element generates an element
  // *within* the .breadcrumb-item and thereby inherits the `text-decoration`.
  //
  // To trick IE into suppressing the underline, we give the pseudo-element an
  // underline and then immediately remove it.
  +.breadcrumb-item:hover::before {
    text-decoration: underline;
  }

  // stylelint-disable-next-line no-duplicate-selectors
  +.breadcrumb-item:hover::before {
    text-decoration: none;
  }

  &.active {
    color: $text-secondary;
    font-weight: bold;

    @include themed() {
      color: t(textSecondary);
    }
  }
}