.bg__connect {

  //background: url('../images/logo/connets_bg.svg') no-repeat !important;
  //background-position: center center !important;
  //background-size: cover !important;

  &.overlay10 {
    background: transparent !important;
    position: relative;
    overflow: hidden;

    &::before {
      z-index: -1;
      position: absolute;
      left: 0;
      top: 0;
      content: url('../images/logo/connets_bg.svg');
      opacity: 0.1;

    }
  }

  &.overlay50 {
    background: #212121 !important;
    position: relative;
    overflow: hidden;

    &::before {
      //z-index: -1;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      content: url('../images/logo/connets_bg.svg');
      opacity: 0.8;

    }
  }

  &.overlay80 {
    background: #212121 !important;
    position: relative;
    overflow: hidden;

    &::before {
      //z-index: -1;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      content: url('../images/logo/connets_bg.svg');
      opacity: 0.8;

    }
  }

  &.overlay90 {
    background: transparent;
    position: relative;
    overflow: hidden;

    @include themed() {
      //color: $text-primary;
      background: t(bgdash-body);
    }

    &::before {
      z-index: -1;
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: url('../images/logo/connets_bg.svg') no-repeat !important;
      background-position: center center !important;
      background-size: cover !important;
      width: 100%;
      height: 100%;
      content: "";
      opacity: 0.1;
      //transform: scale(.6);
      //opacity: 0.8;

      // @media #{$large-up} {
      //   transform: scale(1);
      // }

      // @media #{$tablet-only} {
      //   left: -60px;
      //   transform: scale(.8);
      // }

      @include themed() {
        //color: $text-primary;
        opacity: 0.1;
      }

    }
  }

  &.overlay15 {
    background: transparent !important;
    position: relative;
    overflow: hidden;

    &::before {
      z-index: -1;
      display: flex;
      position: absolute;
      left: 0;
      top: -178px;
      right: 0;
      bottom: 0;
      background: url('../images/logo/unisalle_bg.svg') no-repeat !important;
      background-position: center top !important;
      background-size: contain !important;
      width: 100%;
      height: 100%;
      content: "";
      transform: scale(.69);

      @media #{$large-up} {
        transform: scale(.69);
        top: 0;
      }

      @media #{$tablet-only} {
        top: -110px;
      }

      @include themed() {
        //color: $text-primary;
        opacity: 0.1;
      }

    }
  }
}

.bg__help {

  //background: url('../images/logo/connets_bg.svg') no-repeat !important;
  //background-position: center center !important;
  //background-size: cover !important;

  &.overlay90 {
    background: transparent !important;
    position: relative;
    overflow: hidden;

    &::before {
      z-index: -1;
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: url('../images/logo/help_bg.svg') no-repeat !important;
      background-position: center center !important;
      background-size: cover !important;
      width: 100%;
      height: 100%;
      content: "";
      opacity: 0.4;
      //transform: scale(.6);
      //opacity: 0.8;

      // @media #{$large-up} {
      //   transform: scale(1);
      // }

      // @media #{$tablet-only} {
      //   left: -60px;
      //   transform: scale(.8);
      // }

      @include themed() {
        //color: $text-primary;
        opacity: 0.1;
      }

    }
  }
}

.bg__start {

  //background: url('../images/logo/connets_bg.svg') no-repeat !important;
  //background-position: center center !important;
  //background-size: cover !important;

  &.overlay90 {
    background: transparent !important;
    position: relative;
    overflow: hidden;

    &::before {
      //z-index: 1;
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: url('../images/logo/stars_bg.svg') no-repeat !important;
      background-position: center center !important;
      background-size: cover !important;
      width: 100%;
      height: 100%;
      content: "";
      opacity: 0.2;
      //transform: scale(.6);
      //opacity: 0.8;

      // @media #{$large-up} {
      //   transform: scale(1);
      // }

      // @media #{$tablet-only} {
      //   left: -60px;
      //   transform: scale(.8);
      // }

      @include themed() {
        //color: $text-primary;
        opacity: 0.2;
      }

    }
  }
}


.bg__utopia {

  //background: url('../images/logo/connets_bg.svg') no-repeat !important;
  //background-position: center center !important;
  //background-size: cover !important;

  &.overlay10 {
    background: transparent !important;
    position: relative;
    overflow: hidden;

    &::before {
      z-index: -1;
      position: absolute;
      left: 0;
      top: 0;
      content: url('../images/logo/utopia_bg.svg');
      opacity: 0.1;

    }
  }

  &.overlay50 {
    background: transparent !important;
    position: relative;
    overflow: hidden;

    &::before {
      z-index: -1;
      position: absolute;
      right: 0;
      top: 0;
      content: url('../images/logo/utopia_bg.svg');
      opacity: 0.5;

      @include themed() {
        //color: $text-primary;
        opacity: 0.04;
      }
    }
  }
}


.bg__shop {

  //background: url('../images/logo/connets_bg.svg') no-repeat !important;
  //background-position: center center !important;
  //background-size: cover !important;

  &.overlay10 {
    background: transparent !important;
    position: relative;

    &::before {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      content: url('../images/logo/tienda_text.svg');
      opacity: 0.1;

    }
  }

  &.overlay50 {
    background: transparent !important;
    position: relative;

    &::before {
      position: absolute;
      right: 50px;
      left: 0;
      top: 80px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      content: url('../images/logo/tienda_text.svg');
      opacity: 0.5;

      @media #{$large-up} {
        top: -50px;
      }

      @media #{$tablet-only} {
        //bottom: 0;
        top: 60px;
      }

    }
  }
}

.bg__unisalle {

  //background: url('../images/logo/connets_bg.svg') no-repeat !important;
  //background-position: center center !important;
  //background-size: cover !important;
  &.overlay20 {
    background: none;
    //overflow: hidden;
    position: relative;

    &::before {
      //z-index: 1;
      position: absolute;
      left: -161px;
      top: -93px;
      content: url('../images/logo/unisalle_bg.svg');
      transform: scale(.5);
      opacity: 0.3;

      // @media #{$large-up} {
      //   transform: scale(1);
      // }

      // @media #{$tablet-only} {
      //   left: -60px;
      //   transform: scale(.8);
      // }

      @include themed() {
        //color: $text-primary;
        opacity: 0.04;
      }

    }
  }

  &.overlay10 {
    background: transparent !important;
    position: relative;

    &::before {
      //z-index: 1;
      position: absolute;
      left: 0;
      bottom: 0;
      content: url('../images/logo/unisalle_bg.svg');
      transform: scale(.6);
      opacity: 0.8;

      @media #{$large-up} {
        transform: scale(1);
      }

      @media #{$tablet-only} {
        left: -60px;
        transform: scale(.8);
      }

      @include themed() {
        //color: $text-primary;
        opacity: 0.04;
      }

    }
  }

  &.overlay50 {
    background: transparent !important;
    position: relative;

    &::before {
      position: absolute;
      right: 50px;
      left: 0;
      top: -50px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      content: url('../images/logo/unisalle_bg.svg');
      opacity: 0.5;

    }
  }
}

.bg__vive {

  //background: url('../images/logo/connets_bg.svg') no-repeat !important;
  //background-position: center center !important;
  //background-size: cover !important;

  &.overlay11 {
    background: rgba(156, 39, 176, 0.7) !important;
    position: relative;
    overflow: hidden;

    &::before {
      z-index: -1;
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: url('../images/pics/7102625167_02e875a40d_o.jpg') no-repeat !important;
      background-position: center center !important;
      background-size: cover !important;
      width: 100%;
      height: 100%;
      content: "";

      @include themed() {
        //color: $text-primary;
        opacity: 0.1;
      }

    }
  }

  &.overlay50 {
    background: transparent !important;
    position: relative;

    &::before {
      position: absolute;
      right: 50px;
      left: 0;
      top: -50px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      content: url('../images/logo/unisalle_bg.svg');
      opacity: 0.5;

    }
  }
}

.bg__quality {

  //background: url('../images/logo/connets_bg.svg') no-repeat !important;
  //background-position: center center !important;
  //background-size: cover !important;

  &.overlay10 {
    background: transparent !important;
    position: relative;
    overflow: hidden;

    &::before {
      z-index: -1;
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: url('../images/pics/1.jpg') no-repeat !important;
      background-position: center center !important;
      background-size: cover !important;
      width: 100%;
      height: 100%;
      content: "";
      opacity: 0.1;
      //transform: scale(.6);
      //opacity: 0.8;

      // @media #{$large-up} {
      //   transform: scale(1);
      // }

      // @media #{$tablet-only} {
      //   left: -60px;
      //   transform: scale(.8);
      // }

      @include themed() {
        //color: $text-primary;
        opacity: 0.1;
      }

    }
  }

  &.overlay50 {
    background: transparent !important;
    position: relative;

    &::before {
      position: absolute;
      right: 50px;
      left: 0;
      top: -50px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      content: url('../images/logo/unisalle_bg.svg');
      opacity: 0.5;

    }
  }
}
