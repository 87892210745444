@import './slide';

.card_custom {
  background: $bg-secondary;
  box-shadow: $shadow-2;
  padding: 15px 10px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;

  @include themed() {
    background: t(bgCard);
  }

  .head-card {
    box-sizing: border-box;

    img {
      width: 100%;
      max-width: 100%;
    }
  }

  .info-card {
    display: flex;
    margin-bottom: 10px;

    .copy {
      padding-top: 20px;

      p {
        margin: 0;
        margin-bottom: 5px;

        &.title {
          font-family: 'Roboto Condensed Bold';
          font-size: $font20;
          color: $text-primary;

          @include fontsized() {
            font-size: f(font20);
          }

          @include themed() {
            color: t(textPrimary);
          }
        }

        &.sub {
          font-family: 'Roboto-Regular';
          color: $text-primary;
          font-size: $font14;
          line-height: 20px;
          letter-spacing: 0.25px;

          @include fontsized() {
            font-size: f(font14);
          }

          @include themed() {
            color: t(textSecondary);
          }
        }
      }
    }

    .icon-shared {
      padding-top: 35px;

      a {
        color: $text-link-color;
      }
    }
  }

  .body-card {
    .date-now {
      display: flex;
      align-items: center;


      span {

        color: $text-primary;
        padding-right: 5px;

        @include themed() {
          color: t(textPrimary);
        }
      }

      p {

        font-family: 'Roboto-Regular';
        color: $text-secondary;
        font-size: $font14;
        line-height: 20px;
        letter-spacing: 0.25px;
        margin: 0;

        @include fontsized() {
          font-size: f(font14);
        }

        @include themed() {
          color: t(textSecondary);
        }
      }
    }

    .grid-date {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .coldata {
        padding-top: 10px;
        display: flex;
        width: 50%;

        .copy {
          display: flex;
          align-items: center;

          span {
            color: $text-primary;
            line-height: 0;

            @include themed() {
              color: t(textPrimary);
            }
          }

          p {
            padding-left: 5px;
            font-family: 'Roboto-Regular';
            color: $text-primary;
            line-height: 16px;
            letter-spacing: 0.4px;
            margin: 0;

            @include themed() {
              color: t(textPrimary);
            }

            &.location {
              font-family: 'Roboto Condensed Bold';
              font-size: $font16;
              line-height: 24px;
              letter-spacing: 0.15px;
              color: $text-secondary;

              @include fontsized() {
                font-size: f(font16);
              }

              @include themed() {
                color: t(textPrimary);
              }
            }
          }
        }
      }
    }
  }

  .footer-card {
    padding: 20px 0px 5px 0px;
    display: flex;
    align-items: center;

    p {
      color: $text-primary;
      margin: 0;
      font-family: 'Roboto-Regular';
      font-size: $font12;
      line-height: 16px;
      letter-spacing: 0.4px;

      @include fontsized() {
        font-size: f(font12);
      }

      @include themed() {
        color: t(textPrimary);
      }
    }

    button {
      margin-left: auto;
    }
  }
}

.card_custom_calendar {
  background: $bg-secondary;
  box-shadow: $shadow-2;
  padding: 15px 10px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;

  .head-card {
    box-sizing: border-box;

    img {
      width: 100%;
      max-width: 100%;
    }
  }

  .info-card {
    display: flex;
    margin-bottom: 10px;

    .copy {
      padding-top: 20px;

      p {
        margin: 0;
        margin-bottom: 5px;

        &.title {
          font-family: 'Roboto Condensed Bold';
          font-size: $font20;
          color: $text-primary;

          @include fontsized() {
            font-size: f(font20);
          }
        }

        &.sub {
          font-family: 'Roboto-Regular';
          color: $text-primary;
          font-size: $font14;
          line-height: 20px;
          letter-spacing: 0.25px;

          @include fontsized() {
            font-size: f(font14);
          }
        }
      }
    }

    .icon-shared {
      padding-top: 35px;

      a {
        color: $text-link-color;
      }
    }
  }

  .body-card {
    .date-now {
      display: flex;
      align-items: center;


      span {
        padding-right: 5px;
      }

      p {

        font-family: 'Roboto-Regular';
        color: $text-secondary;
        font-size: $font14;
        line-height: 20px;
        letter-spacing: 0.25px;
        margin: 0;

        @include fontsized() {
          font-size: f(font14);
        }
      }
    }

    .grid-date {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .coldata {
        padding-top: 10px;
        display: flex;
        width: 50%;

        .copy {
          display: flex;
          align-items: center;

          p {
            padding-left: 5px;
            font-family: 'Roboto-Regular';
            color: $text-primary;
            line-height: 16px;
            letter-spacing: 0.4px;
            margin: 0;

            &.location {
              font-family: 'Roboto Condensed Bold';
              font-size: $font16;
              line-height: 24px;
              letter-spacing: 0.15px;
              color: $text-secondary;

              @include fontsized() {
                font-size: f(font16);
              }
            }
          }
        }
      }
    }
  }

  .footer-card {
    padding: 20px 0px 5px 0px;
    display: flex;
    align-items: center;

    p {
      margin: 0;
      font-family: 'Roboto-Regular';
      font-size: $font12;
      line-height: 16px;
      letter-spacing: 0.4px;

      @include fontsized() {
        font-size: f(font12);
      }
    }

    button {
      margin-left: auto;
    }
  }
}

.card_info {
  //display: flex;
  display: block;
  width: 100%;
  background: $palette-aceent-500;
  position: relative;
  min-height: 240px;
  box-sizing: border-box;
  background-size: cover;
  background-position: center center;

  &.shadow__2 {
    box-shadow: $shadow-2;
  }

  &.shadow__3 {
    box-shadow: $shadow-3;
  }

  &.slide {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 340px;
    box-shadow: $shadow-3;
    //align-items: center;
  }

  .cover {
    position: absolute;
    width: 100%;
    height: 100%;
    //background: $palette-aceent-500;
    background: adjust-color($palette-aceent-500, $lightness: 0%, $alpha: -0.25);
  }

  .card_head {
    z-index: 2;
    padding: 20px 20px 10px 20px;
    display: flex;
    position: relative;
    flex-direction: row-reverse;

    button.mdl-button.white_custom {
      color: $text-secondary-white;
    }
  }

  .card_body {
    position: relative;
    z-index: 1;
    padding: 0 20px;

    .copy {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &.slide {
        //padding-top: 60px;
        justify-content: center;
        flex-direction: row;

        .number {
          font-family: 'OpenSans-Bold';
          font-size: $font90;
          margin: 0;
          position: relative;
          top: 22px;
          padding-right: 5px;
          color: rgba(255, 255, 255, 0.38);

          @include fontsized() {
            font-size: f(font90);
          }

          @media #{$small-and-down} {
            display: none;
          }

          @media #{$large-and-up} {
            display: none;
          }
        }
      }

      img.text-img {
        margin-bottom: 10px;
      }

      p.text-slide {
        color: $text-white;
        font-family: 'Roboto Condensed Regular';
        font-size: $font36;
        line-height: 1;
        letter-spacing: -0.3px;

        @include fontsized() {
          font-size: f(font36);
        }
      }

      p.text-slide-sub {
        color: $text-primary-white;
        font-family: 'Roboto-Regular';
        font-size: $font14;
        line-height: 20px;
        letter-spacing: 0.25px;

        @include fontsized() {
          font-size: f(font14);
        }

        @media #{$medium-and-up} {
          font-size: $font16;
          line-height: 28px;

          @include fontsized() {
            font-size: f(font16);
          }
        }
      }
    }

    // .copy-img {
    //   //position: absolute;

    //   img {}
    // }
  }

  .card_footer {
    display: flex;
    position: relative;
    padding: 20px 20px 10px 20px;
    z-index: 2;

    &.slide {
      position: absolute;
      bottom: 0;
      padding: 0;
      width: 100%;

      a {
        padding: 20px;

        span.text {
          color: $text-primary;
        }

        span.ico-arrow {
          line-height: 0;
          color: $text-primary;
        }
      }

      .custom-indicator {
        padding: 20px;
        text-align: center;
        //width: 100%;
        z-index: 500;
        position: relative;
        display: flex;
        flex-direction: row;
        //top: 10px;
        overflow: hidden;

        span {
          font-family: arial;
          font-size: $font50;
          width: 15px;
          height: 15px;
          display: inline-block;
          color: #6a1b9a;
          cursor: pointer;
          margin-right: 3px;

          @include fontsized() {
            font-size: f(font50);
          }

          &.cycle-pager-active {
            color: #4a148c;
          }
        }
      }

      .custom-indicator>* {
        cursor: pointer;
      }
    }


    a {
      display: flex;
      align-items: center;
      margin-left: auto;
      text-decoration: none;

      span.ico-arrow {
        padding-left: 10px;
        color: $text-white;
        line-height: 0;
      }

      span.text {
        font-family: 'Roboto Condensed Regular';
        font-size: $font14;
        color: $text-primary-white;
        line-height: 16px;
        letter-spacing: 1.25px;
        text-align: right;

        @include fontsized() {
          font-size: f(font14);
        }
      }
    }
  }
}

.card_note {
  background: $bg-secondary;
  box-shadow: $shadow-2;
  padding: 15px 10px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  margin-bottom: 30px;
}

.card_horizontal {
  display: flex;
  flex-direction: row;
  box-shadow: $shadow-1;
  background: $bg-secondary;

  @include themed() {
    background: t(bgCard);
  }

  .card-head {
    width: 130px;
    height: 100%;

    img {
      max-width: 100%;
      height: 145px;
      object-fit: cover;
    }
  }

  .card-body {
    .copy {
      padding: 20px;

      p.title {
        font-family: 'Roboto-Regular';
        color: $text-primary;
        font-size: $font24;

        @include fontsized() {
          font-size: f(font24);
        }

        @include themed() {
          color: t(textPrimary);
        }
      }

      p.sub {
        font-family: 'Roboto-Regular';
        color: $text-primary;
        font-size: $font14;
        letter-spacing: 0.25px;

        @include fontsized() {
          font-size: f(font14);
        }

        @include themed() {
          color: t(textSecondary);
        }
      }
    }
  }
}
