.getmdl-select {
  outline: none;

  .mdl-textfield__input {
    cursor: pointer;
  }

  .selected {
    background-color: #ddd;
  }

  .mdl-icon-toggle__label {
    float: right;
    margin-top: -30px;
    color: rgba(0, 0, 0, 0.4);
    transform: rotate(0);
    transition: transform 0.3s;
  }

  &.is-focused {
    .mdl-icon-toggle__label {
      color: #3f51b5;
      transform: rotate(180deg);
    }
  }

  .mdl-menu__container {
    width: 100% !important;
    margin-top: 2px;

    .mdl-menu {
      width: 100%;

      .mdl-menu__item {
        font-size: $font16;

        @include fontsized() {
          font-size: f(font16);
        }
      }
    }
  }
}

.getmdl-select__fix-height {
  .mdl-menu__container .mdl-menu {
    overflow-y: auto;
    max-height: 288px !important;
  }

  .mdl-menu.mdl-menu--top-left {
    bottom: auto;
    top: 0;
  }
}
