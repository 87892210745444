a.btn_link {
  display: flex;
  align-items: center;
  background: $palette-primary-500;
  text-decoration: none;
  min-height: 70px;
  padding: 10px 20px;

  .name_btn_link {
    p {

      margin: 0;

      &.title_link {
        font-size: $font16;
        font-family: 'Roboto Condensed Bold';
        color: $text-primary;
        letter-spacing: 0.15px;
        line-height: 24px;

        @include fontsized() {
          font-size: f(font16);
        }
      }

      &.sub_link {
        font-family: 'Roboto-Regular';
        font-size: $font14;
        letter-spacing: 0.25px;
        line-height: 20px;
        color: $text-secondary;

        @include fontsized() {
          font-size: f(font14);
        }
      }
    }
  }

  span.arrow {
    margin-left: auto;
    font-size: $font24;
    //font-weight: bold;
    color: $text-secondary;

    @include fontsized() {
      font-size: f(font24);
    }
  }
}


a.mdl-button,
button.mdl-button {
  font-family: 'Roboto Condensed Regular';
  font-size: $font14;
  color: $text-primary;
  line-height: 16px;
  align-items: center;
  letter-spacing: 1.25px;
  min-width: 120px;
  padding: 0 16px;
  height: 40px;

  @include fontsized() {
    font-size: f(font14);
  }

  &.center__mdl {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.green__mdl {
    width: 160px;
    background: #8bc34a;
    color: $text-white;
  }

  &.yellow__mdl {
    width: 160px;
    background: $palette-primary-500;
    color: $text-primary;
  }

  &.section__btn {
    height: 50px;
  }

  &.custom-round {
    border: 1px solid $divider-btn;
    border-radius: 20px;
  }

  &.download {
    min-width: 42px;
    height: 42px;
    width: 42px;
    display: flex;
    justify-content: center;

    //align-items: center;
    &:hover {
      background: transparent;
    }

    i {
      transform: rotate(180deg);
    }
  }

  &.custom {
    height: 50px;
    min-width: 140px;

    &.border__btn {
      border: 1px solid $divider-color;
    }

    &.full_weight {
      width: 100%;

    }

    &.blue {
      background: $palette-secondary-500;
    }
  }
}

// boton custom material 
.btmdl-custom {
  &.tone50 {
    .mdl-ripple {
      background: $palette-primary-50;
    }
  }

  &.tone100 {
    .mdl-ripple {
      background: $palette-primary-100;
    }
  }

  &.tone500 {
    .mdl-ripple {
      background: $palette-primary-500;
    }
  }
}

span.tag__custom {
  color: $text-secondary;
  display: flex;
  min-width: 120px;
  width: 120px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 30px;

  @include themed() {
    color: t(textPrimary);
    border: 1px solid t(textPrimary);
  }
}

a.mdl-button {
  font-family: 'Roboto-Regular';

}
