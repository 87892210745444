/* pager */
.custom-indicator {

  .cycle-pager {
    text-align: center;
    width: 100%;
    z-index: 500;
    position: absolute;
    top: 10px;
    overflow: hidden;
  }

  .cycle-pager span {
    font-family: arial;
    font-size: $font50;
    width: 16px;
    height: 16px;
    display: inline-block;
    color: #ddd;
    cursor: pointer;

    @include fontsized() {
      font-size: f(font50);
    }
  }

  .cycle-pager span.cycle-pager-active {
    color: #D69746;
  }

  .cycle-pager>* {
    cursor: pointer;
  }
}

.custom-indicator {

  .cycle-pager {
    text-align: center;
    width: 100%;
    z-index: 500;
    position: absolute;
    top: 10px;
    overflow: hidden;
  }

  .cycle-pager span {
    font-family: arial;
    font-size: $font50;
    width: 16px;
    height: 16px;
    display: inline-block;
    color: #ddd;
    cursor: pointer;

    @include fontsized() {
      font-size: f(font50);
    }
  }

  .cycle-pager span.cycle-pager-active {
    color: #D69746;
  }

  .cycle-pager>* {
    cursor: pointer;
  }
}


.content__slide__events {
  position: relative;

  ul {
    width: 100%;
    margin: 0;
    padding: 0;

    li {
      width: 100%;
    }
  }

  .item__slide {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: calc(900px - 90px);

    .bg__black {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba(18, 18, 18, 0.8);
    }

    .item__img {
      //position: absolute;
      width: 100%;
      height: 100%;

      img {
        max-width: 100%;
      }
    }

    .copy {
      padding: 0 160px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      //width: 500px;

      h1 {
        padding: 0;
        margin: 0;
        color: $palette-primary-500;
        font-family: 'OpenSans-Bold';
        font-size: $font36;
        line-height: 42px;
        letter-spacing: 0.58px;
        margin-bottom: 20px;

        @include fontsized() {
          font-size: f(font36);
        }

        @media #{$large-up} {
          font-size: $font70;
          line-height: 92px;
          letter-spacing: -1.13px;

          @include fontsized() {
            font-size: f(font70);
          }
        }

        @media #{$tablet-only} {
          font-size: $font48;
          line-height: 62px;
          letter-spacing: -0.77px;

          @include fontsized() {
            font-size: f(font48);
          }
        }
      }

      .cont__date {
        display: flex;
        //flex-direction: column;
        flex-direction: row;
        width: 100%;

        @media #{$tablet-only} {}

        .item__date {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 10px;

          @media #{$tablet-only} {
            justify-content: center;
          }

          @media #{$phone-down} {
            margin-left: inherit;
            justify-content: flex-start;
            width: 100%;
          }

          span {
            display: block;
            line-height: 0;
            margin: 0;
            color: $text-secondary-dark;

            @include themed() {
              color: t(textPrimary);
            }

            &.date-num {
              font-family: 'Roboto-Regular';
              color: $text-secondary-dark;
              line-height: 16px;
              letter-spacing: 2px;
              font-size: $font12;
              margin-left: 10px;

              @include fontsized() {
                font-size: f(font12);
              }

              @include themed() {
                color: t(textSecondary);
              }
            }
          }
        }
      }
    }

    .item__footer {
      position: absolute;
      bottom: 20px;
      left: 70px;
      display: flex;
      flex-direction: row;
      //justify-content: space-between;
      width: 100%;

      .date__num {
        //position: absolute;
        // bottom: 73px;
        // left: 73px;

        .box__date {
          position: relative;
          height: 120px;
          width: auto;
          padding: 10px;
          border: 1px solid $palette-primary-500;
          background: $bg-dashboard-dark;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            display: block;
            line-height: auto;
            color: $palette-primary-500;
            font-family: 'OpenSans-Bold';
            font-size: $font120;
            letter-spacing: -1.94px;

            @include fontsized() {
              font-size: f(font120);
            }
          }
        }
      }


    }
  }

  .slide__arrow {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    flex-direction: row;

    a {
      position: relative;
      color: $text-primary;
      text-decoration: none;
      background: $palette-primary-400;

      &:hover {
        background: $palette-primary-500;
      }

      // &.prev {
      //   background: $palette-primary-300;
      // }

      // &.next {
      //   background: $palette-primary-400;
      // }

      .box__arrow {
        height: 120px;
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;

      }
    }
  }

  .slide__action {
    position: absolute;
    bottom: 60px;
    right: 310px;
    z-index: 999;

    .custom-number {
      position: relative;
      z-index: 99;
      display: flex;
      flex-direction: row;
      //position: absolute;
      //padding-top: 40px;

      a {
        font-family: 'Roboto Condensed Regular';
        font-size: $font24;
        letter-spacing: -0.5px;
        line-height: 0;
        color: $text-secondary-white;
        padding: 0 10px;
        text-decoration: none;

        @include fontsized() {
          font-size: f(font24);
        }

        &.cycle-pager-active {
          font-size: $font60;
          color: $text-primary-dark;

          @include fontsized() {
            font-size: f(font60);
          }
        }
      }
    }

  }
}


.content__slide__home {
  position: relative;
  display: flex;
  flex-direction: column;

  //min-height: 620px;


  ul {
    width: 100%;
    margin: 0;
    padding: 0;

    li {
      width: 100%;
    }
  }

  .item__slide {
    position: relative;
    display: flex;
    align-items: center;
    //justify-content: center;
    flex-direction: column;
    width: 100%;
    height: calc(580px - 90px);

    @media #{$tablet-only} {
      align-items: flex-start;
    }

    @media #{$large-up} {
      height: calc(100vh - 90px);
      flex-direction: row;
    }

    .item__img {
      //position: absolute;
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;

      @media #{$large-up} {
        width: 50%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-width: 100%;
      }
    }

    .copy {
      padding: 40px 20px 10px 20px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      @media #{$large-up} {
        width: 50%;
      }

      @media #{$tablet-only} {
        width: 80%;
      }

      //width: 500px;

      h1 {
        padding: 0;
        margin: 0;
        color: $palette-primary-500;
        font-family: 'OpenSans-Bold';
        font-size: $font48;
        line-height: 49px;
        letter-spacing: -0.77px;
        margin-bottom: 20px;

        @include fontsized() {
          font-size: f(font48);
        }

        @include themed() {
          color: t(textPrimary);
        }

        @media #{$large-up} {
          font-size: $font70;
          line-height: 1.1;
          letter-spacing: -1.13px;

          @include fontsized() {
            font-size: f(font70);
          }
        }

        @media #{$tablet-only} {
          font-size: $font48;
          line-height: 62px;
          letter-spacing: -0.77px;

          @include fontsized() {
            font-size: f(font48);
          }
        }
      }

      p.sub {
        font-family: 'Roboto-Regular';
        font-size: $font14;
        line-height: 28px;
        letter-spacing: 0.44px;
        color: $text-primary;

        @include fontsized() {
          font-size: f(font14);
        }

        @include themed() {
          color: t(textSecondary);
        }
      }

      button.mdl-button {
        height: 70px;
        min-width: 240px;
        box-shadow: $shadow-3;
        background: $text-primary-black;
        color: $palette-primary-500;

        @include themed() {
          color: $text-primary;
          background: $palette-primary-500;
        }
      }
    }

    .item__footer {
      position: absolute;
      bottom: 20px;
      left: 70px;
      display: flex;
      flex-direction: row;
      //justify-content: space-between;
      width: 100%;

      .date__num {
        //position: absolute;
        // bottom: 73px;
        // left: 73px;

        .box__date {
          position: relative;
          height: 120px;
          width: auto;
          padding: 10px;
          border: 1px solid $palette-primary-500;
          background: $bg-dashboard-dark;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            display: block;
            line-height: auto;
            color: $palette-primary-500;
            font-family: 'OpenSans-Bold';
            font-size: $font120;
            letter-spacing: -1.94px;

            @include fontsized() {
              font-size: f(font120);
            }

          }
        }
      }


    }
  }

  .slide__arrow {
    position: absolute;
    //ight: calc(29% - 11px);

    bottom: 0;
    z-index: 999;
    display: flex;
    flex-direction: row;

    @media #{$large-up} {
      width: 34%;
      //right: calc(29% - 58px);
      left: 0;
    }

    a {
      flex-basis: 50%;
      position: relative;
      color: $palette-primary-500;
      text-decoration: none;
      background: $palette-text-500;

      @media #{$large-up} {
        //flex-basis: 35%;
      }

      &:hover {
        background: $palette-text-400;
      }

      // &.prev {
      //   background: $palette-primary-300;
      // }

      // &.next {
      //   background: $palette-primary-400;
      // }

      .box__arrow {
        height: 120px;
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media #{$large-up} {
          width: initial;
        }

      }
    }
  }

  .slide__preview {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 999;
    width: 66%;

    .box__preview {
      position: relative;
      display: flex;
      background: #fff;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      box-shadow: $shadow-1;
      padding: 20px;
      margin-left: auto;
      //width: calc(100% - 25px);
      min-height: 200px;
      //max-width: 300px;

      @media #{$tablet-only} {
        width: inherit;
      }

      @include themed() {
        background: t(bgCard);
      }

      .copy {
        p {
          font-family: 'Roboto-Regular';
          font-size: $font36;
          color: $text-primary;
          line-height: 42px;

          @include fontsized() {
            font-size: f(font36);
          }

          @media #{$tablet-only} {
            width: 80%;
          }

          @media #{$large-up} {
            width: 100%;
            font-size: $font48;
            color: $text-primary;
            line-height: 49px;

            @include fontsized() {
              font-size: f(font48);
            }
          }

          @include themed() {
            color: t(textPrimary);
          }

          &.sub {
            font-size: $font12;
            color: $text-secondary;
            line-height: 16px;
            letter-spacing: 0.4px;

            @include fontsized() {
              font-size: f(font12);
            }

            @include themed() {
              color: t(textPrimary);
            }
          }
        }

      }

      .icon__option {
        position: absolute;
        right: 10px;
        top: 10px;

        a.mdl-button {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $palette-primary-500;
          height: 48px;
          width: 26px;
          min-width: 26px;
          min-height: 32px;

          &:hover {
            background: none;
          }
        }
      }
    }
  }

  .slide__action {
    position: absolute;
    //position: relative;
    z-index: 999;
    bottom: 40px;
    margin: 0 auto;
    width: 100%;

    @media #{$large-up} {
      display: flex;
      width: initial;
      padding-left: 20px;
    }

    @media #{$tablet-only} {
      display: flex;
      padding-left: 20px;
    }

    .custom-number {
      position: relative;
      z-index: 99;
      display: flex;
      flex-direction: row;
      justify-content: center;
      //position: absolute;
      //padding-top: 40px;

      a {
        font-family: 'Roboto Condensed Regular';
        font-size: $font16;
        letter-spacing: -0.13px;
        line-height: 0;
        color: $text-secondary;
        padding: 0 10px;
        text-decoration: none;

        @include fontsized() {
          font-size: f(font16);
        }

        @media #{$large-up} {
          font-size: $font24;

          @include fontsized() {
            font-size: f(font24);
          }
        }

        &.cycle-pager-active {
          font-size: $font36;
          color: $text-primary;

          @include fontsized() {
            font-size: f(font36);
          }

          @media #{$large-up} {
            font-size: $font60;

            @include fontsized() {
              font-size: f(font60);
            }
          }

          @include themed() {
            color: t(textPrimary);
          }
        }

        @include themed() {
          color: t(textSecondary);
        }
      }
    }

  }
}

.content__slide__shop {
  position: relative;
  display: flex;
  flex-direction: column;
  //min-height: 620px;

  ul {
    width: 100%;
    margin: 0;
    padding: 0;

    li {
      width: 100%;
    }
  }

  .item__slide {
    position: relative;
    display: flex;
    align-items: center;
    //justify-content: center;
    flex-direction: column;
    width: 100%;
    height: calc(580px - 90px);
    background: rgb(13, 71, 161);
    background: linear-gradient(0deg, rgba(13, 71, 161, 1) 0%, rgba(3, 169, 244, 1) 100%);

    @media #{$large-up} {
      //flex-direction: row;
      align-items: center;
      //width: 100%;
      justify-content: center;
    }

    .copy {
      padding: 40px 20px 10px 20px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      //width: 500px;
      @media #{$large-up} {
        flex-direction: row;
        align-items: center;
        width: inherit;
        padding: 0;
        justify-content: center;
        margin-bottom: 20px;
      }

      span.tag {
        padding: 5px;
        font-family: 'OpenSans-Bold';
        background: $bg-secondary;
        font-size: $font16;

        @include fontsized() {
          font-size: f(font16);
        }
      }

      .content__logo {
        position: relative;
        z-index: 1;
        margin-bottom: 20px;

        @media #{$large-up} {
          flex-basis: 50%;
          max-width: 50%;
          padding-left: 30px;
        }

        img {
          width: 140px;
          max-width: 100%;

          @media #{$tablet-only} {
            width: 100px;
          }

          @media #{$large-up} {
            width: 180px;
          }

        }
      }

      h1 {
        padding: 0;
        margin: 0;
        color: $palette-primary-500;
        font-family: 'OpenSans-Bold';
        font-size: $font48;
        line-height: 49px;
        letter-spacing: -0.77px;
        margin-bottom: 20px;

        @include fontsized() {
          font-size: f(font48);
        }

        @media #{$large-up} {
          font-size: $font70;
          line-height: 92px;
          letter-spacing: -1.13px;

          @include fontsized() {
            font-size: f(font70);
          }
        }

        @media #{$tablet-only} {
          font-size: $font48;
          line-height: 62px;
          letter-spacing: -0.77px;

          @include fontsized() {
            font-size: f(font48);
          }
        }
      }

      .content__item__title {
        @media #{$large-up} {
          display: flex;
          justify-content: flex-end;
          flex-basis: 50%;
          max-width: 50%;
        }
      }

      p.title {
        width: 20%;
        font-family: 'Roboto Condensed Bold';
        color: $text-secondary-dark;
        font-size: $font24;
        line-height: normal;
        letter-spacing: -0.2px;

        @include fontsized() {
          font-size: f(font24);
        }

        @media #{$large-up} {
          width: 240px;

        }

      }

      p.sub {
        font-family: 'Roboto-Regular';
        font-size: $font14;
        line-height: 28px;
        letter-spacing: 0.44px;
        color: $text-primary;

        @include fontsized() {
          font-size: f(font14);
        }
      }

      button.mdl-button {
        height: 70px;
        min-width: 240px;
        box-shadow: $shadow-3;
        background: $text-primary-black;
        color: $palette-primary-500;
      }
    }

    .content__btn {
      @media #{$tablet-only} {
        padding-top: 50px;
      }

      @media #{$large-up} {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-left: auto;
      }

      button.mdl-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 50px;
        min-width: 240px;
        border-radius: 30px;
        box-shadow: $shadow-3;
        background: $bg-secondary;
        color: $palette-secondary-500;

        @media #{$large-up} {
          position: relative;
          right: 20px;
        }

        span.icon {
          margin-left: 10px;
          line-height: 0;
        }
      }
    }

    .item__footer {
      position: absolute;
      bottom: 20px;
      left: 70px;
      display: flex;
      flex-direction: row;
      //justify-content: space-between;
      width: 100%;

      .date__num {
        //position: absolute;
        // bottom: 73px;
        // left: 73px;

        .box__date {
          position: relative;
          height: 120px;
          width: auto;
          padding: 10px;
          border: 1px solid $palette-primary-500;
          background: $bg-dashboard-dark;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            display: block;
            line-height: auto;
            color: $palette-primary-500;
            font-family: 'OpenSans-Bold';
            font-size: $font120;
            letter-spacing: -1.94px;

            @include fontsized() {
              font-size: f(font120);
            }

          }
        }
      }


    }
  }

  .slide__arrow {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    flex-direction: row;

    a {
      position: relative;
      color: $text-primary;
      text-decoration: none;
      background: $palette-secondary-400;

      &:hover {
        background: $palette-secondary-500;
      }

      // &.prev {
      //   background: $palette-primary-300;
      // }

      // &.next {
      //   background: $palette-primary-400;
      // }

      .box__arrow {
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

      }
    }
  }

  .slide__action {
    position: absolute;
    //position: relative;
    z-index: 999;
    bottom: 20px;
    margin: 0 auto;
    width: 100%;

    .custom__point {
      position: relative;
      z-index: 99;
      display: flex;
      flex-direction: row;
      justify-content: center;

      //position: absolute;
      //padding-top: 40px;
      span {
        color: #1976d2;
        font-size: $font66;
        margin-right: 5px;

        @include fontsized() {
          font-size: f(font66);
        }

        &.cycle-pager-active {
          color: #2196f3;
        }
      }

      a {
        font-family: 'Roboto Condensed Regular';
        font-size: $font16;
        letter-spacing: -0.13px;
        line-height: 0;
        color: #2196f3;
        padding: 0 10px;
        text-decoration: none;

        @include fontsized() {
          font-size: f(font16);
        }

        &.cycle-pager-active {
          font-size: $font36;
          color: #1976d2;

          @include fontsized() {
            font-size: f(font36);
          }
        }
      }
    }

  }
}
