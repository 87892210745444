.list__docs {
  p.title__list {
    font-size: $font12;
    line-height: 16px;
    color: $text-secondary;

    @include themed() {
      color: t(textSecondary);
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      .list__item {
        position: relative;
        z-index: 1;
        padding: 15px 20px;
        background: $bg-dashboard;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
        transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
          background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
          color 0.2s cubic-bezier(0.4, 0, 0.2, 1);

        @include themed() {
          background: t(bgdash-body);
        }

        span {
          &.item__title {
            font-family: 'Roboto Condensed Bold';
            font-size: $font20;
            color: $text-secondary;
            letter-spacing: 0.25px;
            transition: color 0.2s cubic-bezier(0.4, 0, 0.2, 1);

            @include fontsized() {
              font-size: f(font20);
            }

            @include themed() {
              color: t(textPrimary);
            }
          }

          &.item__tag {
            font-size: $font14;
            line-height: 20px;
            letter-spacing: 0.25px;
            font-family: 'Roboto-Regular';
            color: $text-primary;
            border: 1px solid $divider-btn;
            border-radius: 30px;
            padding: 5px 10px;

            @include fontsized() {
              font-size: f(font14);
            }

            @include themed() {
              border: 1px solid #fff;
              color: t(textPrimary);
            }
          }
        }

        &:hover {
          background: $bg-secondary;
          box-shadow: $shadow-2;

          @include themed() {
            box-shadow: $shadow-0;
            background: t(bgDashLink);
          }

          span {
            &.item__title {
              color: $palette-primary-500;
            }

            &.item__tag {
              font-size: $font14;
              line-height: 20px;
              letter-spacing: 0.25px;
              font-family: 'Roboto-Regular';
              color: $text-primary;
              border: 1px solid $divider-btn;
              border-radius: 30px;
              padding: 5px 10px;

              @include fontsized() {
                font-size: f(font14);
              }

              @include themed() {
                border: 1px solid #fff;
                color: t(textPrimary);
              }
            }
          }
        }

        .item__left {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          //justify-content: space-between;
          width: 50%;
          margin-left: auto;

          a.download,
          button.download {
            margin-left: 20px;
            width: 20px;
            min-width: 20px;

            @include themed() {
              color: t(textSecondary);
            }
          }
        }
      }
    }
  }
}

.cont_spaker {
  min-height: 390px;

  @media #{$large-up} {

    width: 360px;
    margin: 0 auto;
  }

  @media #{$tablet-up} {
    min-height: auto;
  }

  .box__speaker {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: $shadow-3;
    background: #fff;

    &.nop_row {
      flex-direction: column !important;
    }

    @media #{$tablet-up} {
      position: relative;
      flex-direction: row;
      margin-bottom: 30px;
    }

    @include themed() {
      background: t(bgCard);
    }

    .item__left {
      flex-basis: 50%;
    }

    .item__right {
      flex-basis: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      .speaker__name {
        @media #{$tablet-up} {
          border-bottom: none !important;
        }
      }

      .icon__shared {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }

    .item_namedata {
      display: flex;
      flex-direction: row;
      align-items: center;

      @media #{$tablet-up} {
        align-items: flex-start;
      }

      .copy__name {
        @media #{$tablet-up} {
          flex-basis: 50% !important;
          margin-bottom: 15px;
        }
      }
    }

    .speaker__btn {
      padding: 10px 20px;
      margin: 0 auto;
      text-align: center;

      button.mdl-button {
        //margin: 0 auto;
        height: 70px;
        min-width: 280px;

        &.primary {
          background: $palette-primary-500;
        }
      }
    }

    .speaker__shared,
    .speaker__hour,
    .speaker__location,
    .speaker__name {
      padding: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      border-bottom: 1px solid $palette-primary-100;

      @include themed() {
        border-bottom: 1px solid rgba(255, 255, 255, 0.21);
      }

      &.active {
        background: $palette-primary-50;

        @include themed() {
          background: t(bgdash-head);
        }
      }

      // flex columna automatica
      .item {
        flex: 1 1 0px;
      }

      .icon__shared,
      .icon__hour,
      .icon__location,
      .shared__calendar,
      .icon_name {
        display: flex;
        align-items: center;
        justify-content: center;

        @include themed() {
          color: t(textPrimary);
        }

        button {
          min-width: 60px;

          @include themed() {
            color: t(textPrimary);
          }
        }
      }

      .cont_img {
        margin: 0 auto;

        img {
          border-radius: 50%;
          width: 50px;
          height: 50px;
          max-width: 100%;
          object-fit: cover;
        }
      }

      .copy__hour {
        flex-basis: 67%;

        p {
          margin-bottom: 5px;
          font-family: 'Roboto-Regular';

          &.title_hour {
            font-size: $font12;
            color: $text-secondary;
            letter-spacing: 0.4px;
            line-height: 16px;

            @include fontsized() {
              font-size: f(font12);
            }

            @include themed() {
              color: t(textSecondary);
            }
          }

          &.sub_hour {
            font-family: 'Roboto Condensed Bold';
            font-size: $font16;
            line-height: 24px;
            letter-spacing: 0.15px;

            @include fontsized() {
              font-size: f(font16);
            }

            @include themed() {
              color: t(textPrimary);
            }
          }
        }
      }

      .copy__location {
        flex-basis: 67%;

        p {
          font-family: 'Roboto-Regular';
          margin-bottom: 5px;

          &.title_location {
            font-size: $font12;
            color: $text-secondary;
            line-height: 16px;
            letter-spacing: 0.4px;

            @include fontsized() {
              font-size: f(font12);
            }

            @include themed() {
              color: t(textSecondary);
            }
          }

          &.sub_location {
            font-family: 'Roboto Condensed Bold';
            font-size: $font16;
            line-height: 24px;
            letter-spacing: 0.15px;

            @include fontsized() {
              font-size: f(font16);
            }

            @include themed() {
              color: t(textSecondary);
            }
          }
        }
      }

      .copy__name {
        flex-basis: 67%;

        p {
          font-family: 'Roboto-Regular';
          margin-bottom: 5px;

          &.title_name {
            font-size: $font12;
            color: $text-secondary;
            line-height: 16px;
            letter-spacing: 0.4px;

            @include fontsized() {
              font-size: f(font12);
            }

            @include themed() {
              color: t(textSecondary);
            }

            &.name_bold {
              font-family: 'Roboto Condensed Bold';
              font-size: $font16;
              color: $text-secondary;
              line-height: 24px;
              letter-spacing: 0.15px;

              @include fontsized() {
                font-size: f(font16);
              }

              @include themed() {
                color: t(textSecondary);
              }
            }
          }

          &.sub_name {
            font-family: 'Roboto Condensed Bold';
            font-size: $font16;
            line-height: 24px;
            color: $text-primary;
            letter-spacing: 0.15px;

            @include fontsized() {
              font-size: f(font16);
            }

            @include themed() {
              color: t(textPrimary);
            }
          }
        }
      }

      .copy__calendar {
        flex-basis: 50%;

        p {
          font-family: 'Roboto-Regular';
          margin-bottom: 5px;

          &.title_calendar {
            font-size: $font12;
            color: $text-secondary;
            line-height: 16px;
            letter-spacing: 0.4px;

            @include fontsized() {
              font-size: f(font12);
            }

            @include themed() {
              color: t(textSecondary);
            }
          }

          &.sub_calendar {
            font-family: 'Roboto Condensed Bold';
            font-size: $font16;
            line-height: 24px;
            letter-spacing: 0.15px;

            @include fontsized() {
              font-size: f(font16);
            }

            @include themed() {
              color: t(textPrimary);
            }
          }
        }
      }
    }

    .speaker__name {
      @media #{$tablet-up} {
        align-items: initial;
        flex-direction: column;
      }
    }
  }
}

.cont_evaluation {
  @media #{$large-up} {
    display: flex;
    flex-direction: row;
  }

  .eva__column {

    @media #{$large-up} {
      flex-basis: 50%;
      max-width: 50%;
      flex-direction: column;
    }

    .content_card__eval {
      .cont__eva-btn {
        @media #{$large-up} {
          margin: 0 auto;
          text-align: right;
        }

        button.mdl-button {
          i {
            color: $palette-secondary-500;
          }
        }
      }
    }

    .content_card__eval .card__warp {
      margin-bottom: 10px;
    }

  }

  .item__eval {
    @media #{$large-up} {
      flex: 1 1 0px;
    }

    &.mdl6 {
      @media #{$large-up} {
        flex-basis: 60% !important;
      }
    }
  }

  .eval__date {
    display: flex;
    flex-direction: row;

    .icon__date {
      padding-right: 10px;

      @include themed() {
        color: $palette-primary-500;
      }
    }

    p {
      &.title__date {
        flex-basis: 80%;

        @include themed() {
          color: t(textPrimary);
        }
      }
    }

  }

  .eval_copy {
    p {
      font-family: 'Roboto-Regular';

      &.title_eval {
        color: $text-primary;
        font-size: $font34;
        line-height: 35px;
        letter-spacing: 0.25px;

        @include fontsized() {
          font-size: f(font34);
        }

        @include themed() {
          color: t(textPrimary);
        }
      }

      &.sub_eval {
        font-size: $font14;
        color: $text-secondary;
        letter-spacing: 0.25px;

        @include fontsized() {
          font-size: f(font14);
        }

        @include themed() {
          color: t(textSecondary);
        }
      }
    }
  }

  .eval__location {
    p {
      display: flex;
      margin: 0;

      @include themed() {
        color: t(textPrimary);
      }

      &.t_location {
        color: $text-secondary;

        @include themed() {
          color: t(textSecondary);
        }
      }

      span {
        color: $text-primary;

        @include themed() {
          color: t(textPrimary);
        }

        &.icon_hour {
          padding-right: 5px;

          @include themed() {
            color: t(textPrimary);
          }
        }
      }
    }
  }

  .tag_eval {
    padding: 10px 0 40px;

    button.mdl-button {
      border: 1px solid $palette-primary-700;
      background: $palette-primary-50;
      border-radius: 30px;
      max-width: 180px;
      text-transform: none;

      @include themed() {
        color: t(textPrimary);
        background: t(bgCard);
      }
    }
  }

  .content_card__eval {
    p.title {
      font-family: 'Roboto-Regular';
      font-size: $font12;
      color: $text-primary;
      line-height: 16px;
      letter-spacing: 0.4px;

      @include fontsized() {
        font-size: f(font12);
      }

      @include themed() {
        color: t(textPrimary);
      }
    }

    .card__warp {
      display: flex;
      flex-direction: row;
      -webkit-overflow-scrolling: touch;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      width: 100%;
      padding: 20px 0;
      margin-bottom: 50px;

      .item__card {
        position: relative;
        width: 100%;
        box-shadow: $shadow-1;
        max-width: 240px;
        min-height: 240px;
        flex: 0 0 auto;
        justify-content: center;
        padding: 10px;
        margin-left: 10px;
        margin-right: 30px;
        background: $bg-secondary;

        @media #{$large-up} {
          max-width: 190px;
          min-height: 300px;
          margin-left: 10px;
          margin-right: 10px;

        }

        &:hover {
          box-shadow: $shadow-3;
        }

        @include themed() {
          background: t(bgCard);
        }


        .item__head {
          display: flex;
          flex-direction: column;
          //align-items: center;
          //justify-content: center;
          margin-bottom: 10px;
          padding: 0 20px;

          p {
            margin: 0;
            text-align: left;

            &.title {
              font-family: 'OpenSans-Bold';
              font-size: $font36;
              color: $text-primary;
              margin-bottom: 5px;
              line-height: normal;

              @include fontsized() {
                font-size: f(font36);
              }

              @include themed() {
                color: t(textPrimary);
              }
            }

            &.subtitle {
              font-family: 'Roboto-Regular';
              font-size: $font12;
              line-height: 16px;
              letter-spacing: 0.4px;
              color: $text-secondary;
              margin-bottom: 10px;

              @include fontsized() {
                font-size: f(font12);
              }

              @include themed() {
                color: t(textSecondary);
              }
            }
          }
        }

        .item__body {

          padding: 0 20px;

          .item__info {
            p {
              font-family: 'Roboto Condensed Bold';
              font-size: $font20;
              color: $text-secondary;
              letter-spacing: 0.25px;

              @include fontsized() {
                font-size: f(font20);
              }

              @include themed() {
                color: t(textSecondary);
              }
            }
          }

          .item__shared {
            position: absolute;
            bottom: 10px;
            right: 10px;

            button.mdl-button {
              min-width: 68px;

              @include themed() {
                color: t(textSecondary);
              }

              &:focus {
                color: $palette-secondary-500;
              }
            }
          }
        }

        .item__footer {
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 20px 0;
          display: flex;
          //justify-content: space-between;
          align-items: center;
          width: 100%;

          .item__info {
            padding-left: 20px;
            width: 100%;
            max-width: 170px;

            p {
              font-family: 'Roboto-Regular';
              font-size: $font14;
              color: rgba(0, 0, 0, 0.38);
              margin: 0;

              @include fontsized() {
                font-size: f(font14);
              }

              @include themed() {
                color: t(textSecondary);
              }

              &.number {
                font-size: $font12;
                letter-spacing: 0.4px;
                color: $text-secondary;

                @include fontsized() {
                  font-size: f(font12);
                }

                @include themed() {
                  color: t(textPrimary);
                }
              }
            }
          }

          .item__icon {
            padding-right: 20px;
            width: 100%;
            max-width: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            span {
              color: $text-secondary;

              @include themed() {
                color: t(textSecondary);
              }
            }

          }

        }
      }
    }
  }
}

.typelist {
  .mdl-accordion {
    margin-bottom: 20px;
    box-shadow: $shadow-1;

    @media #{$tablet-up} {
      box-shadow: none;
    }




    //min-height: 60px;
    &.mdl-accordion--opened {
      box-shadow: $shadow-2;
      background: #fff;
      border: none;

      @include themed() {
        background: t(bgCard);
      }

      .mdl-accordion__button {
        color: $text-primary;
      }

      .mdl-accordion__icon {
        transform: rotate(90deg);
      }
    }

    a.mdl-navigation__link {
      padding: 20px 10px;
      font-family: "Roboto Condensed Bold";
      display: block;
      color: $text-primary;
      //min-height: 60px;
      background: #fafafa;
      position: relative;

      @include themed() {
        background: t(bgCard);
      }

      span.name {
        display: block;
        padding-right: 30px;

        @include themed() {
          color: t(textPrimary);
        }

        @media #{$tablet-up} {
          padding-right: 30px;
        }
      }
    }

    .mdl-accordion__icon {
      top: 38%;
      color: $text-secondary;

      @include themed() {
        color: t(textSecondary);
      }
    }

    .mdl-accordion__content-wrapper {

      .toogle__mdl {
        padding: 10px;

        p.subtitle {
          font-family: "Roboto-Regular";
          margin: 0;
          color: $text-secondary;

          @include themed() {
            color: t(textSecondary);
          }

        }
      }
    }
  }

}
