.typelist {
  // p.title__list {
  //   font-size: $font12;
  //   line-height: 16px;
  //   color: $text-secondary;

  //   @include themed() {
  //     color: t(textSecondary);
  //   }
  // }
  &.events {
    ul li .list__item {
      background: none;

      &:hover {
        box-shadow: none;
      }

      .item__center {
        .cont__date {
          @media #{$phone-down} {
            flex-direction: column;
          }
        }

        .item__date {
          justify-content: flex-start;

          @media #{$phone-down} {
            margin-bottom: 10px;
          }

          span {
            display: block;
            line-height: 0;
            margin: 0;
            font-family: 'Roboto-Regular';
            letter-spacing: 2px;
            font-size: $font12;
            color: $text-link-color;

            @include fontsized() {
              font-size: f(font12);
            }

            @include themed() {
              color: t(textPrimary);
            }

            &.date-num {
              font-family: 'Roboto-Regular';
              color: $text-primary;
              line-height: 16px;
              letter-spacing: 0.4px;
              font-size: $font12;
              margin-left: 10px;

              @include fontsized() {
                font-size: f(font12);
              }

              @include themed() {
                color: t(textSecondary);
              }
            }
          }
        }
      }
    }

    ul li .list__item {
      @include themed() {
        border: none;
        background: t(bgdash-body);
      }
    }

    ul li .list__item.active {
      span.item__title {
        color: $palette-primary-500;
      }
    }

    span.item__title {
      font-family: 'Roboto-Regular';
      font-size: $font34;
      color: $text-primary;
      line-height: 42px;
      letter-spacing: 0.25px;

      @include fontsized() {
        font-size: f(font34);
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      .list__item {
        position: relative;
        z-index: 1;
        padding: 15px 20px;
        background: $bg-dashboard;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        transition: box-shadow .2s cubic-bezier(.4, 0, 1, 1),
          background-color .2s cubic-bezier(.4, 0, .2, 1),
          color .2s cubic-bezier(.4, 0, .2, 1);

        &.list__row {
          flex-direction: row;

          @media #{$tablet-only} {
            justify-content: space-between;
          }

          @media #{$large-up} {
            //align-items: center;
            justify-content: space-between;
          }
        }



        @include themed() {
          border: 1px solid t(borderLink);
          background: t(bgdash-body);
        }

        .col2-mdl {
          flex-basis: 20%;
          max-width: 20%;

          @media #{$tablet-only} {
            flex-basis: 60px;
            max-width: 60px;
          }

          @media #{$large-up} {
            flex-basis: 60px;
            max-width: 60px;
          }
        }

        .col6-mdl {
          flex-basis: 80%;
          max-width: 85%;
        }

        .cont__btn-mail {
          margin-left: auto;

          a.mdl-button {
            min-width: 20px;

            @include themed() {
              color: t(textSecondary);
            }

          }
        }

        span {
          @include themed() {
            color: t(textSecondary);
          }

          &.item__title {
            display: block;
            font-family: 'Roboto Condensed Bold';
            font-size: $font20;
            color: $text-secondary;
            letter-spacing: 0.25px;
            transition: color .2s cubic-bezier(.4, 0, .2, 1);

            @include fontsized() {
              font-size: f(font20);
            }

            @include themed() {
              color: t(textPrimary);
            }
          }

          &.item__tag {
            font-size: $font14;
            line-height: 20px;
            letter-spacing: 0.25px;
            font-family: 'Roboto-Regular';
            color: $text-primary;
            border: 1px solid $divider-btn;
            border-radius: 30px;
            padding: 5px 10px;

            @include fontsized() {
              font-size: f(font14);
            }

            @include themed() {
              border: 1px solid #fff;
              color: t(textPrimary);
            }

          }
        }

        &:hover {
          background: $bg-secondary;
          box-shadow: $shadow-2;

          @include themed() {
            box-shadow: $shadow-0;
            background: t(bgDashLink);
          }

          span {
            &.item__title {
              color: $palette-primary-500;
            }

            &.item__tag {
              font-size: $font14;
              line-height: 20px;
              letter-spacing: 0.25px;
              font-family: 'Roboto-Regular';
              color: $text-primary;
              border: 1px solid $divider-btn;
              border-radius: 30px;
              padding: 5px 10px;

              @include fontsized() {
                font-size: f(font14);
              }


              @include themed() {
                border: 1px solid #fff;
                color: t(textPrimary);
              }

            }
          }
        }

        .item__flex {
          .cont__date {
            padding: 0 20px;
          }
        }

        .item__center {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          padding-top: 15px;

          @media #{$phone-down} {
            padding-top: 15px;
            flex-direction: column;
            align-items: flex-start;
          }

          //justify-content: flex-end;
          //justify-content: space-between;
          //margin-left: auto;
          .cont__date {
            //padding-top: 20px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          .item__date {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            @media #{$phone-down} {
              margin-left: inherit;
              justify-content: flex-start;
              width: 100%;
            }

            span {
              display: block;
              line-height: 0;
              margin: 0;
              color: $text-link-color;

              @include themed() {
                color: t(textPrimary);
              }

              &.date-num {
                font-family: 'Roboto-Regular';
                color: $text-primary;
                line-height: 16px;
                letter-spacing: 2px;
                font-size: $font12;
                margin-left: 10px;

                @include fontsized() {
                  font-size: f(font12);
                }

                @include themed() {
                  color: t(textSecondary);
                }
              }
            }
          }

          button.download {
            margin-left: 20px;

            @include themed() {
              color: t(textSecondary);
            }

          }
        }
      }

    }
  }
}
