/*! nouislider - 8.5.1 - 2016-04-24 16:00:30 */


.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.noUi-target {
  position: relative;
  direction: ltr
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1
}

.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0
}

.noUi-handle {
  position: relative;
  z-index: 1
}

.noUi-stacking .noUi-handle {
  z-index: 10
}

.noUi-state-tap .noUi-origin {
  -webkit-transition: left .3s, top .3s;
  transition: left .3s, top .3s
}

.noUi-state-drag * {
  cursor: inherit !important
}

.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.noUi-horizontal {
  height: 18px
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  left: -17px;
  top: -6px
}

.noUi-vertical {
  width: 18px
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px
}

.noUi-background {
  background: #FAFAFA;
  box-shadow: inset 0 1px 1px #f0f0f0
}

.noUi-connect {
  background: #3FB8AF;
  box-shadow: inset 0 0 3px rgba(51, 51, 51, .45);
  -webkit-transition: background 450ms;
  transition: background 450ms
}

.noUi-origin {
  border-radius: 2px
}

.noUi-target {
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB
}

.noUi-target.noUi-connect {
  box-shadow: inset 0 0 3px rgba(51, 51, 51, .45), 0 3px 6px -5px #BBB
}

.noUi-draggable {
  cursor: w-resize
}

.noUi-vertical .noUi-draggable {
  cursor: n-resize
}

.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB
}

.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB
}

.noUi-handle:after,
.noUi-handle:before {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px
}

.noUi-handle:after {
  left: 17px
}

.noUi-vertical .noUi-handle:after,
.noUi-vertical .noUi-handle:before {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px
}

.noUi-vertical .noUi-handle:after {
  top: 17px
}

[disabled] .noUi-connect,
[disabled].noUi-connect {
  background: #B8B8B8
}

[disabled] .noUi-handle,
[disabled].noUi-origin {
  cursor: not-allowed
}

.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.noUi-pips {
  position: absolute;
  color: #999
}

.noUi-value {
  position: absolute;
  text-align: center
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px
}

.noUi-marker {
  position: absolute;
  background: #CCC
}

.noUi-marker-large,
.noUi-marker-sub {
  background: #AAA
}

.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%
}

.noUi-value-horizontal {
  -webkit-transform: translate3d(-50%, 50%, 0);
  transform: translate3d(-50%, 50%, 0)
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px
}

.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%
}

.noUi-value-vertical {
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
  padding-left: 25px
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  padding: 5px;
  text-align: center
}

.noUi-horizontal .noUi-handle-lower .noUi-tooltip {
  top: -32px
}

.noUi-horizontal .noUi-handle-upper .noUi-tooltip {
  bottom: -32px
}

.noUi-vertical .noUi-handle-lower .noUi-tooltip {
  left: 120%
}

.noUi-vertical .noUi-handle-upper .noUi-tooltip {
  right: 120%
}

/*!
 * Propeller v1.2.0 (http://propeller.in)
 * Copyright 2016-2019 Digicorp, Inc.
 * Licensed under MIT (http://propeller.in/LICENSE)
 */
.pmd-range-slider.noUi-target {
  box-shadow: none;
  border: none;
  height: 2px;
  margin: 40px 0 6px;
}

.pmd-range-slider.noUi-connect {
  background: #03a9f4;
}

.pmd-range-slider .noUi-connect {
  background: #03a9f4;
}

.pmd-range-slider .noUi-handle {
  border: none;
  box-shadow: none;
  border-radius: 50%;
  background: none;
  width: 14px;
  height: 14px;
  left: -7px;
  top: -6px;
  cursor: pointer;
}

.pmd-range-slider .noUi-handle:after,
.pmd-range-slider .noUi-handle:before {
  display: none;
}

.pmd-range-slider .noUi-handle:before {
  display: block;
  width: 100%;
  height: 100%;
  background: #03a9f4;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
}

.pmd-range-slider.noUi-background,
.pmd-range-slider .noUi-background {
  box-shadow: none;
  background: #dedede;
}

.pmd-range-slider.noUi-background {
  transition: all 0.2s ease-in-out;
}

.pmd-range-slider .noUi-base {
  z-index: 100;
}

.pmd-range-slider .noUi-pips-horizontal {
  height: 2px;
  padding: 0;
  top: 1px;
  z-index: 10;
}

.pmd-range-slider .noUi-pips .noUi-value {
  display: none;
}

.pmd-range-slider .noUi-pips .noUi-marker-horizontal {
  background: #000000;
  height: 2px;
}

.pmd-range-slider .noUi-pips.noUi-pips-horizontal .noUi-marker-large:first-child {
  margin-left: 0;
}

.pmd-range-slider .noUi-pips.noUi-pips-horizontal .noUi-marker-large:nth-last-child(2) {
  margin-left: -2px;
}

.pmd-range-slider .noUi-pips-vertical {
  width: 2px;
  padding: 0;
  left: 1px;
  z-index: 10;
}

.pmd-range-slider .noUi-pips .noUi-marker-vertical {
  background: #000000;
  width: 2px;
}

.pmd-range-slider .noUi-pips.noUi-pips-vertical .noUi-marker-large:first-child {
  margin-top: -2px;
}

.pmd-range-slider .noUi-pips.noUi-pips-vertical .noUi-marker-large:nth-last-child(2) {
  margin-top: 0px;
}

.pmd-range-slider .noUi-tooltip {
  left: 50%;
  padding: 0;
  width: 28px;
  height: 28px;
  margin-left: -14px;
  border: none;
  background: #4285f4;
  color: #ffffff;
  line-height: 28px;
  border-radius: 50%;
  font-size: 11px;
}

.pmd-range-slider.noUi-horizontal .noUi-handle-upper .noUi-tooltip,
.pmd-range-slider.noUi-horizontal .noUi-handle-lower .noUi-tooltip {
  top: -32px;
  bottom: auto;
}

.pmd-range-slider.noUi-horizontal .noUi-handle-upper .noUi-tooltip:before,
.pmd-range-slider.noUi-horizontal .noUi-handle-lower .noUi-tooltip:before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  left: 50%;
  background: #03a9f4;
  margin-left: -10px;
  z-index: -1;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 9px;
  border-radius: 10px 10px 0px 10px;
}

.pmd-range-slider.pmd-range-tooltip-right-bottom.noUi-horizontal .noUi-handle-upper .noUi-tooltip {
  top: auto;
  bottom: -32px;
}

.pmd-range-slider.pmd-range-tooltip-right-bottom.noUi-horizontal .noUi-handle-upper .noUi-tooltip:before {
  top: auto;
  bottom: 9px;
  border-radius: 0 10px 10px 10px;
}

.pmd-range-slider.pmd-range-tooltip-left-bottom.noUi-horizontal .noUi-handle-lower .noUi-tooltip {
  top: auto;
  bottom: -32px;
}

.pmd-range-slider.pmd-range-tooltip-left-bottom.noUi-horizontal .noUi-handle-lower .noUi-tooltip:before {
  top: auto;
  bottom: 9px;
  border-radius: 0 10px 10px 10px;
}

.pmd-range-slider.pmd-range-tooltip-bottom.noUi-horizontal .noUi-handle-upper .noUi-tooltip,
.pmd-range-slider.pmd-range-tooltip-bottom.noUi-horizontal .noUi-handle-lower .noUi-tooltip {
  top: auto;
  bottom: -30px;
  background: transparent;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto-Regular";
  font-size: 12px;
}

.pmd-range-slider.pmd-range-tooltip-bottom.noUi-horizontal .noUi-handle-upper .noUi-tooltip:before,
.pmd-range-slider.pmd-range-tooltip-bottom.noUi-horizontal .noUi-handle-lower .noUi-tooltip:before {
  top: auto;
  bottom: 27px;
  border-radius: 50%;
}

.pmd-range-slider.pmd-range-tooltip-bottom.noUi-horizontal .noUi-tooltip {
  top: auto;
  bottom: -32px;
}

.pmd-range-slider.pmd-range-tooltip-right-bottom.noUi-horizontal .noUi-handle-upper .noUi-tooltip,
.pmd-range-slider.pmd-range-tooltip-left-bottom.noUi-horizontal .noUi-handle-lower .noUi-tooltip,
.pmd-range-slider.pmd-range-tooltip-bottom.noUi-horizontal .noUi-tooltip {
  -moz-transform-origin: 50% -50%;
  -webkit-transform-origin: 50% -50%;
  transform-origin: 50% -50%;
  will-change: transform;
}

.pmd-range-slider.noUi-vertical {
  height: 300px;
  margin: 20px auto;
}

.pmd-range-slider.noUi-vertical.noUi-target {
  width: 2px;
}

.pmd-range-slider.noUi-vertical.noUi-connect {
  background: #4285f4;
}

.pmd-range-slider.noUi-vertical .noUi-handle {
  left: -6px;
}

.pmd-range-slider.noUi-vertical .noUi-tooltip {
  margin-left: 0;
  margin-top: -14px;
  top: 50%;
  left: -32px;
}

.pmd-range-slider.noUi-vertical .noUi-handle-upper .noUi-tooltip:before,
.pmd-range-slider.noUi-vertical .noUi-handle-lower .noUi-tooltip:before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  background: #4285f4;
  margin-top: -10px;
  z-index: -1;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 9px;
  border-radius: 10px 0px 10px 10px;
}

.pmd-range-slider.pmd-range-tooltip-top-right.noUi-vertical .noUi-handle-upper .noUi-tooltip {
  left: auto;
  right: -32px;
}

.pmd-range-slider.pmd-range-tooltip-top-right.noUi-vertical .noUi-handle-upper .noUi-tooltip:before {
  left: auto;
  right: 9px;
  border-radius: 10px 10px 10px 0px;
}

.pmd-range-slider.pmd-range-tooltip-bottom-right.noUi-vertical .noUi-handle-lower .noUi-tooltip {
  left: auto;
  right: -32px;
}

.pmd-range-slider.pmd-range-tooltip-bottom-right.noUi-vertical .noUi-handle-lower .noUi-tooltip:before {
  left: auto;
  right: 9px;
  border-radius: 10px 10px 10px 0px;
}

.pmd-range-slider.pmd-range-tooltip-right.noUi-vertical .noUi-handle-upper .noUi-tooltip,
.pmd-range-slider.pmd-range-tooltip-right.noUi-vertical .noUi-handle-lower .noUi-tooltip {
  left: auto;
  right: -32px;
}

.pmd-range-slider.pmd-range-tooltip-right.noUi-vertical .noUi-handle-upper .noUi-tooltip:before,
.pmd-range-slider.pmd-range-tooltip-right.noUi-vertical .noUi-handle-lower .noUi-tooltip:before {
  left: auto;
  right: 9px;
  border-radius: 10px 10px 10px 0px;
}

.pmd-range-slider.pmd-range-tooltip-right.noUi-vertical .noUi-tooltip {
  left: auto;
  right: -32px;
}

.pmd-range-slider.pmd-range-tooltip-top-right.noUi-vertical .noUi-handle-upper .noUi-tooltip,
.pmd-range-slider.pmd-range-tooltip-bottom-right.noUi-vertical .noUi-handle-lower .noUi-tooltip,
.pmd-range-slider.pmd-range-tooltip-right.noUi-vertical .noUi-tooltip {
  -moz-transform-origin: -50% 50%;
  -webkit-transform-origin: -50% 50%;
  transform-origin: -50% 50%;
  will-change: transform;
}

.pmd-range-slider .noUi-tooltip {
  -ms-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
}

.pmd-range-slider.noUi-horizontal .noUi-tooltip {
  -moz-transform-origin: 50% 150%;
  -webkit-transform-origin: 50% 150%;
  transform-origin: 50% 150%;
  will-change: transform;
}

.pmd-range-slider.noUi-vertical .noUi-tooltip {
  -moz-transform-origin: 150% 50%;
  -webkit-transform-origin: 150% 50%;
  transform-origin: 150% 50%;
  will-change: transform;
}

.pmd-range-slider .noUi-handle:before,
.pmd-range-slider .noUi-tooltip {
  transition: all 0.2s ease-in-out;
}

.pmd-range-slider .noUi-handle.noUi-active:before {
  opacity: 0;
  -ms-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
}

.pmd-range-slider .noUi-handle.noUi-active .noUi-tooltip {
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.pmd-range-slider.pmd-range-tooltip .noUi-handle:before {
  -ms-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
}

.pmd-range-slider.pmd-range-tooltip .noUi-tooltip {
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}

[disabled].noUi-connect {
  background: #d0d0d0;
}

[disabled].pmd-range-slider.noUi-background {
  background: #f1f1f1;
}

[disabled].pmd-range-slider .noUi-background {
  background: #f1f1f1;
}

[disabled].pmd-range-slider .noUi-handle:before {
  background: #d0d0d0;
}

.pmd-range-slider [disabled].noUi-origin .noUi-handle,
[disabled].pmd-range-slider .noUi-handle {
  cursor: not-allowed;
}

.pmd-range-slider [disabled].noUi-origin .noUi-handle:before {
  background: #d0d0d0;
}

/*# sourceMappingURL=range-slider.css.map */
