$bg-primary: #f4f8f9;
$bg-head: #fff;
$bg-secondary: #fff;
$bg-btn-secondary: rgb(245, 245, 245);
$bg-dashboard: #fafafa;
$text-primary: rgba(0, 0, 0, 0.87);
$text-secondary: rgba(0, 0, 0, 0.6);
$text-link-color: rgba(0, 0, 0, 0.54);
$text-primary-white: rgba(255, 255, 255, 0.87);
$text-secondary-white: rgba(255, 255, 255, 0.6);
$text-white: #fff;
$bg-card: #242424;
$bg-dash-link: #2c2c2c;
$border-link: #616161;
$divider-color: #BDBDBD;
$bg-hover: #333333;
$divider-btn: rgba(0, 0, 0, 0.12);
$social-color: #0a3356;
// dark 
$social-color-dark: #FFF;
$text-primary-black: rgba(0, 0, 0, 0.87);
$bg-head-dark: #1e1e1e;
$bg-card-dark: #242424;
$bg-dashboard-dark: #121212;
$bg-dash-link-dark: #2c2c2c;
$border-link-dark: #616161;
$text-primary-dark: #fff;
$bg-hover-dark: #333333;
$text-number: #424242;
$text-secondary-dark: rgba(255, 255, 255, 0.6);
// $primary-color-dark: #FFA000;
// $primary-color: #FFC107;
// $primary-color-light: #FFECB3;
// $primary-color-text: #212121;
// $accent-color: #448AFF;
// $secondary-text-color: #757575;
//$primary-text-color: #212121;

// fonts x
$font12: 12px;
$font14: 14px;
$font16: 16px; //--
$font20: 20px;
$font24: 24px;
$font34: 34px;
$font36: 36px;
$font48: 48px;
$font50: 50px;
$font60: 60px;
$font66: 66px;
$font70: 70px;
$font72: 72px;
$font90: 90px;
$font91: 91px;
$font92: 92px;
$font93: 93px;
$font120: 120px;

$zoom: 2px;
// zoom
$font12x: 12px+$zoom;
$font14x: 14px+$zoom;
$font16x: 16px+$zoom; //--
$font20x: 20px+$zoom;
$font24x: 24px+$zoom;
$font34x: 34px+$zoom;
$font36x: 36px+$zoom;
$font48x: 48px+$zoom;
$font50x: 50px+$zoom;
$font60x: 60px+$zoom;
$font66x: 66px+$zoom;
$font70x: 70px+$zoom;
$font72x: 72px+$zoom;
$font90x: 90px+$zoom;
$font91x: 91px+$zoom;
$font92x: 92px+$zoom;
$font93x: 93px+$zoom;
$font120x: 120px+$zoom;

//elevation shadows
$shadow-0: none !important;
$shadow-1: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
0 3px 1px -2px rgba(0, 0, 0, 0.12),
0 1px 5px 0 rgba(0, 0, 0, 0.2);
$shadow-2: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
0 1px 10px 0 rgba(0, 0, 0, 0.12),
0 2px 4px -1px rgba(0, 0, 0, 0.3);
$shadow-3: 0 8px 17px 2px rgba(0, 0, 0, 0.14),
0 3px 14px 2px rgba(0, 0, 0, 0.12),
0 5px 5px -3px rgba(0, 0, 0, 0.2);
$shadow-4: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
0 6px 30px 5px rgba(0, 0, 0, 0.12),
0 8px 10px -7px rgba(0, 0, 0, 0.2);
$shadow-5: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
0 9px 46px 8px rgba(0, 0, 0, 0.12),
0 11px 15px -7px rgba(0, 0, 0, 0.2);

// primario

$palette-primary: rgb(255, 248, 225) rgb(255, 236, 181) rgb(255, 224, 131) rgb(255, 212, 81) rgb(255, 202, 44) rgb(255, 193, 7) rgb(255, 187, 6) rgb(255, 179, 5) rgb(255, 171, 4) rgb(255, 158, 2) rgb(255, 255, 255) rgb(255, 250, 242) rgb(255, 228, 191) rgb(255, 217, 166);

$palette-primary-50: nth($palette-primary, 1);
$palette-primary-100: nth($palette-primary, 2);
$palette-primary-200: nth($palette-primary, 3);
$palette-primary-300: nth($palette-primary, 4);
$palette-primary-400: nth($palette-primary, 5);
$palette-primary-500: nth($palette-primary, 6);
$palette-primary-600: nth($palette-primary, 7);
$palette-primary-700: nth($palette-primary, 8);
$palette-primary-800: nth($palette-primary, 9);
$palette-primary-900: nth($palette-primary, 10);
$palette-primary-A100: nth($palette-primary, 11);
$palette-primary-A200: nth($palette-primary, 12);
$palette-primary-A400: nth($palette-primary, 13);
$palette-primary-A700: nth($palette-primary, 14);

// segundario
$palette-secondary: rgb(225, 245, 254) rgb(179, 229, 252) rgb(129, 212, 250) rgb(79, 195, 247) rgb(41, 182, 246) rgb(3, 169, 244) rgb(3, 162, 243) rgb(2, 152, 241) rgb(2, 143, 239) rgb(1, 126, 236) rgb(255, 255, 255) rgb(224, 239, 255) rgb(173, 212, 255) rgb(147, 199, 255);

$palette-secondary-50: nth($palette-secondary, 1);
$palette-secondary-100: nth($palette-secondary, 2);
$palette-secondary-200: nth($palette-secondary, 3);
$palette-secondary-300: nth($palette-secondary, 4);
$palette-secondary-400: nth($palette-secondary, 5);
$palette-secondary-500: nth($palette-secondary, 6);
$palette-secondary-600: nth($palette-secondary, 7);
$palette-secondary-700: nth($palette-secondary, 8);
$palette-secondary-800: nth($palette-secondary, 9);
$palette-secondary-900: nth($palette-secondary, 10);
$palette-secondary-A100: nth($palette-secondary, 11);
$palette-secondary-A200: nth($palette-secondary, 12);
$palette-secondary-A400: nth($palette-secondary, 13);
$palette-secondary-A700: nth($palette-secondary, 14);


// texto
$palette-text: rgb(224, 224, 224) rgb(179, 179, 179) rgb(128, 128, 128) rgb(77, 77, 77) rgb(38, 38, 38) rgb(0, 0, 0) rgb(0, 0, 0) rgb(0, 0, 0) rgb(0, 0, 0) rgb(0, 0, 0) rgb(166, 166, 166) rgb(140, 140, 140) rgb(115, 115, 115) rgb(102, 102, 102);

$palette-text-50: nth($palette-text, 1);
$palette-text-100: nth($palette-text, 2);
$palette-text-200: nth($palette-text, 3);
$palette-text-300: nth($palette-text, 4);
$palette-text-400: nth($palette-text, 5);
$palette-text-500: nth($palette-text, 6);
$palette-text-600: nth($palette-text, 7);
$palette-text-700: nth($palette-text, 8);
$palette-text-800: nth($palette-text, 9);
$palette-text-900: nth($palette-text, 10);
$palette-text-A100: nth($palette-text, 11);
$palette-text-A200: nth($palette-text, 12);
$palette-text-A400: nth($palette-text, 13);
$palette-text-A700: nth($palette-text, 14);

//morado
$palette-aceent: rgb(245, 224, 255) rgb(230, 179, 255) rgb(213, 128, 255) rgb(196, 77, 255) rgb(183, 38, 255) rgb(170, 0, 255) rgb(163, 0, 255) rgb(153, 0, 255) rgb(144, 0, 255) rgb(127, 0, 255) rgb(255, 255, 255) rgb(248, 242, 255) rgb(220, 191, 255) rgb(205, 166, 255);

$palette-aceent-50: nth($palette-aceent, 1);
$palette-aceent-100: nth($palette-aceent, 2);
$palette-aceent-200: nth($palette-aceent, 3);
$palette-aceent-300: nth($palette-aceent, 4);
$palette-aceent-400: nth($palette-aceent, 5);
$palette-aceent-500: nth($palette-aceent, 6);
$palette-aceent-600: nth($palette-aceent, 7);
$palette-aceent-700: nth($palette-aceent, 8);
$palette-aceent-800: nth($palette-aceent, 9);
$palette-aceent-900: nth($palette-aceent, 10);
$palette-aceent-A100: nth($palette-aceent, 11);
$palette-aceent-A200: nth($palette-aceent, 12);
$palette-aceent-A400: nth($palette-aceent, 13);
$palette-aceent-A700: nth($palette-aceent, 14);
